import { Flex, Text, Spinner } from "@chakra-ui/react";
import CaseTreeNodeToAccordionItem from "./CaseTreeNodeToAccordionItem";
import { navbarHeight } from "../Navbar";
import { SelectedCaseOrFolder, CaseTreeNode } from "../../logics/CaseArchive";
import { FaFolderOpen } from "react-icons/fa";
import { keyframes } from "@emotion/react";

export const hoverColor = "gray.200";
export const selectedColor = "gray.300";

const CaseTreeExplorer = ({
  selectedCaseOrFolder,
  setSelectedCaseOrFolder,
  caseTree,
  maxHeight = `calc(100vh - ${navbarHeight}px)`,
  isFixedHeight = false,
  foldersOnlyClickable = false,
  isLoadingSavedCasesAndFolders = false,
  shouldBlinkCaseTree = false,
}: {
  selectedCaseOrFolder: SelectedCaseOrFolder | undefined;
  setSelectedCaseOrFolder: React.Dispatch<
    React.SetStateAction<SelectedCaseOrFolder | undefined>
  >;
  caseTree: CaseTreeNode | undefined;
  maxHeight?: string;
  isFixedHeight?: Boolean;
  foldersOnlyClickable?: Boolean;
  isLoadingSavedCasesAndFolders?: Boolean;
  shouldBlinkCaseTree?: Boolean;
}) => {
  const handleOnClick = () => {
    setSelectedCaseOrFolder(undefined);
  };
  const blinkAnimation = keyframes`
  0% { opacity: 0.2; }
  100% { opacity: 1; }
`;

  return (
    <Flex
      p="10px"
      height={"fit-content"}
      width={"100%"}
      overflow={"auto"}
      maxHeight={maxHeight}
      minHeight={isFixedHeight ? maxHeight : ""}
      direction={"column"}
      position={"relative"}
      css={{
        animation: `${
          shouldBlinkCaseTree ? `${blinkAnimation} 0.8s infinite` : "none"
        }`,
      }}
    >
      <Flex
        height={"fit-content"}
        bg={selectedCaseOrFolder ? "" : selectedColor}
        borderRadius={"5px"}
        py={"2px"}
        px="5px"
      >
        <Flex
          height={"24px"}
          width={"100%"}
          _hover={{ bg: hoverColor }}
          borderRadius={"5px"}
          cursor={"pointer"}
          px="2px"
          alignItems={"center"}
          onClick={handleOnClick}
        >
          <FaFolderOpen />

          <Text textAlign="left" color={"gray.600"} ms="5px">
            <b>{"판례보관함"}</b>
          </Text>
        </Flex>
      </Flex>
      {isLoadingSavedCasesAndFolders ? (
        <Flex justifyContent={"center"} justifyItems={"center"} p="20px">
          <Spinner color="gray.500" size="sm" />
        </Flex>
      ) : (
        caseTree?.children.map((child) => {
          return (
            <CaseTreeNodeToAccordionItem
              selectedCaseOrFolder={selectedCaseOrFolder}
              setSelectedCaseOrFolder={setSelectedCaseOrFolder}
              caseTreeNode={child}
              depth={0}
              basePathId={""}
              key={child.string_id}
              foldersOnlyClickable={foldersOnlyClickable}
            />
          );
        })
      )}
    </Flex>
  );
};

export default CaseTreeExplorer;
