import * as React from "react";
import { useState } from "react";
import { Flex, Text, Avatar, Spacer } from "@chakra-ui/react";
import { isMobileQuery } from "../utils/UIUtils";
import { useMediaQuery } from "react-responsive";
import { useAtom, useAtomValue } from "jotai";
import { AuthContextType } from "../context/AuthContext";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { selectedNavbarKeyAtom } from "../states/HomePage";
import { mainTabBackgroudColor } from "../tabs/MainTab";
import logo from "../img/logo_header_menu.svg";
import { OnlyMobile, OnlyPC } from "../utils/UIUtils";
import { searchResultsWithStatusAtom } from "../states/Search";
import SearchBox from "./Search/SearchBox";

export interface NavbarItem {
  key: string;
  label: string;
  url: string;
}
export const navbarHeight = 60;

const Navbar = ({ items }: { items: NavbarItem[] }) => {
  const [selectedNavbarKey, setSelectedNavbarKey] = useAtom(
    selectedNavbarKeyAtom,
  );
  const searchResultsWithStatus = useAtomValue(searchResultsWithStatusAtom);
  const [initialQuery, setInitialQuery] = useState<string>("");

  React.useEffect(() => {
    if (searchResultsWithStatus.query) {
      setInitialQuery(searchResultsWithStatus.query);
    }
  }, [searchResultsWithStatus]);

  const [type, setType] = useState<"mainView" | "searchView" | "extraView">(
    selectedNavbarKey === "메인"
      ? "mainView"
      : selectedNavbarKey === "검색" || selectedNavbarKey === "판례"
        ? "searchView"
        : "extraView",
  );
  React.useEffect(() => {
    setType(
      selectedNavbarKey === "메인"
        ? "mainView"
        : selectedNavbarKey === "검색" || selectedNavbarKey === "판례"
          ? "searchView"
          : "extraView",
    );
  }, [selectedNavbarKey]);

  const navigate = useNavigate();
  const authContext = useContext(AuthContext) as AuthContextType;
  const navbarColor = type === "mainView" ? mainTabBackgroudColor : "blue.700";

  const makeItemDOM = (item: NavbarItem) => {
    const fontColor = type === "mainView" ? "gray.600" : "white";
    const hoverColor = type === "mainView" ? "gray.100" : "blue.600";
    return (
      <Flex
        borderRadius={"5px"}
        p={"5px"}
        _hover={{ bg: hoverColor }}
        key={item.key}
        mx={"2px"}
        cursor={"pointer"}
        bg={selectedNavbarKey === item.key ? "blue.800" : navbarColor}
        onClick={(e) => {
          setSelectedNavbarKey(item.key);
          navigate(item.url);
        }}
      >
        <Text
          fontSize="md"
          whiteSpace={"nowrap"}
          fontWeight={"bold"}
          color={fontColor}
        >
          {item.label}
        </Text>
      </Flex>
    );
  };

  return (
    <>
      <OnlyPC>
        <Flex
          height={`${navbarHeight}px`}
          bg={navbarColor}
          top={0}
          width={"100vw"}
          zIndex={99}
          position={"fixed"}
          alignItems={"center"}
        >
          {type !== "mainView" && (
            <Flex
              ms="15px"
              p={"5px"}
              cursor={"pointer"}
              minWidth={"168px"}
              width={"168px"}
              borderRadius={"5px"}
              justifyContent={"center"}
              _hover={{ bg: "blue.600" }}
              onClick={() => {
                navigate(`/main`);
              }}
            >
              <img src={logo} alt="Servant" style={{ height: "28px" }} />
            </Flex>
          )}
          {type === "searchView" && (
            <Flex
              mx="20px"
              height={"36px"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"46em"}
            >
              <SearchBox inNavbar={true} initialQuery={initialQuery} />
            </Flex>
          )}
          <Spacer />
          <Flex me="10px">{items.map((item) => makeItemDOM(item))}</Flex>
          <Avatar
            me="15px"
            size="sm"
            name={authContext.user?.username}
            bg="gray.300"
          />
        </Flex>
      </OnlyPC>
      <OnlyMobile>
        <Flex
          height={`${navbarHeight}px`}
          bg={navbarColor}
          top={0}
          width={"100vw"}
          zIndex={99}
          position={"fixed"}
          alignItems={"center"}
        ></Flex>
      </OnlyMobile>
      <Flex height={`${navbarHeight}px`} />
    </>
  );
};

export default Navbar;
