import { Text, Flex } from "@chakra-ui/react";

import { ExternalSearchResultsWithStatus } from "../../logics/Search";
import { caseSearchResulWidth } from "./SearchResultDisplay";
import {
  CaseTreeNode,
  SavedCasesAndFolders,
  SavedCase,
} from "../../logics/CaseArchive";
import ExternalSearchResultListItem from "./ExternalSearchResultListItem";

const DisplayPrioritizedCaseResult = ({
  searchResultsWithStatus,
  selectedSummaryIndex,
  setSelectedSummaryIndex,
  caseTree,
  shouldBlinkCaseTree,
  setShouldBlinkCaseTree,
  savedCasesAndFolders,
  setSavedCasesAndFolders,
  savedCasesList,
  setSavedCasesLoadError,
}: {
  searchResultsWithStatus: ExternalSearchResultsWithStatus;
  selectedSummaryIndex: number | undefined;
  setSelectedSummaryIndex: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  caseTree: CaseTreeNode | undefined;
  shouldBlinkCaseTree: Boolean;
  setShouldBlinkCaseTree: React.Dispatch<React.SetStateAction<Boolean>>;
  savedCasesAndFolders: SavedCasesAndFolders;
  setSavedCasesAndFolders: React.Dispatch<
    React.SetStateAction<SavedCasesAndFolders>
  >;
  savedCasesList: SavedCase[] | undefined;
  setSavedCasesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    (searchResultsWithStatus.caseNameAnalysisResult?.type === "exactCaseName" ||
      searchResultsWithStatus.caseNameAnalysisResult?.type ===
        "containsCaseName") && (
      <>
        <Flex
          borderTop={"2px"}
          borderColor={"blue.400"}
          mt="20px"
          mb="10px"
          position={"relative"}
          minWidth={`${caseSearchResulWidth}px`}
          maxWidth={`${caseSearchResulWidth}px`}
        ></Flex>

        {searchResultsWithStatus.prioritizedSearchResults?.length === 0 ? (
          <Flex
            height={"80px"}
            minWidth={`${caseSearchResulWidth}px`}
            maxWidth={`${caseSearchResulWidth}px`}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text fontWeight={"bold"}>
              {`사건번호 "`}
              <span style={{ color: "#3182CE" }}>
                {searchResultsWithStatus.caseNameAnalysisResult?.caseNumber}
              </span>
              {`"의 판례는 등록되지 않았습니다.`}
            </Text>
          </Flex>
        ) : (
          searchResultsWithStatus.prioritizedSearchResults?.map(
            (result, index) => {
              const newIndex = -index - 1;
              return (
                <ExternalSearchResultListItem
                  key={newIndex}
                  searchResult={result}
                  index={newIndex}
                  selectedSummaryIndex={selectedSummaryIndex}
                  setSelectedSummaryIndex={setSelectedSummaryIndex}
                  caseTree={caseTree}
                  shouldBlinkCaseTree={shouldBlinkCaseTree}
                  setShouldBlinkCaseTree={setShouldBlinkCaseTree}
                  savedCasesAndFolders={savedCasesAndFolders}
                  setSavedCasesAndFolders={setSavedCasesAndFolders}
                  savedCasesList={savedCasesList}
                  setSavedCasesLoadError={setSavedCasesLoadError}
                  isPrioritized={true}
                  isLast={
                    index ===
                    searchResultsWithStatus.prioritizedSearchResults!.length - 1
                  }
                />
              );
            },
          )
        )}
        <Flex
          borderTop={"2px"}
          borderColor={"blue.400"}
          my="10px"
          minWidth={`${caseSearchResulWidth}px`}
          maxWidth={`${caseSearchResulWidth}px`}
        />
      </>
    )
  );
};

export default DisplayPrioritizedCaseResult;
