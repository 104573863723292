import React from "react";
import { Flex, Text, Spinner } from "@chakra-ui/react";

import { InternalSearchResult } from "../../logics/Search";
import { TabItems } from "../../logics/FileManager";
import InternalSearchResultList from "./InternalSearchResultList";
import DisplayPreviewItem from "./DisplayPreviewItem";

const DisplaySearchResults = ({
  isSearchLoading,
  setShowSearchResults,
  internalSearchResults,
  tabItems,
  setTabItems,
  previewSearchResult,
  setPreviewSearchResult,
}: {
  isSearchLoading: boolean;
  setShowSearchResults: React.Dispatch<React.SetStateAction<boolean>>;
  internalSearchResults: InternalSearchResult[] | undefined;
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  previewSearchResult: InternalSearchResult | undefined;
  setPreviewSearchResult: React.Dispatch<
    React.SetStateAction<InternalSearchResult | undefined>
  >;
}) => {
  return (
    <Flex width={"100%"} overflow={"hidden"} height={"100%"} bg="white">
      <Flex width={"100%"} minW={"800px"} height={"100%"}>
        <Flex
          flex={1}
          height={"100%"}
          borderRight={"1px"}
          borderColor={"gray.300"}
          overflowY="auto"
        >
          {isSearchLoading && (
            <Flex
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              p="5px"
            >
              <Spinner color="gray.500" size="md" />
            </Flex>
          )}
          {internalSearchResults !== undefined && (
            <InternalSearchResultList
              searchResults={internalSearchResults}
              tabItems={tabItems}
              setTabItems={setTabItems}
              setPreviewSearchResult={setPreviewSearchResult}
            />
          )}
        </Flex>
        <Flex
          flex={1.2}
          minWidth={"600px"}
          height={"100%"}
          position={"relative"}
          overflow={"auto"}
        >
          {previewSearchResult ? (
            <DisplayPreviewItem
              previewSearchResult={previewSearchResult}
              setPreviewSearchResult={setPreviewSearchResult}
              tabItems={tabItems}
              setTabItems={setTabItems}
            />
          ) : (
            <Flex
              width={"100%"}
              height={"100%"}
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text color={"gray.600"} fontWeight={"bold"}>
                {"미리 볼 파일을 선택하세요."}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DisplaySearchResults;
