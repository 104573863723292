import React from "react";
import { useContext } from "react";
import { Flex, Text, IconButton, Icon, Spinner } from "@chakra-ui/react";
import { LuX } from "react-icons/lu";
import { TabItem, TabItems, putUrlInTabItem } from "../../logics/FileManager";
import { FixedSizeList } from "react-window";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { navbarHeight } from "../Navbar";
import { tabSelectorHeight } from "./FileManagerMain";

import PDFViewer from "../PDFViewer";

const PdfViewerInFileManager = ({
  currentTabItem,
  setTabItems,
}: {
  currentTabItem: TabItem;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;
  const [page, setPage] = React.useState<number | undefined>(
    currentTabItem.page,
  );
  const windowRef = React.useRef<FixedSizeList>(null);

  const currentTabItemId = currentTabItem.id;
  const pdfViewerHeaderHeight = 44;
  const pdfViewerHeaderTopMargin = 5;

  React.useEffect(() => {
    if (windowRef.current && currentTabItem.page) {
      windowRef.current.scrollToItem(currentTabItem.page, "start");
    }
    setPage(currentTabItem.page);
  }, [currentTabItem]);

  React.useEffect(() => {
    if (currentTabItem.status === "Success") {
      return;
    }
    if (currentTabItem.status === "Loading") {
      putUrlInTabItem(currentTabItem, authContext)
        .then((newTabItem) => {
          setTabItems((prev) => {
            const newTabList = prev.tabList.map((tabItem) => {
              if (tabItem.id === currentTabItemId) {
                return newTabItem;
              }
              return tabItem;
            });
            return {
              tabList: newTabList,
              activeTabId: prev.activeTabId,
            };
          });
        })
        .catch((e) => {
          console.log(e);
          setTabItems((prev) => {
            const newTabList = prev.tabList.map((tabItem) => {
              if (tabItem.id === currentTabItemId) {
                return {
                  ...tabItem,
                  status: "Error" as
                    | "Loading"
                    | "Error"
                    | "Success"
                    | undefined,
                };
              }
              return tabItem;
            });
            return {
              tabList: newTabList,
              activeTabId: prev.activeTabId,
            };
          });
        });
    }
  }, [currentTabItem]);

  return (
    <Flex
      id={"pdf viewer in file-manager"}
      width={"100%"}
      height={"100%"}
      direction={"column"}
    >
      <Flex
        id={"pdf viewer header"}
        borderTop={"1px"}
        borderBottom={"1px"}
        borderColor={"gray.300"}
        bg={"gray.200"}
        width={"100%"}
        mt={`${pdfViewerHeaderTopMargin}px`}
        minHeight={`${pdfViewerHeaderHeight}px`}
        maxHeight={`${pdfViewerHeaderHeight}px`}
        alignItems={"center"}
        px="5px"
      >
        <IconButton
          bg="white"
          size={"sm"}
          variant={"outline"}
          aria-label="홈으로 이동"
          icon={<Icon as={LuX} color={"gray.600"} />}
          onClick={(e) => {
            e.stopPropagation();
            setTabItems((prev) => {
              const newTabList = prev.tabList.filter((tabItem) => {
                return tabItem.id !== currentTabItemId;
              });
              const newActiveTabIndex = null;
              return {
                tabList: newTabList,
                activeTabId: newActiveTabIndex,
              };
            });
          }}
          me="5px"
        />
        <Text
          bg="white"
          borderRadius={"5px"}
          py="4px"
          flex={1}
          px={"10px"}
          isTruncated
          fontSize={"md"}
          fontWeight={"bold"}
          color={"gray.600"}
          me={"5px"}
        >
          {currentTabItem.title}
        </Text>
      </Flex>
      <Flex
        flex={1}
        width={"100%"}
        bg={"gray.100"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {currentTabItem.status === "Loading" ? (
          <Flex
            mt="50px"
            width="100%"
            mb="20px"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner color="gray.500" />
          </Flex>
        ) : currentTabItem.status === "Success" ? (
          <Flex
            width={"100%"}
            px="50px"
            maxWidth={"1000px"}
            minHeight={`calc( 100vh - ${
              tabSelectorHeight +
              navbarHeight +
              pdfViewerHeaderHeight +
              pdfViewerHeaderTopMargin
            }px)`}
            maxHeight={`calc( 100vh - ${
              tabSelectorHeight +
              navbarHeight +
              pdfViewerHeaderHeight +
              pdfViewerHeaderTopMargin
            }px)`}
            pt={"5px"}
            pb={"20px"}
          >
            <PDFViewer
              id={currentTabItem.id!}
              url={currentTabItem.url!}
              fullHeight={true}
              fullWidth={true}
              backgroundColor=""
              page={page}
              windowRef={windowRef}
            />
          </Flex>
        ) : (
          <Text textAlign={"center"} fontSize={"md"} color={"red.400"}>
            <b>{"파일 불러오기에 실패하였습니다. 파일을 다시 열어주십시오."}</b>
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default PdfViewerInFileManager;
