import * as React from "react";
import { Text, Flex, Spinner, Center, Input } from "@chakra-ui/react";
import { useRef, useState, useContext, useCallback } from "react";
import { useEffect } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  requestFetchFile,
  requestFileDownloadUrl,
  requestFilePartitionDownloadUrl,
  SelectedFile,
} from "../logics/FileManager";
import { LargePdfViewer, CurrentPageNoViewer } from "../libs/pdf/PdfManager";
import AuthContext, { AuthContextType } from "../context/AuthContext";
import { FixedSizeList } from "react-window";
import { set } from "date-fns";

const PDFViewer: React.FC<{
  id: number;
  url: string;
  highlight?: boolean;
  highlightPage?: number;
  highlightPosition?: any;
  pdfWidth?: number;
  pdfHeight?: number;
  fullHeight?: boolean;
  fullWidth?: boolean;
  backgroundColor?: string;
  page?: number;
  windowRef: FixedSizeList | null;
}> = ({
  id,
  url,
  highlight = false,
  highlightPage,
  highlightPosition,
  pdfWidth = 600,
  pdfHeight = 900,
  fullHeight = false,
  fullWidth = false,
  backgroundColor = "gray.100",
  page = 0,

  windowRef,
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;

  const [numPages, setNumPages] = useState<number | null>(null);
  const [inputPage, setInputPage] = useState<number | string>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedPage, setSelectedPage] = useState<number>(page);

  React.useLayoutEffect(() => {
    setSelectedPage(page);
  }, [page]);

  const scrollToPage = (pageId: number) => {
    if (windowRef.current) {
      windowRef.current.scrollToItem(pageId - 1, "start");
    }
  };

  const onDocumentLoadSuccess = useCallback(
    (num: number) => {
      setNumPages(num);
      setLoading(false);
    },
    [loading],
  );

  const setCurrentPageNo = useCallback((currentPageNo: number) => {
    setCurrentPage(currentPageNo);
  }, []);

  const getFileInfo = useCallback(
    async (fileId: number): Promise<[number, number, number]> => {
      const response = await requestFetchFile(authContext, fileId);
      return [
        response.num_pages,
        response.partition_size,
        response.num_partitions,
      ];
    },
    [loading],
  );

  const getUrlOfPartition = useCallback(
    async (
      fileId: number,
      numPartitions: number,
      partitionId: number,
    ): Promise<string> => {
      if (numPartitions === 1) {
        const response = await requestFileDownloadUrl(authContext, fileId);
        return response.url;
      }
      //console.log("fileId, partitionId", fileId, partitionId);
      const response = await requestFilePartitionDownloadUrl(
        authContext,
        fileId,
        partitionId,
      );
      return response.url;
    },
    [loading],
  );

  useEffect(() => {
    setCurrentPage(1);
    setSelectedPage(page);
  }, [url]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPage(e.target.value);
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      scrollToPage(Number(inputPage));
    }
  };
  return (
    <Flex
      direction={"column"}
      bg={backgroundColor}
      width={"100%"}
      alignItems="center"
    >
      <Flex
        width={fullWidth ? "100%" : `${pdfWidth}px`}
        bg="gray.300"
        p="2"
        height={"30px"}
        borderRadius={"2px"}
        alignItems={"center"}
        mb={"2"}
      >
        <Text ms="2" color={"gray.700"} fontSize={"sm"} me="2">
          <b>페이지 :</b>
        </Text>
        <Text
          width={"100px"}
          color={"gray.700"}
          fontSize={"sm"}
          alignItems={"center"}
        >
          <CurrentPageNoViewer
            numPages={numPages}
            currentPageNo={currentPage}
          />
        </Text>

        <Text color={"gray.700"} fontSize={"sm"}>
          <b>페이지 이동 :</b>
        </Text>
        <Input
          type="number"
          value={inputPage}
          width={"100px"}
          height={"26px"}
          bg="white"
          fontSize={"sm"}
          onChange={handleInputChange}
          style={{ marginLeft: 10 }}
          onKeyPress={handleEnterPress}
        />
      </Flex>
      {loading && (
        <Center width={"100%"} height={"100%"}>
          <Spinner color="gray.500" />
        </Center>
      )}
      <LargePdfViewer
        fileId={id}
        gap={40}
        prefetchSize={1}
        highlight={highlight}
        highlightPage={highlightPage}
        highlightPosition={highlightPosition}
        windowRef={windowRef}
        getFileInfo={getFileInfo}
        getUrlOfPartition={getUrlOfPartition}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        setCurrentPageNo={setCurrentPageNo}
        startPage={selectedPage}
      />
    </Flex>
  );
};

export default PDFViewer;
