import React from "react";
import {
  Flex,
  Text,
  IconButton,
  Icon,
  InputGroup,
  ButtonGroup,
  Tooltip,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { GoHome, GoArrowUp } from "react-icons/go";
import {
  LuUpload,
  LuFolderPlus,
  LuFolderEdit,
  LuTrash2,
  LuX,
} from "react-icons/lu";
import { useContext } from "react";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { FileTreeNode, SelectedFolder } from "../../logics/FileManager";
import {
  MakeOnFilesAddedCallback,
  MakeOnFilesRejectedCallback,
  FilesAndFolders,
  getParentFolderPath,
  ModalType,
  selectFolderByPath,
  findSubmittedFilesInFileTreeNode,
} from "../../logics/FileManager";
import { InternalSearchResult } from "../../logics/Search";
import { addSpaceAroundSlash } from "../../utils/TextUtils";
import ModalInFileManagerHeader from "./ModalInFileExplorerHeader";
import SearchBoxInFileManager from "./SearchBoxInFileManager";

export const fileExplorerHeaderFirstRowHeight = 44;
export const fileExplorerHeaderSecondRowHeight = 42;

const FileExplorerHeader = ({
  selectedFolder,
  setSelectedFolder,
  filesAndFolders,
  setFilesAndFolders,
  setShouldBlinkFileTree,
  fileTree,
  filesLoadError,
  setFilesLoadError,
  setInternalSearchResults,
  isSearchLoading,
  setIsSearchLoading,
  showSearchResults,
  setShowSearchResults,
  setPreviewSearchResult,
}: {
  selectedFolder: SelectedFolder;
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder>>;
  filesAndFolders: FilesAndFolders | undefined;
  setFilesAndFolders: React.Dispatch<
    React.SetStateAction<FilesAndFolders | undefined>
  >;
  setShouldBlinkFileTree: React.Dispatch<React.SetStateAction<boolean>>;
  fileTree: FileTreeNode | undefined;
  filesLoadError: boolean;
  setFilesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
  setInternalSearchResults: React.Dispatch<
    React.SetStateAction<InternalSearchResult[] | undefined>
  >;
  isSearchLoading: boolean;
  setIsSearchLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showSearchResults: boolean;
  setShowSearchResults: React.Dispatch<React.SetStateAction<boolean>>;
  setPreviewSearchResult: React.Dispatch<
    React.SetStateAction<InternalSearchResult | undefined>
  >;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;
  const userName = authContext.user?.username;
  const toast = useToast();
  const onFilesAdded = MakeOnFilesAddedCallback({
    authContext: authContext,
    path: selectedFolder.path,
    parentFolderId: selectedFolder.id,
    toast: toast,
    setFilesAndFolders: setFilesAndFolders,
    setShouldBlinkFileTree: setShouldBlinkFileTree,
  });
  const onFilesRejected = MakeOnFilesRejectedCallback({
    toast: toast,
  });

  const { getInputProps, open } = useDropzone({
    onDrop: onFilesAdded,
    onDropRejected: onFilesRejected,
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    accept: { "application/pdf": [] },
    maxSize: 1024 * 1024 * 1024,
  });
  const handleOnClicMoveToParentFolder = () => {
    const parentPathStringId = getParentFolderPath(
      selectedFolder.path_string_id,
    );
    selectFolderByPath({
      fileTreeNode: fileTree,
      pathStringId: parentPathStringId,
      setSelectedFolder: setSelectedFolder,
      userName: userName,
    });
  };
  const handleOnClickMoveToHome = () => {
    if (fileTree === undefined) return;
    setSelectedFolder({
      name: userName,
      id: null,
      path: "",
      path_string_id: "",
      children: fileTree.children,
    });
  };
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const submittedFiles = findSubmittedFilesInFileTreeNode(selectedFolder);

  const [modalType, setModalType] = React.useState<ModalType>(undefined);
  return (
    <>
      <Flex width={"100%"} alignItems={"center"} direction={"column"}>
        <Flex
          bg={"gray.200"}
          height={`${fileExplorerHeaderFirstRowHeight}px`}
          width={"100%"}
          borderTop={"1px"}
          borderBottom={"1px"}
          borderColor={"gray.300"}
          px="5px"
          py="4px"
          alignItems={"center"}
        >
          <ButtonGroup size="sm" isAttached variant="outline" me="5px">
            <Tooltip
              placement="top-start"
              label={selectedFolder.id === null ? "" : `"파일관리"로 이동`}
              bg="gray.500"
            >
              <IconButton
                bg="white"
                aria-label="홈으로 이동"
                icon={<Icon as={GoHome} />}
                isDisabled={selectedFolder.path_string_id === ""}
                onClick={() => handleOnClickMoveToHome()}
                _disabled={{ cursor: "default", opacity: 0.5 }}
              />
            </Tooltip>
            <Tooltip
              placement="top-start"
              label={selectedFolder.id === null ? "" : `상위 폴더로 이동`}
              bg="gray.500"
            >
              <IconButton
                bg="white"
                aria-label="상위폴더이동"
                isDisabled={selectedFolder.path_string_id === ""}
                icon={<Icon as={GoArrowUp} />}
                onClick={() => handleOnClicMoveToParentFolder()}
                _disabled={{ cursor: "default", opacity: 0.5 }}
              />
            </Tooltip>
          </ButtonGroup>
          <Text
            bg="white"
            borderRadius={"5px"}
            border={"1px"}
            py="4px"
            flex={1}
            px={"10px"}
            isTruncated
            fontSize={"md"}
            fontWeight={"bold"}
            color={"gray.600"}
            borderColor={"gray.300"}
          >
            {showSearchResults
              ? `"${selectedFolder.name}"의 검색 결과`
              : addSpaceAroundSlash(`${userName}` + selectedFolder.path)}
          </Text>
          <Flex width={"400px"} ms="5px" mt="12px">
            <SearchBoxInFileManager
              setInternalSearchResults={setInternalSearchResults}
              isSearchLoading={isSearchLoading}
              setIsSearchLoading={setIsSearchLoading}
              fileTree={fileTree}
              selectedFolder={selectedFolder}
              isTherePreprocessing={submittedFiles.length !== 0}
              showSearchResults={showSearchResults}
              setShowSearchResults={setShowSearchResults}
              setPreviewSearchResult={setPreviewSearchResult}
            />
          </Flex>
        </Flex>
        <Flex
          width={"100%"}
          height={`${fileExplorerHeaderSecondRowHeight}px`}
          bg="gray.100"
          py="4px"
          px="5px"
          alignItems={"center"}
        >
          <>
            <InputGroup width={"fit-content"}>
              <input {...getInputProps()} />
              <Tooltip
                placement="bottom"
                label={showSearchResults ? "" : "업로드 가능한 파일 형식: pdf"}
                bg="gray.500"
              >
                <Button
                  size={"sm"}
                  leftIcon={<Icon as={LuUpload} />}
                  variant={"outline"}
                  bg={"white"}
                  me="5px"
                  isDisabled={filesLoadError || showSearchResults}
                  _hover={{ bg: "gray.100" }}
                  onClick={open}
                >
                  {"파일 업로드"}
                </Button>
              </Tooltip>
            </InputGroup>
            <Button
              size={"sm"}
              me={"5px"}
              leftIcon={<Icon as={LuFolderPlus} />}
              variant={"outline"}
              bg={"white"}
              isDisabled={filesLoadError || showSearchResults}
              _hover={{ bg: "gray.100" }}
              onClick={() => {
                setModalType("newFolder");
                onOpenModal();
              }}
            >
              {"새폴더"}
            </Button>
            {selectedFolder.id !== null && (
              <>
                <Button
                  size={"sm"}
                  leftIcon={<Icon as={LuFolderEdit} />}
                  variant={"outline"}
                  isDisabled={filesLoadError || showSearchResults}
                  bg={"white"}
                  me="5px"
                  _hover={{ bg: "gray.100" }}
                  onClick={() => {
                    setModalType("renameFolder");
                    onOpenModal();
                  }}
                >
                  {"이름변경"}
                </Button>
                <Button
                  size={"sm"}
                  leftIcon={<Icon as={LuTrash2} />}
                  variant={"outline"}
                  isDisabled={filesLoadError || showSearchResults}
                  bg={"white"}
                  me="5px"
                  _hover={{ bg: "gray.100" }}
                  onClick={() => {
                    setModalType("deleteFolder");
                    onOpenModal();
                  }}
                >
                  {"삭제"}
                </Button>
              </>
            )}
          </>
          {showSearchResults && (
            <Button
              size={"sm"}
              ms={"5px"}
              leftIcon={<Icon as={LuX} />}
              variant={"outline"}
              bg={"white"}
              isDisabled={filesLoadError}
              _hover={{ bg: "gray.100" }}
              onClick={() => {
                setShowSearchResults(false);
                setIsSearchLoading(false);
                setInternalSearchResults(undefined);
              }}
            >
              {"검색 닫기"}
            </Button>
          )}
        </Flex>
      </Flex>
      <ModalInFileManagerHeader
        isOpenModal={isOpenModal}
        modalType={modalType}
        setModalType={setModalType}
        onCloseModal={onCloseModal}
        setShouldBlinkFileTree={setShouldBlinkFileTree}
        filesAndFolders={filesAndFolders}
        setFilesAndFolders={setFilesAndFolders}
        setFilesLoadError={setFilesLoadError}
        fileTree={fileTree}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
      />
    </>
  );
};

export default FileExplorerHeader;
