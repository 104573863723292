import * as React from "react";
import { Text, Spinner, Flex, Button, useToast } from "@chakra-ui/react";
import { useAtomValue, useAtom } from "jotai";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  searchResultsWithStatusAtom,
  currentResultIdAtom,
  selectedCaseAtom,
  ContentPerPage,
} from "../../states/Search";
import {
  requestRelevantParts,
  ExternalSearchResultsWithStatus,
} from "../../logics/Search";
import { caseSearchResulWidth } from "./SearchResultDisplay";
import {
  requestCaseArchiveList,
  CaseTreeNode,
  makeCaseTree,
  SavedCasesAndFolders,
  extractCaseIdAndParentFolderId,
  SavedCase,
} from "../../logics/CaseArchive";
import { navbarHeight } from "../Navbar";
import { toastErrorMessage } from "../../utils/UIUtils";
import CaseViewerInTab from "./CaseViewerInTab";
import ExternalSearchResultListItem from "./ExternalSearchResultListItem";
import DisplayPrioritizedCaseResult from "./DisplayPrioritizedCaseResult";

const ExternalSearchResultList = () => {
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const toast = useToast();
  const [searchResultsWithStatus, setSearchResultsWithStatus] = useAtom(
    searchResultsWithStatusAtom,
  );

  const prioritizedCaseIds = searchResultsWithStatus.prioritizedSearchResults
    ? searchResultsWithStatus.prioritizedSearchResults?.map(
        (result) => result.case_id,
      )
    : [];

  const [searchResultsWithStatusTemp, setSearchResultsWithStatusTemp] =
    React.useState<ExternalSearchResultsWithStatus>({
      status: "Empty",
    });
  const [savedCasesAndFolders, setSavedCasesAndFolders] =
    React.useState<SavedCasesAndFolders>({ saved_cases: [], folders: [] });
  const [caseTree, setCaseTree] = React.useState<CaseTreeNode | undefined>(
    undefined,
  );
  const [shouldBlinkCaseTree, setShouldBlinkCaseTree] =
    React.useState<Boolean>(true);
  const [savedCasesLoadError, setSavedCasesLoadError] =
    React.useState<boolean>(false);
  const [savedCasesList, setSavedCasesList] = React.useState<
    SavedCase[] | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const startIdx = searchResultsWithStatus.startIdxDisplay;
  const endIdx = Math.min(
    startIdx! + ContentPerPage,
    searchResultsWithStatus.searchResults!.length,
  );
  const currentResultId = useAtomValue(currentResultIdAtom);
  const noResult = searchResultsWithStatus.searchResults?.length === 0;
  const nomoreResult = endIdx === searchResultsWithStatus.searchResults!.length;
  const [selectedSummaryIndex, setSelectedSummaryIndex] = React.useState<
    number | undefined
  >(undefined);
  const selectedCase = useAtomValue(selectedCaseAtom);

  React.useLayoutEffect(() => {
    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        setSavedCasesLoadError(true);
      });
  }, []);

  React.useEffect(() => {
    const caseTree = makeCaseTree(savedCasesAndFolders);
    setCaseTree(caseTree);
    const savedCasesList = extractCaseIdAndParentFolderId(savedCasesAndFolders);
    setSavedCasesList(savedCasesList);
    setShouldBlinkCaseTree(false);
  }, [savedCasesAndFolders]);

  React.useEffect(() => {
    if (searchResultsWithStatusTemp.id === currentResultId) {
      setSearchResultsWithStatus(searchResultsWithStatusTemp);
    }
  }, [searchResultsWithStatusTemp]);

  React.useEffect(() => {
    const toRequestCaseIds = searchResultsWithStatus.searchResults
      ?.filter((result, idx) => startIdx! <= idx && idx < endIdx)
      .filter((result) => result.case === undefined)
      .map((result) => result.case_id) as number[];
    if (!toRequestCaseIds || toRequestCaseIds.length === 0) return;
    setLoading(true);
    requestRelevantParts(
      authContext,
      toRequestCaseIds,
      searchResultsWithStatus.query!,
    )
      .then(({ cases, relevant_parts }) => {
        for (let i = 0; i < toRequestCaseIds.length; i++) {
          const case_id = toRequestCaseIds[i];
          const caseIdx = searchResultsWithStatus.searchResults!.findIndex(
            (result) => result.case_id === case_id,
          );
          searchResultsWithStatus.searchResults![caseIdx].case = cases[i];
          searchResultsWithStatus.searchResults![caseIdx].relevant_parts =
            relevant_parts[i];
        }
        setSearchResultsWithStatusTemp({ ...searchResultsWithStatus });
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          toast: toast,
          message: "서버 에러입니다. 다시 시도해 주세요.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startIdx]);

  return (
    <Flex direction={"column"} height={"100%"} width={"100%"}>
      {selectedCase !== undefined && (
        <Flex
          position={"fixed"}
          bg="white"
          top={`${navbarHeight}px`}
          left={"0"}
          width={"100vw"}
          zIndex={50}
          direction="column"
          flex={1}
          borderRight={"1px "}
          borderColor={"gray.300"}
          height={`calc( 100vh - ${navbarHeight}px )`}
        >
          <CaseViewerInTab
            caseTree={caseTree}
            savedCasesList={savedCasesList}
            savedCasesAndFolders={savedCasesAndFolders}
            setSavedCasesAndFolders={setSavedCasesAndFolders}
            shouldBlinkCaseTree={shouldBlinkCaseTree}
            setShouldBlinkCaseTree={setShouldBlinkCaseTree}
            setSavedCasesLoadError={setSavedCasesLoadError}
          />
        </Flex>
      )}
      {savedCasesLoadError || searchResultsWithStatus.status === "Error" ? (
        <Flex
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text fontSize={"lg"} fontWeight={"bold"} color={"red.500"}>
            {"서버와의 통신에 문제가 생겼습니다. 다시 검색해 주세요."}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column">
          <DisplayPrioritizedCaseResult
            searchResultsWithStatus={searchResultsWithStatus}
            selectedSummaryIndex={selectedSummaryIndex}
            setSelectedSummaryIndex={setSelectedSummaryIndex}
            caseTree={caseTree}
            shouldBlinkCaseTree={shouldBlinkCaseTree}
            setShouldBlinkCaseTree={setShouldBlinkCaseTree}
            savedCasesAndFolders={savedCasesAndFolders}
            setSavedCasesAndFolders={setSavedCasesAndFolders}
            savedCasesList={savedCasesList}
            setSavedCasesLoadError={setSavedCasesLoadError}
          />
          {searchResultsWithStatus.searchResults
            ?.filter((result, idx) => idx < endIdx)
            .filter((result) => result.case !== undefined)
            .filter((result) => {
              return !prioritizedCaseIds.includes(result.case_id);
            })
            .map((result, index) => {
              return (
                <ExternalSearchResultListItem
                  key={index}
                  searchResult={result}
                  index={index}
                  selectedSummaryIndex={selectedSummaryIndex}
                  setSelectedSummaryIndex={setSelectedSummaryIndex}
                  caseTree={caseTree}
                  shouldBlinkCaseTree={shouldBlinkCaseTree}
                  setShouldBlinkCaseTree={setShouldBlinkCaseTree}
                  savedCasesAndFolders={savedCasesAndFolders}
                  setSavedCasesAndFolders={setSavedCasesAndFolders}
                  savedCasesList={savedCasesList}
                  setSavedCasesLoadError={setSavedCasesLoadError}
                />
              );
            })}
          {loading && (
            <Flex
              minWidth={`${caseSearchResulWidth}px`}
              maxWidth={`${caseSearchResulWidth}px`}
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
              my={"10px"}
              height={"50px"}
            >
              <Spinner color="gray.500" size="md" />
            </Flex>
          )}
          {nomoreResult ? (
            noResult ? (
              <Text
                minWidth={`${caseSearchResulWidth}px`}
                maxWidth={`${caseSearchResulWidth}px`}
                my={"50px"}
                color={"gray.600"}
                align={"center"}
              >
                <b>{"검색 결과가 없습니다."}</b>{" "}
              </Text>
            ) : (
              <Text
                minWidth={`${caseSearchResulWidth}px`}
                maxWidth={`${caseSearchResulWidth}px`}
                my={"20px"}
                color={"gray.600"}
                align={"center"}
              >
                <b>{"더 이상 검색 결과가 없습니다."}</b>{" "}
              </Text>
            )
          ) : (
            <Flex
              minWidth={`${caseSearchResulWidth}px`}
              maxWidth={`${caseSearchResulWidth}px`}
              justifyContent={"center"}
            >
              <Button
                size={"sm"}
                my={"10px"}
                onClick={() => {
                  const newStartIdx = endIdx;
                  setSearchResultsWithStatus({
                    ...searchResultsWithStatus,
                    startIdxDisplay: newStartIdx,
                  });
                }}
              >
                {"검색결과 더 보기"}
              </Button>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default ExternalSearchResultList;
