import React from "react";
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import {
  SelectedCaseOrFolder,
  SavedCasesAndFolders,
  requestCreateFolderInCaseArchive,
  requestCaseArchiveList,
  requestDeleteObjectInCaseArchive,
  requestRenameFolderInCaseArchive,
} from "../../logics/CaseArchive";
import { toastErrorMessage } from "../../utils/UIUtils";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { CaseTreeNode } from "../../logics/CaseArchive";
import {
  getParentFolderPath,
  selectCaseOrForderByPath,
} from "../../logics/CaseArchive";

const ModalInCaseArchive = ({
  isOpenModal,
  modalType,
  setModalType,
  selectedCaseOrFolder,
  setSelectedCaseOrFolder,
  onCloseModal,
  setShouldBlinkCaseTree,
  savedCasesAndFolders,
  setSavedCasesAndFolders,
  setSavedCasesLoadError,
  caseTree,
}: {
  isOpenModal: boolean;
  modalType: "newFolder" | "renameFolder" | "deleteObject" | undefined;
  setModalType: React.Dispatch<
    React.SetStateAction<
      "newFolder" | "renameFolder" | "deleteObject" | undefined
    >
  >;
  selectedCaseOrFolder: SelectedCaseOrFolder | undefined;
  setSelectedCaseOrFolder: React.Dispatch<
    React.SetStateAction<SelectedCaseOrFolder | undefined>
  >;
  onCloseModal: () => void;
  savedCasesAndFolders: SavedCasesAndFolders | undefined;
  setShouldBlinkCaseTree: React.Dispatch<React.SetStateAction<Boolean>>;
  setSavedCasesAndFolders: React.Dispatch<
    React.SetStateAction<SavedCasesAndFolders | undefined>
  >;
  setSavedCasesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
  caseTree: CaseTreeNode | undefined;
}) => {
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const toast = useToast();
  const initialRef = React.useRef<HTMLInputElement>(null);

  const [newFolderName, setNewFolderName] = React.useState<string>("");
  const [folderNameToChange, setFolderNameToChange] = React.useState<string>(
    selectedCaseOrFolder === undefined ? "" : selectedCaseOrFolder.name
  );
  const selected_folder_id =
    selectedCaseOrFolder && selectedCaseOrFolder.type === "folder"
      ? selectedCaseOrFolder.id
      : null;
  const foldersInSelectedFolder = savedCasesAndFolders
    ? savedCasesAndFolders.folders.filter(
        (folder) => folder.parent_folder_id === selected_folder_id
      )
    : [];
  const parent_folder = savedCasesAndFolders
    ? savedCasesAndFolders.folders.filter(
        (folder) => folder.id === selected_folder_id
      )
    : [];
  const parent_folder_id =
    parent_folder.length > 0 ? parent_folder[0].parent_folder_id : null;
  const foldersInParentFolder = savedCasesAndFolders
    ? savedCasesAndFolders.folders.filter(
        (folder) => folder.parent_folder_id === parent_folder_id
      )
    : [];
  const isAlreadyUsedFolderNameInSeletecd = foldersInSelectedFolder.some(
    (folder) => folder.name === newFolderName
  );
  const isAlreadyUsedFolderNameInParent = foldersInParentFolder.some(
    (folder) =>
      folder.name === folderNameToChange && folder.id !== selected_folder_id
  );

  const isSameNameToChange = selectedCaseOrFolder
    ? selectedCaseOrFolder!.name === folderNameToChange
    : false;

  const closeModal = () => {
    onCloseModal();
    setModalType(undefined);
    setNewFolderName("");
    setFolderNameToChange(
      selectedCaseOrFolder === undefined ? "" : selectedCaseOrFolder.name
    );
  };

  React.useEffect(() => {
    setNewFolderName("");
    setFolderNameToChange(
      selectedCaseOrFolder === undefined ? "" : selectedCaseOrFolder.name
    );
  }, [selectedCaseOrFolder]);

  const handleOnChangeNewName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(e.target.value);
  };

  const handleOnChangeRename = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderNameToChange(e.target.value);
  };

  const onClickCreateNewFolder = async () => {
    setShouldBlinkCaseTree(true);

    try {
      await requestCreateFolderInCaseArchive(
        authContext,
        selected_folder_id,
        newFolderName
      );
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "새폴더 생성에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
      setShouldBlinkCaseTree(false);
      closeModal();
      return;
    }
    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "저장된 판례를 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setSavedCasesLoadError(true);
      });
    closeModal();
  };

  const onClickRenameFolder = async () => {
    closeModal();
    if (!selectedCaseOrFolder) {
      return;
    }
    const id = selectedCaseOrFolder.id;
    if (!id) {
      return;
    }

    try {
      !isSameNameToChange &&
        setSavedCasesAndFolders((prev) => {
          if (!prev) {
            return { saved_cases: [], folders: [] };
          }
          return {
            saved_cases: prev.saved_cases,
            folders: prev.folders.map((folder) => {
              const newFolder =
                folder.id === id
                  ? { ...folder, name: folderNameToChange }
                  : folder;
              return newFolder;
            }),
          };
        });
      !isSameNameToChange &&
        (await requestRenameFolderInCaseArchive(
          authContext,
          id,
          folderNameToChange
        ));
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "폴더 이름 변경에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
      setShouldBlinkCaseTree(false);
    }
    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "저장된 판례를 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setSavedCasesLoadError(true);
      });
    closeModal();
  };

  const onClickDeleteObject = async () => {
    setShouldBlinkCaseTree(true);
    if (!selectedCaseOrFolder) {
      return;
    }
    const id = selectedCaseOrFolder.id;
    if (!id) {
      return;
    }
    const type = selectedCaseOrFolder.type;
    try {
      await requestDeleteObjectInCaseArchive(authContext, id, type);
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "삭제에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
      setShouldBlinkCaseTree(false);
      closeModal();
      return;
    }
    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "저장된 판례를 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setSavedCasesLoadError(true);
      });
    const parent_folder_path = getParentFolderPath(selectedCaseOrFolder.path);
    selectCaseOrForderByPath({
      caseTreeNode: caseTree,
      path: parent_folder_path,
      setSelectedCaseOrFolder: setSelectedCaseOrFolder,
    });

    closeModal();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpenModal}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody pb={1}>
          {modalType === "deleteObject" ? (
            <Flex width={"100%"} justifyContent={"center"}>
              <Text mt="20px" fontWeight={"bold"} whiteSpace={"pre"}>
                {selectedCaseOrFolder?.type === "folder"
                  ? "해당 폴더를 삭제하시겠습니까?\n하위 폴더와 판례도 모두 삭제됩니다."
                  : `해당 판례를 삭제하시겠습니까?`}
              </Text>
            </Flex>
          ) : (
            <FormControl>
              <FormLabel my="20px">
                {modalType === "newFolder"
                  ? "새폴더 이름을 입력해주세요"
                  : "변경할 폴더의 이름을 입력해주세요"}
              </FormLabel>
              {modalType === "renameFolder" ? (
                <Input
                  ref={initialRef}
                  value={folderNameToChange}
                  onChange={handleOnChangeRename}
                />
              ) : (
                <Input
                  ref={initialRef}
                  value={newFolderName}
                  onChange={handleOnChangeNewName}
                />
              )}
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size={"sm"} mr={3} onClick={closeModal}>
            {"취소"}
          </Button>
          <Tooltip
            bg="gray.500"
            placement="top"
            label={
              isAlreadyUsedFolderNameInSeletecd ||
              isAlreadyUsedFolderNameInParent
                ? "이미 사용된 이름입니다."
                : undefined
            }
          >
            <Button
              size={"sm"}
              bg={"blue.600"}
              isDisabled={
                modalType === "newFolder"
                  ? isAlreadyUsedFolderNameInSeletecd || newFolderName === ""
                  : modalType === "renameFolder"
                  ? isAlreadyUsedFolderNameInParent || folderNameToChange === ""
                  : false
              }
              color={"white"}
              _hover={{ bg: "blue.700" }}
              onClick={
                modalType === "newFolder"
                  ? onClickCreateNewFolder
                  : modalType === "deleteObject"
                  ? onClickDeleteObject
                  : modalType === "renameFolder"
                  ? onClickRenameFolder
                  : undefined
              }
            >
              {modalType === "newFolder"
                ? "만들기"
                : modalType === "renameFolder"
                ? "변경"
                : "삭제"}
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ModalInCaseArchive;
