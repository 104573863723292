import * as React from "react";
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext, { AuthContextType } from "../context/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {
  let { user } = useContext(AuthContext) as AuthContextType;

  return !user ? <Navigate to="/login" /> : children;
};

export default PrivateRoute;
