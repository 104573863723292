import AuthContext, { AuthContextType } from "../context/AuthContext";
import { generateRandomString, requestServer } from "./Utils";
import { range } from "../utils/UIUtils";
import { FileTreeNode } from "./FileManager";

import React, { ReactNode } from "react";

const WEBSOCKET_URL = process.env.REACT_APP_WS_URL + "/ws/case_summarize/";

export type LLMModel =
  | "gpt-3.5-turbo-16k"
  | "gpt-3.5-turbo-1106"
  | "gpt-4"
  | "gpt-4-1106-preview";
export type Category = "case" | "onju";

export type CommentaryLawType =
  | "civil"
  | "civilProcedure"
  | "civilExecution"
  | "commercial"
  | "criminal"
  | "criminalProcedure";

export type CaseNameAnalysisResultType =
  | "exactCaseName"
  | "containsCaseName"
  | "unrelated";

export type SortKeysByOrder = (
  keys: string[],
  order: string[],
  specialKey: string,
) => string[];

export interface CaseNameAnalysisResult {
  type: CaseNameAnalysisResultType;
  caseNumber: string | undefined;
}

export const lawTypeKoreanMapSimple = {
  civil: "민법",
  civilProcedure: "민소법",
  civilExecution: "민사집행법",
  commercial: "상법",
  criminal: "형법",
  criminalProcedure: "형소법",
};

export interface SearchHistory {
  deleted: number;
  id: number;
  question: string;
  time: string;
  user_id: number;
}

export const commentaryLawTypes: CommentaryLawType[] = [
  "civil",
  "civilProcedure",
  "civilExecution",
  "commercial",
  "criminal",
  "criminalProcedure",
];

export const lawTypeKoreanMap = {
  civil: "민법",
  civilProcedure: "민사소송법",
  civilExecution: "민사집행법",
  commercial: "상법",
  criminal: "형법",
  criminalProcedure: "형사소송법",
};

export interface CaseSummary {
  case_id: number | undefined;
  state: "Ready" | "Loading" | "Finished" | "Error";
  summary: string;
}

export interface Case {
  case_id: number;
  name: string;
  contents_json: any;
  case_information: CaseInforamtion;
}

export interface CaseSearchResult {
  cases: any[];
  error?: string;
}

export interface PdfFile {
  path: string;
  url: string | undefined;
  top: number;
  bottom: number;
  right: number;
  left: number;
  page: number;
}

export interface CaseInforamtion {
  법원: string;
  사건명: string;
  사건번호: string;
  사건종류: string;
  선고: string;
  선고일자: string;
  이유: string;
  주문: string;
  청구취지: string;
  판결유형: string;
}

export interface ExternalSearchResultsWithStatus {
  status: "Empty" | "Loading" | "Finished" | "Error";
  caseNameAnalysisResult?: CaseNameAnalysisResult;
  prioritizedSearchResults?: ExternalSearchResult[];
  searchResults?: ExternalSearchResult[];
  query?: string;
  took?: number;
  startIdxDisplay?: number;
  id?: number;
}

export interface RelevantPart {
  start_offset: number;
  end_offset: number;
  key: string;
  score: number;
}

export interface ExternalSearchResult {
  score: number;
  case_id: number;
  name: string;
  relevant_parts?: RelevantPart[];
  case?: Case;
}

export interface InternalSearchResultsWithStatus {
  status: "Empty" | "Loading" | "Finished";
  searchResults?: InternalSearchResult[];
  fileIdToObjectURLMap: {
    [key: number]: {
      url?: string;
      error?: string;
    };
  }; // first, get s3 temporal url and downlaod it. then, make object url.
}

export interface InternalSearchResult {
  score: number;
  top: number;
  left: number;
  bottom: number;
  right: number;
  content: number;
  page: number;
  file_manager_file_id: number;
  s3_key: string;
  num_partitions: number;
  partition_size: number | null;
  partition_id: number | null;
  url: string;
  path: string;
  path_string_id: string;
  name: string;
}

export function convertToJSX(str: any) {
  const parts = str.split(/<b>|<\/b>/g);
  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <b key={i}>{parts[i]}</b>;
  }
  return parts;
}

export function convertToJSXWithSpan(str: string, selectedSpanId?: string) {
  const regex = /<span id="([^"]*)">/g;
  let parts: React.ReactNode[] = [];
  let lastIndex: number = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(str)) !== null) {
    parts.push(str.slice(lastIndex, match.index));
    lastIndex = regex.lastIndex;
    const idValue: string = match[1];
    const contentStartIndex: number = match.index + match[0].length;
    const contentEndIndex: number = str.indexOf("</span>", lastIndex);
    const content = convertToJSX(str.slice(contentStartIndex, contentEndIndex));
    parts.push(
      <span key={idValue} id={idValue}>
        {content}
      </span>,
    );
    lastIndex = contentEndIndex + "</span>".length;
  }
  parts.push(str.slice(lastIndex));
  return parts;
}

export function emphasizeMatchingSubstrings(
  mainString: string,
  substrings: string[],
): string {
  for (const match of substrings) {
    const startIndex = mainString.indexOf(match);

    if (startIndex !== -1) {
      const beforeMatch = mainString.substring(0, startIndex);
      const afterMatch = mainString.substring(startIndex + match.length);
      mainString = `${beforeMatch}<em>${match}</em>${afterMatch}`;
    }
  }

  return convertToJSX(mainString);
}

export const getHighlightedParts = (str: any) => {
  if (str === undefined) return [];
  const parts = str.split(/<em>|<\/em>/g);
  return parts
    .filter((part, index) => index % 2 === 1)
    .filter((part) => part !== "");
};

export const getProfile = async (authContext: AuthContextType) => {
  // TODO error handling required
  return await requestServer(authContext, "/api/profile", null, "GET");
};

export const requestQuery = async (
  authContext: AuthContextType,
  query: string,
  k: number,
): Promise<any> => {
  const response = await requestServer(
    authContext,
    `/api/external_search/query`,
    {
      query,
      k,
    },
  );
  return {
    searchResults: response.search_results,
    took: response.took,
  };
};

export const requestAddHistory = async (
  authContext: AuthContextType,
  query: string,
): Promise<any> => {
  const response = await requestServer(
    authContext,
    `/api/external_search/add_history`,
    {
      query,
    },
  );
  return {
    searchResults: response.search_results,
    took: response.took,
  };
};

export const requestSearchHistory = async (
  authContext: AuthContextType,
  top_k: number = 10,
): Promise<any> => {
  const response = await requestServer(
    authContext,
    `/api/external_search/history`,
    {
      top_k,
    },
  );
  return response.history;
};
export const requestDeleteSearchHistory = async (
  authContext: AuthContextType,
  id: number,
): Promise<any> => {
  const response = await requestServer(
    authContext,
    `/api/external_search/history_delete`,
    {
      id,
    },
  );
  return response.history;
};

const findAndRemoveCaseNumber = (query: string): [string, string[]] => {
  const caseNumberPattern = `\\b\\d{2,4}(${caseSpecificSymbols.join(
    "|",
  )})\\d+\\b`;
  const patternCaseNumber = new RegExp(caseNumberPattern, "g");
  const caseNumbers = query.match(patternCaseNumber) || [];
  const caseNumberRemovedQuery = query.replace(patternCaseNumber, "").trim();
  return [caseNumberRemovedQuery, caseNumbers];
};

const removeBracketedText = (input: string): string => {
  const pattern = /\[[^\]]*\]/g;
  return input.replace(pattern, "").trim();
};

const removeSpecificStrings = (
  input: string,
  stringsToRemove: string[],
): string => {
  const escapedStrings = stringsToRemove.map((s) =>
    s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
  );
  const pattern = new RegExp(escapedStrings.join("|"), "g");
  return input.replace(pattern, "").trim();
};

const removeNumbersSymbolsSpaces = (str: string): string => {
  return str.replace(/[0-9\s\p{P}]/gu, "");
};

export const analyzeCaseNameInQuery = (
  query: string,
): CaseNameAnalysisResult => {
  // console.log("analyzeCaseNameInQuery", query);
  const startTime = new Date().getTime();
  const [caseNumberRemovedQuery, caseNumbers] = findAndRemoveCaseNumber(query);
  const caseNameRemovedBracketedText = removeBracketedText(
    caseNumberRemovedQuery,
  );
  // console.log("caseNumbers", caseNumbers);
  const caseNameRemovedSpecificStrings = removeSpecificStrings(
    caseNameRemovedBracketedText,
    judgmentTypes,
  );
  // console.log("caseNameRemovedSpecificStrings", caseNameRemovedSpecificStrings);
  const caseNameRemovedCourtTypes = removeSpecificStrings(
    caseNameRemovedSpecificStrings,
    courtTypes,
  );
  // console.log("caseNameRemovedCourtTypes", caseNameRemovedCourtTypes);
  const caseNameRemovedEtcCharacters = removeSpecificStrings(
    caseNameRemovedCourtTypes,
    etcCharacters,
  );
  // console.log("caseNameRemovedEtcCharacters", caseNameRemovedEtcCharacters);
  const caseNameRemovedNumbersSymbolsSpaces = removeNumbersSymbolsSpaces(
    caseNameRemovedEtcCharacters,
  );
  const endTime = new Date().getTime();
  // console.log("analyzeCaseNameInQuery", endTime - startTime, "ms");
  // console.log(
  //   "caseNameRemovedNumbersSymbolsSpaces",
  //   caseNameRemovedNumbersSymbolsSpaces
  // );
  if (caseNumbers.length === 0) {
    return {
      type: "unrelated",
      caseNumber: undefined,
    };
  } else {
    if (caseNameRemovedNumbersSymbolsSpaces === "") {
      return {
        type: "exactCaseName",
        caseNumber: caseNumbers[0],
      };
    } else {
      return {
        type: "containsCaseName",
        caseNumber: caseNumbers[0],
      };
    }
  }
};

export const requestInternalSearchQuestionAndSearch = async (
  authContext: AuthContextType,
  fileTreeRoot: FileTreeNode,
  parent_folder_id_list: number[],
  question: string,
): Promise<InternalSearchResult[]> => {
  const response = await requestServer(
    authContext,
    `/api/internal_search/question_and_search`,
    {
      question: question,
      parent_folder_id_list: parent_folder_id_list,
    },
  );
  const searchResults: InternalSearchResult[] = response.search_results;
  const traverseAndSetPath = (
    node: FileTreeNode,
    currentPath: string,
    currentPathStingId: string,
  ) => {
    if (node.type === "folder") {
      node.children.forEach((child) => {
        traverseAndSetPath(
          child,
          currentPath + "/" + node.name,
          currentPathStingId + "/" + node.string_id,
        );
      });
    } else {
      searchResults.forEach((searchResult) => {
        if (searchResult.file_manager_file_id === node.id) {
          searchResult.path = currentPath + "/" + node.name;
          searchResult.path_string_id =
            currentPathStingId + "/" + node.string_id;
          searchResult.name = node.name;
        }
      });
    }
  };
  const userName = authContext.user?.username;
  fileTreeRoot.children.forEach((child) => {
    traverseAndSetPath(child, `${userName}`, "");
  });
  return searchResults;
};

export const requestRelevantParts = async (
  authContext: AuthContextType,
  caseIdList: number[],
  query: string,
): Promise<any> => {
  const response = await requestServer(
    authContext,
    `/api/external_search/relevant_parts`,
    {
      query,
      case_id_list: caseIdList,
    },
  );
  return response;
};

export const getSelectedCase = ({ caseInfo }) => {
  const caseInformation = {
    법원: caseInfo.법원,
    사건명: caseInfo.사건명,
    사건번호: caseInfo.사건번호,
    사건종류: caseInfo.사건종류,
    선고: caseInfo.선고,
    선고일자: caseInfo.선고일자,
    이유: caseInfo.이유,
    주문: caseInfo.주문,
    청구취지: caseInfo.청구취지,
    판결유형: caseInfo.판결유형,
  };
  return {
    case_id: caseInfo.id,
    name: JSON.parse(caseInfo.contents_json).제목,
    contents_json: JSON.parse(caseInfo.contents_json),
    case_information: caseInformation,
  };
};

export const keyAndOffsetsToDocDic = (keys_and_offsets, contentsJson) => {
  // group by key
  const grouped = {};
  keys_and_offsets.forEach((key_and_offset) => {
    if (grouped[key_and_offset.key] === undefined) {
      grouped[key_and_offset.key] = [];
    }
    grouped[key_and_offset.key].push({
      start_offset:
        key_and_offset.start_offset - 300 < 0
          ? 0
          : key_and_offset.start_offset - 300,
      end_offset:
        key_and_offset.end_offset + 300 >
        contentsJson[key_and_offset.key].length
          ? contentsJson[key_and_offset.key].length
          : key_and_offset.end_offset + 300,
    });
  });
  // sort by start_offset
  Object.keys(grouped).forEach((key) => {
    grouped[key].sort((a, b) => a.start_offset - b.start_offset);
  });
  // merge offsets
  const merged = {};
  Object.keys(grouped).forEach((key) => {
    const mergedOffsets: any[] = [];
    grouped[key].forEach((key_and_offset) => {
      if (mergedOffsets.length === 0) {
        mergedOffsets.push(key_and_offset);
      } else {
        const last = mergedOffsets[mergedOffsets.length - 1];
        if (last.end_offset >= key_and_offset.start_offset) {
          last.end_offset = key_and_offset.end_offset;
        } else {
          mergedOffsets.push(key_and_offset);
        }
      }
    });
    merged[key] = mergedOffsets;
  });

  // generate docStr
  const docDic = {};
  Object.keys(merged).map((key) => {
    const offsets = merged[key];
    const content = contentsJson[key];
    const parts: string[] = [];
    offsets.forEach((offset) => {
      parts.push(content.substring(offset.start_offset, offset.end_offset));
    });
    docDic[key] = parts;
  });
  return docDic;
};

export const requestAddSummarizeMessage = async (
  authContext: AuthContextType,
  projectId: number,
  userMessage,
  summarizeMessage,
): Promise<any> => {
  const response = await requestServer(
    authContext,
    `/api/external_search/add_summarize_message`,
    {
      user_message: userMessage,
      summarize_message: summarizeMessage,
      project_id: projectId,
    },
  );
  return response;
};

export const requestCaseInfoByCaseNumber = async (
  authContext: AuthContextType,
  caseNumber: string | undefined,
): Promise<CaseSearchResult> => {
  if (caseNumber === undefined) {
    return { cases: [] };
  }
  try {
    const response = await requestServer(
      authContext,
      `/api/external_search/cases_by_case_number`,
      {
        case_number: caseNumber,
      },
    );
    return { cases: response };
  } catch (err) {
    console.log(err);
    return { cases: [], error: String(err) };
  }
};

export const Highlight = ({ top, left, width, height }) => (
  <div
    style={{
      position: "absolute",
      top: top,
      left: left,
      width: width,
      height: height,
      backgroundColor: "yellow",
      opacity: 0.15,
    }}
  />
);

export const sortKeysByOrder: SortKeysByOrder = (keys, order, specialKey) => {
  return keys.sort((a, b) => {
    const indexA = order.indexOf(a);
    const indexB = order.indexOf(b);
    const indexSpecial = order.indexOf(specialKey);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else if (indexSpecial !== -1) {
      // "이유" 바로 앞에 위치하는 로직이 필요하면 여기에 추가
      // 이 예제에서는 "이유" 바로 앞의 로직을 구현하지 않고 기본적인 정렬만 제공
      return a.localeCompare(b);
    } else {
      return 0;
    }
  });
};

export const caseSectionOrder = [
  "판시사항",
  "판결요지",
  "참조조문",
  "참조판례",
  "피고인",
  "상고인,피고인",
  "피고인겸피치료감호청구인,피부착명령청구자,치료명령피청구자",
  "피고인겸피치료감호청구인",
  "피고인겸피부착명령청구자",
  "피고인겸치료명령피청구자",
  "피감호청구인",
  "원고인",
  "상고인",
  "항소인",
  "검사",
  "변호인",
  "변호사",
  "제1심",
  "원심판결",
  "원판결",
  "원심결정",
  "제1심판결",
  "원고",
  "원고,피항고인",
  "원고,항고인",
  "원고,항소인",
  "원고,피항소인",
  "원고,피상고인",
  "원고,상고인",
  "원고(재심피고),피상고인",
  "원고(재심피고),피항소인",
  "원고(재심원고),항소인",
  "원고(반소피고),피상고인",
  "원고,항소인,재심원고",
  "원고(반소피고)",
  "원고,피상고인겸상고인",
  "원고,상고인겸피상고인",
  "원고보조참가인",
  "피고,상고인겸피상고인",
  "피고,피상고인겸상고인",
  "피고",
  "피고,피항고인",
  "피고,항고인",
  "피고,항소인",
  "피고,피항소인",
  "피고,피상고인",
  "피고,상고인",
  "피고(재심원고),상고인",
  "피고(재심원고),항소인",
  "피고(재심피고),피항소인",
  "피고(반소원고)",
  "피고(반소원고),상고인",
  "피고,피항소인,재심피고",
  "재항고인",
  "피고보조참가인",
  "원심판결",
  "원판결",
  "원심결정",
  "환송판결",
  "제1심",
  "제1심판결",
  "변론종결",
  "이유",
  "다시쓰는판결",
  "다시쓰는판결이유",
  "범죄사실",
  "감호요건사실",
  "범죄사실및증거의요지",
  "증거의요지",
  "법령의적용",
  "적용법조",
  "피고인및변호인의주장에대한판단",
  "피고인과변호인의주장에관한판단",
  "양형이유",
  "양형의이유",
  "공소기각부분",
  "신상정보의등록및제출",
  "무죄부분",
];

export const beutifyString = (str: string) => {
  if (str === "원고,피항고인") {
    return "원고, 피항고인";
  }
  if (str === "원고,항고인") {
    return "원고, 항고인";
  }
  if (str === "원고,피상고인") {
    return "원고, 피상고인";
  }
  if (str === "원고,상고인") {
    return "원고, 상고인";
  }
  if (str === "원고,피상고인겸상고인") {
    return "원고, 피상고인 겸 상고인";
  }
  if (str === "상고인,피고인") {
    return "상고인, 피고인";
  }
  if (str === "피고,피항고인") {
    return "피고, 피항고인";
  }
  if (str === "피고,항고인") {
    return "피고, 항고인";
  }
  if (str === "원고,피항소인") {
    return "원고, 피항소인";
  }
  if (str === "원고,항소인") {
    return "원고, 항소인";
  }
  if (str === "피고,피항소인") {
    return "피고, 피항소인";
  }
  if (str === "피고,항소인") {
    return "피고, 항소인";
  }
  if (str === "피고,피상고인") {
    return "피고, 피상고인";
  }
  if (str === "피고,상고인") {
    return "피고, 상고인";
  }
  if (str === "피고보조참가인") {
    return "피고 보조참가인";
  }
  if (str === "피고,상고인겸피상고인") {
    return "피고, 상고인 겸 피상고인";
  }
  if (str === "원고(반소피고),피상고인") {
    return "원고(반소피고), 피상고인";
  }
  if (str === "피고(반소원고),상고인") {
    return "피고(반소원고), 상고인";
  }
  if (str === "원고(재심원고),항소인") {
    return "원고(재심원고), 항소인";
  }
  if (str === "피고(재심피고),피항소인") {
    return "피고(재심피고), 피항소인";
  }
  if (str === "원고,항소인,재심원고") {
    return "원고, 항소인, 재심원고";
  }
  if (str === "피고,피항소인,재심피고") {
    return "피고, 피항소인, 재심피고";
  }
  if (str === "증거의요지") {
    return "증거의 요지";
  }
  if (str === "법령의적용") {
    return "법령의 적용";
  }
  if (str === "양형이유") {
    return "양형 이유";
  }
  if (str === "범죄사실및증거의요지") {
    return "범죄사실 및 증거의 요지";
  }
  if (str === "양형의이유") {
    return "양형의 이유";
  }
  if (str === "피고인겸피치료감호청구인") {
    return "피고인 겸 피치료감호청구인";
  }
  if (str === "피고인겸피부착명령청구자") {
    return "피고인 겸 피부착명령청구자";
  }
  if (str === "피고인겸치료명령피청구자") {
    return "피고인 겸 치료명령피청구자";
  }
  if (str == "피고인겸피치료감호청구인,피부착명령청구자,치료명령피청구자") {
    return "피고인 겸 피치료감호청구인, 피부착명령청구자, 치료명령피청구자";
  }
  if (str === "피고인및변호인의주장에대한판단") {
    return "피고인 및 변호인의 주장에 대한 판단";
  }
  if (str === "다시쓰는판결이유") {
    return "다시 쓰는 판결 이유";
  }
  if (str === "다시쓰는판결이유(배상명령신청각하부분제외)") {
    return "다시 쓰는 판결 이유 (배상명령신청 각하 부분 제외)";
  }
  if (str === "신상정보의등록및제출") {
    return "신상정보의 등록 및 제출";
  }

  return str;
};

const caseSpecificSymbols = [
  "가합",
  "가단",
  "가소",
  "나",
  "다",
  "라",
  "마",
  "그",
  "바",
  "머",
  "자",
  "차",
  "차전",
  "러",
  "카합",
  "카단",
  "카공",
  "카담",
  "카명",
  "카불",
  "카조",
  "카구",
  "카확",
  "카열",
  "카임",
  "카정",
  "카경",
  "카소",
  "카기",
  "타경",
  "타채",
  "타배",
  "타인",
  "타기",
  "비합",
  "비단",
  "회합",
  "회단",
  "간회합",
  "간회단",
  "회확",
  "회기",
  "하합",
  "하단",
  "하확",
  "하면",
  "하기",
  "개회",
  "개확",
  "개기",
  "국승",
  "국지",
  "과",
  "책",
  "정가",
  "정명",
  "정라",
  "정마",
  "고합",
  "고단",
  "고정",
  "고약",
  "고약전",
  "노",
  "도",
  "로",
  "모",
  "오",
  "보",
  "코",
  "조",
  "토",
  "초적",
  "초보",
  "초재",
  "너",
  "츠",
  "즈합",
  "즈단",
  "즈기",
  "느합",
  "느단",
  "후개",
  "후감",
  "후기",
  "호명",
  "호기",
  "호협",
  "구합",
  "구단",
  "누",
  "두",
  "루",
  "무",
  "부",
  "사",
  "아",
  "허",
  "후",
  "흐",
  "히",
  "카허",
  "수",
  "우",
  "수흐",
  "주",
  "추",
  "쿠",
  "정드",
  "정브",
  "정스",
  "정과",
  "정러",
  "정머",
  "정국",
  "정루",
  "정무",
  "정관",
  "정르",
  "정므",
  "초사",
  "초기",
  "감고",
  "감노",
  "감도",
  "감로",
  "감모",
  "감오",
  "감토",
  "감초",
  "전고",
  "전노",
  "전도",
  "전오",
  "전초",
  "전로",
  "전모",
  "보고",
  "보노",
  "보도",
  "보오",
  "보초",
  "보로",
  "보모",
  "치고",
  "치노",
  "치도",
  "치오",
  "치초",
  "초치",
  "치로",
  "치모",
  "동고",
  "동노",
  "동도",
  "동오",
  "동초",
  "푸",
  "크",
  "트",
  "푸초",
  "푸집",
  "버",
  "서",
  "어",
  "저",
  "버집",
  "처",
  "커",
  "터",
  "처집",
  "동버",
  "동서",
  "동어",
  "동저",
  "동버집",
  "동처",
  "동커",
  "동터",
  "동처집",
  "성",
  "성로",
  "성모",
  "성초",
  "인",
  "인라",
  "인마",
  "인카",
  "정고",
  "정기",
  "정로",
  "정모",
  "드합",
  "드단",
  "르",
  "므",
  "브",
  "스",
  "으",
];

const judgmentTypes = [
  "판결",
  "판결 : 항소",
  "판결 : 확정",
  "전원합의체 판결",
  "결정",
  "판결 : 상고",
  "전원합의체 결정",
  "판결(주1)",
  "명령",
  "결정 : 환송",
  "결정 : 확정",
  "심판 : 확정",
  "결정 : 재항고",
  "심판",
  "판결 : 환송",
  "판결 : 상고(기각)",
  "결정 : 항고",
  "판결：상고기각",
  "판결：항소기각, 상고기각",
  "판결：확정",
  "판결：상고",
  "민사부판결 : 확정",
  "특별부판결 : 확정",
  "내지 177 판결",
  "민사제2부판결 : 확정",
  "민사제3부판결 : 확정",
  "제2특별부판결 : 확정",
  "민사제1부판결 : 확정",
  "제2특별부판결: 확정",
  "제2특별부판결 : 상고",
  "특별부판결 : 상고",
  "민사제2부판결 : 상고",
  "민사제1부판결 : 상고",
  "민사제3부판결 : 상고",
  "제1형사부판결 : 확정",
  "제1특별부판결 : 상고",
  "민사제4부판결 : 확정",
  "특별제1부판결 : 상고",
  "민사부판결 : 상고",
  "제1특별부판결 : 확정",
  "제1부특별판결 : 상고",
  "제3민사부판결 : 상고",
  "제2민사부판결 : 상고",
  "제4민사부판결 : 확정",
  "제1민사부판결 : 상고",
  "제3특별부판결 : 상고",
  "형사제2부판결 : 확정",
  "제3민사부판결 : 확정",
  "제2형사부판결 : 확정",
  "특별제1부판결 : 확정",
  "제4형사부판결 : 확정",
  "제3형사부판결 : 확정",
  "제1민사부판결 : 확정",
  "제7민사부판결 : 확정",
  "제8민사부판결 : 확정",
  "제2민사부판결 : 확정",
  "제2민사부 판결 : 확정",
  "제1형사부판결 : 상고",
  "제2부민사부판결 : 확정",
  "민사상고부판결",
  "형사상고부판결",
  "제5민사부판결 : 확정",
  "형사부판결 : 확정",
  "제6민사부판결 : 상고",
  "형사부판결 : 상고",
  "민사상고제2부판결",
  "제3민사부판결",
  "제형사부판결 : 확정",
  "제4민사부판결 : 상고",
  "민사항소부판결 : 상고",
  "제3민사부 판결 : 상고",
  "제2형사부판결 : 상고",
  "제5민사부판결 : 상고",
  "제6민사항소부판결 : 확상",
  "제6민사부판결 : 확정",
  "전원합의체판결",
  "제2민사부결정 : 재항고",
  "제7민사부판결 : 상고",
  "제9민사부판결 : 확정",
  "제10민사부판결 : 상고",
  "제11민사부판결 : 확정",
  "제11민사부판결 : 상고",
  "제9민사부판결 : 상고",
  "형사부판결",
  "제10민사부판결 : 확정",
  "제1부 판결",
  "제3부 판결",
  "제1부결정",
  "제2부 판결",
  "제8민사부판결 : 상고",
  "제3부 결정",
  "제1부 결정",
  "제2부 결정",
  "제1특별부판결 : 재항고",
  "제2민사부판결 :",
  "제6민사분판결 : 상고",
  "제1민사부판결 : 재항고",
  "제1특별부판결",
  "제4민사부판결",
  "제1부특별부판결 : 확정",
  "제1형사부결정 : 상고",
  "제9민사부판결 : 이송",
  "제10민사부판결 : 환송",
  "제6민사부판결 : 확정",
  "제2민사부판결 : 환송",
  "제12민사부판결 : 확정",
  "제2민사부 판결 : 상고",
  "형사부 판결 : 확정",
  "제12민사부판결 : 상고",
  "제11민사부판결 : 판결",
  "제1민사부결정 : 이송",
  "제12민사부판결 : 환송",
  "제7부민사판결 : 상고",
  "제민사2부판결 : 상고",
  "제1부판결",
  "제1형사부결정 : 확정",
  "제4부민사부판결 : 상고",
  "제3민사부판결 : 환송",
  "제3형사부판결 : 상고",
  "제6민사판결 : 확정",
  "제2형사부판결 : 환송",
  "제1민사부판결 : 환송",
  "제7만사부판결 : 상고",
  ": 상고",
  "제3민사부결정 : 확정",
  "제3형사부결정 : 확정",
  "제2가사심판부심판 : 상고",
  "제2민사부심판 : 상고",
  "심결",
  "제1부판결 : 상고",
  "제2형사부결정 : 인용",
  "제2형사부판결 : 파기",
  "제2형사부결정 : 재항고",
  "제7민사부 판결 : 상고",
  "제1형사부결정 : 재항고",
  "제4민사부결정 : 확정",
  "제4민사부결정 : 재항고",
  "제1특별부심판 : 상고",
  "제2특별부판결 :상고",
  "제2형사부판결  : 상고",
  "형사부판결 :확정",
  "제11민사부판결",
  "제9형사부판결 : 확정",
  "제6민사부판결: 상고",
  "제4민사부판결 :상고",
  "제5민사부판결: 확정",
  "제4민사부판결 : 환송",
  "제2특수부판결 : 확정",
  "제3특별부판결 : 확정",
  "제4형사부판결 : 상고",
  "제2민사부판결 : 재항고",
  "재정",
  "제1민사부판결  : 상고",
  "제3부판결 : 확정",
  "제4부판결",
  "제3특별부 : 확정",
  "제2민사판결 : 확정",
  "제11민사부판결 :상고",
  "제1민사부판결 : 파기환송",
  "형사부결정 : 항고",
  "제1민사부판결 : 상고불허가",
  "제2민사부판결 : 권리상고",
  "제2민사부판결 : 상고불허가",
  "제3민사부판결 : 권리상고",
  "제1민사부판결 : 권리상고",
  "제3민사부판결 : 상고불허가",
  "제7민사부판결 : 상고허가",
  "제5민사부판결 : 상고불허가",
  "제7민사부판결 : 상고불허가",
  "제8민사부판결 : 상고불허가",
  "제9민사부판결 : 상고불허가",
  "제4형사부판결·확정",
  "제1형부판결 : 상고",
  "제4특별부판결 : 확정",
  "제5형사부판결 : 상고",
  "제4특별부판결 : 상고",
  "제5형사부판결 : 확정",
  "판결",
  "제12민사판결 : 확정",
  "제8부판결 : 확정",
  "제1부심판판결 : 항소",
  "제10부판결 : 확정",
  "제5부판결 : 항소",
  "제7부판결 : 확정",
  "제2민사부판결 : 항소",
  "제2민사부판결 : 상고허가",
  "제4민사부판결 : 항소",
  "제1부판결 : 상고불허가",
  "제3민사부판결 : 항소",
  "제10부판결 : 항소",
  "제3부심판 : 확정",
  "제17부판결 : 확정",
  "제11부판결 : 항소",
  "제4형사부판결 : 항소",
  "제8부판결 : 항소",
  "제1민사부판결 : 항소",
  "제2항고부결정 : 재항고",
  "제1부심판 : 미정",
  "제2형사부판결 : 항소",
  "제1부판결 : 상고허가",
  "제13부판결 : 항소",
  "제17부판결 : 항소",
  "가사부심판 : 확정",
  "제1부심판 : 항소",
  "제11부판결 : 확정",
  "제9부판결 : 확정",
  "제3형사부판결 : 항소",
  "제15부판결 : 확정",
  "제15부판결 : 항소",
  "제1민사부결정 : 확정",
  "제1민사부판결 : 상고허가",
  "제1민사부결정 : 재항고",
  "형사부판결 : 항소",
  "제9민사부판결 : 항소",
  "제2항고부결정 : 확정",
  "제1가사부심판 : 확정",
  "제6민사부판결 : 항소",
  "제8민사부판결 : 항소",
  "제7부판결 : 항소",
  "제5부판결 : 확정",
  "제5부민사부판결 : 항소",
  "제5부판결 : 상고",
  "제7민사부판결 : 항소",
  "민사부판결 : 항소",
  "제16부판결 : 항소",
  "제3부판결 : 상고",
  "제3가사부심판 : 확정",
  "제12부판결 : 항소",
  "제13민사부판결 : 상고",
  "제1특별부 : 확정",
  "민사합의부판결 : 항소",
  "제5민사부판결 : 항소",
  "제13민사부판결 : 확정",
  "제6형사부판결 : 상고",
  "제5부결정 : 확정",
  "합의부판결 : 확정",
  "제14부판결 : 확정",
  "제5형사부결정 : 항고",
  "제6형사부판결 : 확정",
  "제2부판결 : 상고",
  "제5특별부판결 : 확정",
  "제1부판결 : 확정",
  "제2민사부결정 : 항고",
  "제9부판결 : 항소",
  "제7부판결 : 상고",
  "제14민사부판결 : 상고",
  "제3부결정 : 재항고",
  "제3부심판 : 항소",
  "제8민사부판결 : 환송",
  "합의부 판결 : 항소",
  "제6부판결 : 확정",
  "제2부판결 : 확정",
  "제14민사부판결 : 확정",
  "제1부결정 : 재항고",
  "제5특별부판결 : 상고",
  "제1부결정 : 확정",
  "제16부판결 : 확정",
  "제6특별부판결 : 상고",
  "제4부판결 : 확정",
  "제4부판결 : 상고",
  "판결 :항소",
  "제2형사부 판결 : 확정",
  "제3민사부판결 항소",
  "제9부판결 : 상고",
  "판결 :확정",
  "제5부심판 : 확정",
  "제12민사부 판결 : 상고",
  "제14민사부판결 :확정",
  "제1형사부 판결 : 상고",
  "제2민사부판결 :확정",
  "제6민사부판결 : 상고허가 신청기각",
  "제2부심판 : 확정",
  "제15민사부판결 : 상고",
  "제16민사부판결 : 상고",
  "제2판결부판결 : 확정",
  "제11민사부결정 : 확정",
  "제1형사부판결 : 항소",
  "제9민사부결정 : 확정",
  "제16민사부판결 : 확정",
  "제2부심판 : 항소",
  "제3가사심판부심판 : 확정",
  "제2부판결 : 재항고",
  ": 항소",
  ": 확정",
  "제2민사부 : 항소",
  "재4민사부판결 : 확정",
  "제5민사부판결 : 상고허가 신청기각",
  "제14부판결 : 항소",
  "제5부판결 : 상고허가 신청기각",
  "제15민사부판결 : 확정",
  "제6민사부판결 : 상고허가신청",
  "민사부합의판결 : 항소",
  "제8부판결 : 상고",
  "제3민사부결정 : 재항고",
  "판결 : 항고",
  "제2민사부판결 : 쌍방항소",
  "제3부판결",
  "제6부판결 : 상고",
  "제4형사부판결: 일부상고",
  "판결 : 상고기각",
  "제5민사부판결 : 확정)",
  "제12부판결 : 확정",
  "판",
  "제12민사부판결 : 항소",
  "제11민사부판결 : 항소",
  "제5가사심판부심판 : 확정",
  "제2부심판 :확정",
  "제6민사부결정 : 확정",
  "제3형사부판결",
  "판결",
  "제6부판결 : 항소",
  "제7특별부판결 : 상고",
  "제2형사부판결 : 파기환송",
  "제2민사부판결 :상고",
  "제6특별부판결 : 확정",
  "제1민사부 판결 : 확정",
  "제4민사부 판결 : 상고",
  "형사부 판결 : 상고",
  "제3특별부 : 상고",
  "제3민사부 판결 : 확정",
  "제2형사부 판결 : 항소",
  "제1민사부 판결 : 상고",
  "제3특별부 판결 : 상고",
  "제2민사부 결정 : 재항고",
  "형사부 판결 : 항소",
  "제2특별부 판결 : 상고",
  "제3형사부 판결 : 확정",
  "제1특별부 판결 : 확정",
  "제4특별부 판결 : 확정",
  "제2민사부판결 : 확정",
  "제12민사부 판결 : 항소",
  "민사부 판결 : 확정",
  "제3민사부 판결 : 항소",
  "제1형사부 판결 : 확정",
  "제7부 판결 : 항소",
  "제4민사부 판결 : 확정",
  "제15민사부 판결 : 상고",
  "특별부판결:상고",
  "제4특별부 판결 : 상고",
  "제4민사부판결 : 상고허가신청기각",
  "민사부 판결 : 항소",
  "제7특별부 판결 : 상고",
  "판결 제4특별부 : 확정",
  "가사심판부 결정 : 확정",
  "판결 특별부 : 확정",
  "제8부 판결 : 상고",
  "제5민사부 판결 : 항소",
  "제7부 판결 : 확정",
  "제7특별부판결 : 확정",
  "가사심판부심판 : 확정",
  "제1민사부판결 : 상고허가 신청기각",
  "제5민사부판결 : 상고허가신청기각",
  "제11민사부판결 : 상고허가신청기각",
  "제2민사부판결 : 상고허가 신청기각",
  "제9민사부판결 : 상고허가신청기각",
  "(병합)판결",
  "제18부결정 : 확정",
  "제2민사부판결 : 상고허가신청기각",
  "제1민사부판결 : 상고허가신청기각",
  "제2형사부판결 : 상고기각",
  "제18부판결 : 항소",
  "제2특별부판결 : 상고기각",
  "제10민사부판결 : 항소",
  "제17민사부판결 : 상고",
  "제4민사부판결 : 상고허가 신청기각",
  "형사부판결 : 상고기각",
  "제11민사부판결 : 상고허가 신청기각",
  "제2특별부 판결 : 확정",
  "제12민사부판결 : 상고허가 신청기각",
  "제14민사부판결 : 상고허가 신청기각",
  "제1형사부판결 : 상고기각",
  "가사심판부심판 : 항소",
  "제3민사부 판결 :",
  "특별부판결 : 상고기각",
  "제11부판결 : 항소기각",
  "제10민사부판결 : 상고허가 신청기각",
  "제8민사부판결 : 상고기각신청기각",
  "제12민사부판결 : 상고허가신청기각",
  "제5형사부판결 : 상고기각",
  "제37부판결 : 항소",
  "제17부판결판결 : 항소",
  "제7민사부판결 : 상고장각하",
  "제3민사부판결 : 상고허가신청기각",
  "제7형사부판결 : 상고기각",
  "제1민사부판결 : 상고기각",
  "제3특별부판결 : 파기자판",
  "제14부판결 : 소취하",
  "제3특별부판결 : 파기환송",
  "재13민사부판결 : 확정",
  "합의부판결 : 항소",
  "제1특별부판결 : 상고기각",
  "제50부결정 : 확정",
  "제10부결정 : 확정",
  "제6특별부판결 : 상고기각",
  "제1부심판 : 확정",
  "제13민사부판결 : 상고허가 신청기각",
  "제4부심판 : 확정",
  "제10민사부판결 : 상고허가신청기각",
  "제3부판결 : 상고기각",
  "제2형사부판결 : 파기자판",
  "제1민사부결정 : 재항고기각",
  "제7민사부판결 : 상고허가 신청기각",
  "특별부판결 : 상고취하",
  "제6민사부판결 : 상고허가신청기각",
  "제5민사부판결 : 항소기각",
  "제9민사부판결 : 상고허가 신청기각",
  "형사부결정 : 확정",
  "제2부판결 : 상고허가 신청기각",
  "제2민사부판결 : 항소기각",
  "제7민사부판결 : 파기환송",
  "제12부판결:항소",
  "제11민사부판결 : 일부파기환송",
  "가사부심판 : 항소",
  "제1특별부판결 : 파기환송",
  "제3특별부판결 : 상고기각",
  "제6특별부판결 : 파기환송",
  "제42부판결 : 확정",
  "제13민사부판결 : 상고기각",
  "제7특별부판결 : 상고기각",
  "제42부판결 : 항소",
  "제3민사부판결 : 상고허가 신청기각",
  "제5민사부판결 : 상고기각",
  "제42부판결 : 항소",
  "제8민사부판결 : 상고기각",
  "제11부판결 : 상고",
  "제5특별부판결 : 파기환송",
  "제23부결정 : 확정",
  "제6부심판 : 확정",
  "제5특별부판결 : 상고기각",
  "제41부판결 : 항소",
  "제2민사부판결 : 상고기각",
  "제4특별부판결 : 상고기각",
  "제2민사부결정 : 확정",
  "반소판결",
  "제1형사부판결 : 파기환송",
  "제8특별부판결 : 파기환송",
  "제19부판결 : 확정",
  "제42부판결 : 소취하",
  "제3민사부판결 : 상고기각",
  "선고",
  "제7민사부판결 : 상고기각",
  "제51부결정 : 확정",
  "제42부판결 : 항소기각",
  "제8특별부판결 : 상고기각",
  "제2민사부판결 : 상고취하",
  "결정 : 항고기각",
  "제4민사부판결 : 상고기각",
  "판결 : 항소기각",
  "제11민사부판결 : 상고기각",
  "제11민사부판결판결 : 항소",
  "제4특별부판결 : 상고허가 신청기각",
  "제19부판결 : 항소취하",
  "제4부판결 : 항소",
  "제13부판결 : 확정",
  "제7특별부판결 : 파기환송",
  "제4민사부판결 : 제1심판결취소, 상고기각",
  "제42민사부판결 : 항소",
  "파결",
  "판결{{}}",
  "제2부결정 : 재항고",
  "제10민사부판결 : 상고기각",
  "제41부판결 : 확정",
  "제1민사부 판결 : 항소",
  "제15민사부판결 : 항소",
  "제8특별부판결 : 상고",
  "제51부판결 : 확정",
  "제6민사부판결 : 상고기각",
  "제4민사부판결 : 파기환송",
  "제1부판결 : 상고기각",
  "제15부판결 : 항소기각",
  "제5형사부결정 : 확정",
  "제5형사부판결 : 상고기각",
  "제5부심판 : 항고",
  "제5부판결 : 상고기각",
  "제10특별부판결 : 상고",
  "제3민사부판결 : 파기환송",
  "제4특별부판결 : 상소기각",
  "제1부심판 : 항고",
  "제4부판결 : 상고기각",
  "제9특별부판결 : 상고기각",
  "제1부판결 : 항소",
  "제16민사부판결 : 항소기각",
  "제10특별부판결 : 상고기각",
  "제8민사부결정 : 확정",
  "형사부판결 : 항소기각",
  "제10특별부판결 : 확정",
  "가사부판결 : 항소",
  "제5부민사부판결 : 확정",
  "제9특별부판결 : 확정",
  "제36부판결 : 항소",
  "특별부판결 : 파기환송",
  "제23부판결 : 확정",
  "제2부판결 : 항소",
  "제22부판결 : 확정",
  "판결: 확정",
  "제2부판결 : 상고기각",
  "제33부판결 : 확정",
  "제4부판결 : 파기환송",
  "제9특별부판결 : 상고",
  "제36부판결 : 확정",
  "제3부판결 : 항소",
  "제3형사부판결 : 상고기각",
  "제19부판결 : 항소",
  "제37부판결 : 확정",
  "제6부판결 : 상고기각",
  "제2가사부심판 : 확정",
  "제4부결정 : 확정",
  "결정 : 재항고기각",
  "제6민사판결 : 항소",
  "제21부판결 : 항소기각",
  "가사부판결 : 확정",
  "위헌제청결정 : 미결정",
  "제2민사부심판 : 확정",
  "제50부판결 : 확정",
  "제2형사부결정 : 확정",
  "제4부판결 : 항소기각",
  "제6민사부판결 : 항소기각",
  "제9특별부판결 : 파기환송",
  "형사부판결 : 파기환송",
  "제2판사부판결 : 확정",
  "민사부판결 : 상고기각",
  "제25부판결 : 항소기각",
  "제20부판결 : 확정",
  "결정 : 헌법재판소미결정",
  "제31부판결 : 항소",
  "민사부결정 : 헌법재판소미결정",
  "제22부결정 : 헌법재판소미결정",
  "민사부결정 : 확정",
  "제1민사부 결정 : 확정",
  "제33부판결 : 항소",
  "제21민사부판결 : 확정",
  "제7부판결 : 상고기각",
  "제11부민사부판결 : 확정",
  "제1민사부 결정 : 본안상고",
  "제25부판결 : 상고",
  "제8특별부판결 : 확정",
  "제5민사부결정 : 확정",
  "제2부결정 : 확정",
  "제11형사부판결 : 항소",
  "제24부판결 : 항소",
  "제8부결정 : 확정",
  "민사결정 : 헌법재판소 미 결정",
  "제21부판결 : 항소",
  "제17민사부판결 : 확정",
  "제9민사부결정 : 재항고",
  "제18민사부판결 : 확정",
  "명령 : 소장각하·상고기각",
  "제3부심판 : 항고",
  "제51부결정 : 항고",
  "전원합의체결정",
  "제51부판결 : 항소",
  "제22부판결 : 항소",
  "제9부결정 : 확정",
  "제9부결정 : 재항고",
  "제11특별부판결 : 상고",
  "항고부결정 : 확정",
  "제10부판결 : 상고",
  "제50부결정 : 항고",
  "제2특별부결정 : 확정",
  "제9민사부 판결 : 확정",
  "제21부판결 : 확정",
  "판결(반소)",
  "제2부판결",
  "제3부심판 : 즉시항고",
  "제50부판결 : 항소",
  "제4민사부 판결 : 항소",
  "판결：항소",
  "판결 : 파기환송",
  "결정：헌법재판소 미결정",
  "결정 : 결정취소",
  "결정：확정",
  "판결 : 상고 후 소취하",
  "판결：항소 및 상고 기각",
  "판결 : 상고 기각",
  "심판 : 항고",
  "판결 : 항소",
  "판결：상고, 파기환송",
  "결정：항고",
  "판결：상고 기각",
  "판결(일부)：진행중",
  "판결：항소기각·상고기각",
  "심판 : 항소",
  "판결：확정",
  "판결：항소(화해)",
  "판결：항소(조정)",
  "판결：항소(파기·일부변경)",
  "판결：항소기각·상고",
  "판결：항소기각·일부파기환송",
  "판결：원심파기(양형)",
  "판결：항소기각",
  "판결：항소기각",
  "판결：파기이송",
  "판결：상고기각",
  "판결：취소기각·상고",
  "판결：항소기각, 상소",
  "판결：항소(변경)·상고",
  "판결：항소기각, 상고",
  "판결：항소(파기)·상고",
  "판결：항소(일부변경)·확정",
  "결정：특별항고 각하",
  "판결：항소기각, 상소기각",
  "판결：항소화해",
  "결정：재항고 기각",
  "판결：항소(일부 변경)·상고",
  "결정：재항고",
  "결정：재항고",
  "판결：항소",
  "판결：항소기각 확정",
  "판결：조정확정",
  "판결：상고파기환송",
  "결정：상고기각",
  "판결：파기환송(일부)",
  "판결：원심파기상고",
  "판결：이의신청",
  "판결 : 항소취하",
  "판결：항소기각, 상고 ",
  "판결：상고취하",
  "판결：상고",
  "판결：항소기각, 확정 ",
  "결정：확정",
  "판결：항소기각, 확정",
  "심판 : 항고",
  "판결：파기",
  "판결 : 상고",
  "판결：항소 조정성립",
  "판결：항소기각상고",
  "판결：항소취하",
  "결정：이의",
  "결정：일부항고",
  "결정：항고기각",
  "판결 : 파기",
  "판결：항소조정성립",
  "판결：항소심 원고 소취하",
  "판결：피고 항소취하",
  "판결：항소·상고기",
  "판결：항소심 조정 성립",
  "판결：항소·상고기각",
  "판결：상고취하",
  "판결：항소심 원고일부승소",
  "판결：항소 취하",
  "판결：항소기각·확정",
  "판결：항소·상고",
  "판결：항소심 소취하",
  "판결：항소심 조정성립",
  "판결：항소취하간주",
  "판결 : 항소 취하",
  "판결：파기환송",
  "판결 : 항소기각·상고기각",
  "판결 : 항소기각·상고",
  "판결 : 항소기각·확정",
  "판결 : 항소기각, 상고",
  "판결 : 항소심 소취하",
  "판결 : 항소심 화해",
  "판결 : 항소각하·상고기각",
  "판결 : 항소 기각",
  "판결 : 항소(원고일부승)",
  "판결 : 항소각하",
  "판결 : 상고각하",
  "판결 : 항소심 조정성립",
  "판결：항소, 소취하",
  "판결：항소심조정성립",
  "결정：이의신청취하",
  "판결：항소기각, 상고기각",
  "결정：재항고취하",
  "결정：기각",
  "판결：항소(일부 변경), 상고",
  "판결：항소취하 간주",
  "판결：항소(일부변경), 확정",
  "결정：항고기각, 확정",
  "판결：확정(상고취하)",
  "결정：항고기각, 재항고기각",
  "판결：항소, 조정성립",
  "판결：항소기각·상고(피고의 감액처분으로 소각하)",
  "판결：항소, 강제조정",
  "판결：변경판결, 상고",
  "결정：이의신청",
  "결정：재항고기각",
  "결정：항고취하",
  "심판：확정",
  "결정：항고, 신청취하",
  "결정：항고기각·확정",
  "판결：항소, 화해",
  "판결：항소, 조정",
  "판결：항소, 변경",
  "위헌제청결정：위헌결정",
  "판결：항소(양형 변경), 상고기각",
  "판결：항소(변경), 확정",
  "중간판결",
  "판결：상고기각·확정",
  "판결：항소(양형변경), 상고기각",
  "판결：항소기각, 상고기각(심리불속행)",
  "판결：심리불속행 기각",
  "판결：항소, 조정 성립",
  "판결: 상고",
  "판결：항소장각하명령, 확정",
  "판결：항소, 화해권고결정",
  "판결：상고기각(심리불속행)",
  "판결: 항소",
  "결정: 항고",
  "판결: 항소여부미정",
  "결정: 확정",
  "판결: 이의신청",
  "판결: 항소기각, 확정",
  "판결: 항소여부 미정",
  "판결: 항        소",
  "판결: 상고여부 미정",
  "결정: 항고여부미정",
  "판결: 상고, 소취하",
  "판결: 항소장각하",
  "심판: 항고",
  "결정: 특별항고",
  "판결: 항소기간 미도과",
  "판결: 항소장 각하",
  "판결: 항고",
  "판결 : 상고기간 미도과",
  "판결: 일부 항소",
  "판결: 사기: 확정",
  "결정 : 항고각하",
  "위헌제청결정: 헌법재판소 미결정",
  "결정: 재항고",
  "판결: 확정",
  "판결 : 항소기간미도과",
  "결정 : 상고",
  "판결 : 소취하",
  "결정 : 기각",
  "위헌제청결정",
  "결정 : 미확정",
  "결정 : 위헌제청",
  "재결",
  "판결경정결정",
  "결정 : 즉시항고",
  "판결 : 상고취하",
  "일부판결",
  "추가판결",
  "판결 : 미정",
  "판결 : 이송",
  "판결(채권양도금지 특약 사건)",
  "판결 : 상고(취하)",
  "결정 : 이의신청",
  "결정 : 이의",
];

const courtTypes = [
  "서울서부지방법원",
  "서울중앙지방법원",
  "대구고등법원",
  "서울고등법원",
  "대법원",
  "울산지방법원",
  "춘천지방법원강릉지원",
  "광주고등법원제주재판부",
  "수원지방법원",
  "청주지방법원",
  "인천지방법원",
  "대구지방법원",
  "서울북부지방법원",
  "제주지방법원",
  "서울행정법원",
  "부산고등법원",
  "서울남부지방법원",
  "대전지방법원서산지원",
  "대전지방법원천안지원",
  "서울가정법원",
  "광주고등법원",
  "창원지방법원",
  "부산지방법원",
  "대전고등법원청주재판부",
  "대전고등법원",
  "춘천지방법원속초지원",
  "의정부지방법원",
  "부산고등법원창원재판부",
  "서울동부지방법원",
  "대전지방법원",
  "광주지방법원",
  "수원고등법원",
  "춘천지방법원",
  "전주지방법원",
  "서울고등법원춘천재판부",
  "특허법원",
  "서울고등법원인천재판부",
  "부산지방법원동부지원",
  "서울회생법원",
  "대구지방법원서부지원",
  "의정부지방법원고양지원",
  "부산고등법원울산재판부",
  "대구가정법원",
  "대구지방법원포항지원",
  "광주고등법원전주재판부",
  "수원지방법원평택지원",
  "서울형사지방법원",
  "서울지방법원북부지원",
  "서울민사지방법원",
  "서울지방법원동부지원",
  "서울지방법원남부지원",
  "서울지방법원의정부지원",
  "전주지방법원군산지원",
  "대전지방법원홍성지원",
  "마산지방법원",
  "전주지방법원정주지원",
  "서울지방법원",
  "대전지방법원강경지원",
  "청주지방법원충주지원",
  "부산지방법원울산지원",
  "대구지방법원상주지원",
  "광주지방법원목포지원",
  "춘천지방법원원주지원",
  "마산지방법원거창지원",
  "서울지방법원서부지원",
  "광주지방법원순천지원",
  "대구지방법원김천지원",
  "수원지방법원여주지원",
  "창원지방법원충무지원",
  "창원지방법원진주지원",
  "대구지방법원경주지원",
  "광주지방법원해남지원",
  "청주지방법원영동지원",
  "춘천지방법원영월지원",
  "수원지방법원성남지원",
  "대구지방법원안동지원",
  "광주지방법원장흥지원",
  "전주지방법원정읍지원",
  "대전지방법원논산지원",
  "청주지방법원제천지원",
  "대구지방법원의성지원",
  "인천지방법원부천지원",
  "대전지방법원서산지원당진군법원",
  "대전지방법원공주지원",
  "대전지방법원공주지원청양군법원",
  "창원지방법원통영지원",
  "부산지방법원가정지원",
  "제26사단보통군사법원",
  "고등군사법원",
  "수원지방법원안산지원",
  "창원지방법원거창지원",
  "대구지방법원안동지원영주시법원",
  "제1군사령부보통군사법원",
  "해군작전사령부보통군사법원",
  "대구지방법원영덕지원",
  "대구지방법원가정지원",
  "제6사단보통군사법원",
  "중앙해양안전심판원",
  "수원지방법원안양지원",
  "국방부보통군사법원",
  "창원지방법원밀양지원",
  "부산가정법원",
  "대전가정법원",
  "대구가정법원안동지원",
  "특수전사령부보통군사법원",
  "창원지방법원마산지원",
  "전주지방법원남원지원",
  "제3군사령부보통군사법원",
  "광주가정법원",
  "대전가정법원공주지원",
  "수원지방법원여주지원이천시법원",
  "인천가정법원",
  "제2작전사령부보통군사법원",
  "인천가정법원부천지원",
  "수원가정법원",
  "수원가정법원성남지원",
  "부산지방법원서부지원",
  "서울서부법원",
  "수원가정법원안산지원",
];

const etcCharacters = ["(병합)", "(분리)", "(주1)"];
