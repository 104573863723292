import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import AuthContext, { AuthContextType } from "../context/AuthContext";
import { getProfile } from "../logics/Search";

import Navbar, { NavbarItem } from "../components/Navbar";
import CaseViewerNewTab from "../components/Search/CaseViewerNewTab";
import FileManagerTab from "../tabs/FileManagerTab";
import MainTab from "../tabs/MainTab";
import SearchTab from "../tabs/SearchTab";
import CaseArchiveTab from "../tabs/CaseArchiveTab";

const HomePage = () => {
  const authContext = React.useContext(AuthContext) as AuthContextType;
  React.useEffect(() => {
    getProfile(authContext);
  }, []);

  const getItem = (key: string, label: string, url: string): NavbarItem => {
    return {
      key,
      label,
      url,
    };
  };

  const navbarItems: NavbarItem[] = [
    getItem("판례보관함", "판례보관함", "case-archive"),
    getItem("파일관리", "파일관리", "file-manager"),
  ];

  const getContent = () => {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="main" replace />} />
        <Route path="/main" element={<MainTab />} />
        <Route path="/search/*" element={<SearchTab />} />
        <Route path="/case-archive" element={<CaseArchiveTab />} />
        <Route path="/file-manager/*" element={<FileManagerTab />} />
        <Route path="/case/:caseId" element={<CaseViewerNewTab />} />
      </Routes>
    );
  };

  return (
    <Flex direction={"column"}>
      <Navbar items={navbarItems} />
      <Flex>{getContent()}</Flex>
    </Flex>
  );
};

export default HomePage;
