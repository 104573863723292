import React from "react";
import { useContext } from "react";
import { Flex } from "@chakra-ui/react";
import { navbarHeight } from "../Navbar";
import { tabSelectorHeight } from "./FileManagerMain";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  SelectedFolder,
  FilesAndFolders,
  FileTreeNode,
  requestFilesAndFolders,
  makeFileTree,
  selectFolderByPath,
  TabItems,
} from "../../logics/FileManager";
import { InternalSearchResult } from "../../logics/Search";
import FileTreeExplorer from "./FileTreeExplorer";
import FileExplorerHeader from "./FileExplorerHeader";
import FileExplorerMain from "./FileExplorerMain";
import {
  fileExplorerHeaderFirstRowHeight,
  fileExplorerHeaderSecondRowHeight,
} from "./FileExplorerHeader";
import DisplaySearchResults from "./DisplaySearchResults";

export const FileTreeExplorerWidth = 350;
export const FileTreeExplorerRightBorderWidth = 1;
export const fileExplorerHeaderInnerHeight =
  fileExplorerHeaderFirstRowHeight + fileExplorerHeaderSecondRowHeight + 1;
export const fileExplorerHeaderTopMargin = 5;
export const fileExplorerHeaderHeight =
  fileExplorerHeaderInnerHeight + fileExplorerHeaderTopMargin;

const FileExplorer = ({
  tabItems,
  setTabItems,
}: {
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;
  const [filesAndFolders, setFilesAndFolders] = React.useState<
    FilesAndFolders | undefined
  >(undefined);
  const [fileTree, setFileTree] = React.useState<FileTreeNode | undefined>(
    undefined,
  );

  const userName = authContext.user?.username;
  const [selectedFolder, setSelectedFolder] = React.useState<SelectedFolder>({
    name: userName,
    id: null,
    path: "",
    path_string_id: "",
    children: undefined,
  });

  const [isLoadingFilesAndFolders, setIsLoadingFilesAndFolders] =
    React.useState<boolean>(false);
  const [filesLoadError, setFilesLoadError] = React.useState<boolean>(false);
  const [shouldBlinkFileTree, setShouldBlinkFileTree] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setIsLoadingFilesAndFolders(true);
    requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        setFilesLoadError(true);
        setIsLoadingFilesAndFolders(false);
      });
  }, []);

  React.useEffect(() => {
    const filesList = filesAndFolders ? filesAndFolders.files : [];
    const filteredTabItems = tabItems.tabList.filter(
      (tabItem) =>
        filesList.some((file) => file.id === tabItem.id) || tabItem.id === null,
    );
    const updatedTabItems = filteredTabItems.map((tabItem) => {
      if (tabItem.id === null) return tabItem;
      const file = filesList.find((file) => file.id === tabItem.id);
      if (!file) return tabItem;
      return {
        ...tabItem,
        title: file!.name,
      };
    });
    setTabItems({
      tabList: updatedTabItems,
      activeTabId: tabItems.activeTabId,
    });
    const fileTree = makeFileTree(filesAndFolders);
    selectFolderByPath({
      fileTreeNode: fileTree,
      pathStringId: selectedFolder.path_string_id,
      setSelectedFolder: setSelectedFolder,
      userName: userName,
    });
    setFileTree(fileTree);
    if (filesAndFolders) {
      setIsLoadingFilesAndFolders(false);
      setShouldBlinkFileTree(false);
    }
  }, [filesAndFolders]);

  const [internalSearchResults, setInternalSearchResults] = React.useState<
    InternalSearchResult[] | undefined
  >(undefined);
  const [isSearchLoading, setIsSearchLoading] = React.useState<boolean>(false);
  const [showSearchResults, setShowSearchResults] =
    React.useState<boolean>(false);

  const [previewSearchResult, setPreviewSearchResult] = React.useState<
    InternalSearchResult | undefined
  >(undefined);

  React.useEffect(() => {
    setIsSearchLoading(false);
    showSearchResults && setShowSearchResults(false);
    setInternalSearchResults(undefined);
  }, [selectedFolder]);

  return (
    <Flex width={"100%"} height={"100%"} direction={"column"}>
      <Flex
        id={"File Explorer Header Container"}
        mt={`${fileExplorerHeaderTopMargin}px`}
        width={"100%"}
        height={`${fileExplorerHeaderInnerHeight}px`}
        bg="gray.100"
        borderBottom="1px"
        borderColor={"gray.300"}
      >
        <FileExplorerHeader
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          filesAndFolders={filesAndFolders}
          setFilesAndFolders={setFilesAndFolders}
          setShouldBlinkFileTree={setShouldBlinkFileTree}
          fileTree={fileTree}
          filesLoadError={filesLoadError}
          setFilesLoadError={setFilesLoadError}
          setInternalSearchResults={setInternalSearchResults}
          isSearchLoading={isSearchLoading}
          setIsSearchLoading={setIsSearchLoading}
          showSearchResults={showSearchResults}
          setShowSearchResults={setShowSearchResults}
          setPreviewSearchResult={setPreviewSearchResult}
        />
      </Flex>
      <Flex
        id={"File Explorer Main Container"}
        height={`calc(100vh - ${
          navbarHeight + tabSelectorHeight + fileExplorerHeaderHeight
        }px)`}
        width={"100%"}
      >
        <Flex
          id={"File Tree Explorer Container"}
          width={`${FileTreeExplorerWidth}px`}
          minWidth={`${FileTreeExplorerWidth}px`}
          maxWidth={`${FileTreeExplorerWidth}px`}
          borderRight={`${FileTreeExplorerRightBorderWidth}px`}
          borderColor={"gray.300"}
          overflowY={"auto"}
        >
          <FileTreeExplorer
            fileTree={fileTree}
            setFilesAndFolders={setFilesAndFolders}
            fileLoadError={filesLoadError}
            shouldBlinkFileTree={shouldBlinkFileTree}
            setShouldBlinkFileTree={setShouldBlinkFileTree}
            isLoadingFilesAndFolders={isLoadingFilesAndFolders}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
          />
        </Flex>
        <Flex
          flex={1}
          id={"File Explorer Main Container"}
          position={"relative"}
        >
          {showSearchResults && (
            <Flex
              width={"100%"}
              height={"100%"}
              position={"absolute"}
              zIndex={5}
            >
              <DisplaySearchResults
                isSearchLoading={isSearchLoading}
                setShowSearchResults={setShowSearchResults}
                internalSearchResults={internalSearchResults}
                tabItems={tabItems}
                setTabItems={setTabItems}
                previewSearchResult={previewSearchResult}
                setPreviewSearchResult={setPreviewSearchResult}
              />
            </Flex>
          )}
          <FileExplorerMain
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            tabItems={tabItems}
            setTabItems={setTabItems}
            filesAndFolders={filesAndFolders}
            setFilesAndFolders={setFilesAndFolders}
            setFilesLoadError={setFilesLoadError}
            fileLoadError={filesLoadError}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FileExplorer;
