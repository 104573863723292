import React from "react";
import { Flex, Text, IconButton, Box } from "@chakra-ui/react";
import { CaseTreeNode } from "../../logics/CaseArchive";
import { SelectedCaseOrFolder } from "../../logics/CaseArchive";
import { FaChevronRight } from "react-icons/fa";
import { FaFolder, FaFolderOpen, FaRegFile } from "react-icons/fa";
import { hoverColor, selectedColor } from "./CaseTreeExplorer";

const CaseTreeNodeToAccordionItem = ({
  selectedCaseOrFolder,
  setSelectedCaseOrFolder,
  caseTreeNode,
  depth,
  basePathId,
  foldersOnlyClickable = false,
}: {
  selectedCaseOrFolder: SelectedCaseOrFolder | undefined;
  setSelectedCaseOrFolder: React.Dispatch<
    React.SetStateAction<SelectedCaseOrFolder | undefined>
  >;
  caseTreeNode: CaseTreeNode;
  depth: number;
  basePathId: string;
  foldersOnlyClickable?: Boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [rotate, setRotate] = React.useState(false);
  const currentDepth = depth + 1;
  const currentPathId = basePathId + "/" + caseTreeNode.string_id;
  const handleClick = () => {
    setRotate(!rotate);
    setIsOpen(!isOpen);
  };
  const iconStyle = {
    transform: rotate ? "rotate(90deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  };
  const [isClicked, setIsClicked] = React.useState<Boolean>(false);

  React.useEffect(() => {
    if (selectedCaseOrFolder && selectedCaseOrFolder.path === currentPathId) {
      setIsClicked(true);
    } else {
      setIsClicked(false);
    }
  }, [selectedCaseOrFolder]);

  const handleOnClickFlex = () => {
    const newSelectedCaseOrFolder: SelectedCaseOrFolder = {
      id: caseTreeNode.id,
      name: caseTreeNode.name,
      type: caseTreeNode.type,
      case_id: caseTreeNode.case_id,
      path: currentPathId,
    };
    setSelectedCaseOrFolder(newSelectedCaseOrFolder);
  };

  if (caseTreeNode.type === "folder") {
    return (
      <Flex direction={"column"}>
        <Flex
          alignItems={"center"}
          py="2px"
          pe="2px"
          borderRadius={"5px"}
          bg={isClicked ? selectedColor : ""}
        >
          <Flex
            minWidth={`${(currentDepth - 1) * 20}px`}
            maxWidth={`${(currentDepth - 1) * 20}px`}
          />
          <IconButton
            size="small"
            variant={"ghost"}
            aria-label="open"
            width={"20px"}
            onClick={handleClick}
            m="2px"
            height="15px"
            _hover={{ bg: hoverColor }}
          >
            <FaChevronRight size={"12px"} style={iconStyle} />
          </IconButton>
          <Flex
            _hover={{ bg: hoverColor }}
            borderRadius={"5px"}
            cursor={"pointer"}
            onClick={handleOnClickFlex}
            width="100%"
            px="2px"
          >
            <Flex height={"24px"} alignItems={"center"}>
              {isOpen ? <FaFolderOpen /> : <FaFolder />}
            </Flex>
            <Text
              noOfLines={3}
              overflow="hidden"
              textOverflow="ellipsis"
              display="-webkit-box"
              css={{
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
              align="left"
              ms="5px"
            >
              {caseTreeNode.name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={"column"}
          height={isOpen ? "" : "0px"}
          overflow={isOpen ? "" : "hidden"}
        >
          {caseTreeNode.children.map((child) => (
            <CaseTreeNodeToAccordionItem
              selectedCaseOrFolder={selectedCaseOrFolder}
              setSelectedCaseOrFolder={setSelectedCaseOrFolder}
              caseTreeNode={child}
              depth={depth + 1}
              basePathId={currentPathId}
              key={child.string_id}
              foldersOnlyClickable={foldersOnlyClickable}
            />
          ))}
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex
        ps="22px"
        py="2px"
        pe="2px"
        borderRadius={"5px"}
        bg={isClicked ? selectedColor : ""}
      >
        <Flex
          minWidth={`${(currentDepth - 1) * 20}px`}
          maxWidth={`${(currentDepth - 1) * 20}px`}
        />
        <Flex
          px="2px"
          _hover={{ bg: hoverColor }}
          borderRadius={"5px"}
          cursor={foldersOnlyClickable ? "default" : "pointer"}
          onClick={foldersOnlyClickable ? undefined : handleOnClickFlex}
          width="100%"
          position={"relative"}
        >
          <Flex height={"24px"} alignItems={"center"}>
            <FaRegFile />
          </Flex>
          <Text
            noOfLines={5}
            overflow="hidden"
            textOverflow="ellipsis"
            display="-webkit-box"
            css={{
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 5,
            }}
            align="left"
            ms="5px"
          >
            {caseTreeNode.name}
          </Text>
        </Flex>
      </Flex>
    );
  }
};

export default CaseTreeNodeToAccordionItem;
