import React from "react";
import {
  Flex,
  Menu,
  Icon,
  Text,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Tooltip,
  Skeleton,
} from "@chakra-ui/react";
import { useContext } from "react";
import { keyframes } from "@emotion/react";
import { PiFolderThin } from "react-icons/pi";
import { PiFilePdfThin } from "react-icons/pi";
import { BsThreeDots } from "react-icons/bs";
import { LuTrash2, LuFolderEdit, LuFileEdit } from "react-icons/lu";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  FileTreeNode,
  SelectedFolder,
  TabItems,
  requestFetchFile,
  FilesAndFolders,
  ModalType,
  openPdfInTab,
} from "../../logics/FileManager";

import ModalInFileItem from "./ModalInFileItem";

const MenuInFileItem = ({
  fileTreeNode,
  setModalType,
  onOpenModal,
  isUploadingOrPreprocessing,
}: {
  fileTreeNode: FileTreeNode;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
  onOpenModal: () => void;
  isUploadingOrPreprocessing: boolean;
}) => {
  return (
    <Menu>
      <MenuButton
        onClick={(e) => {
          e.stopPropagation();
        }}
        as={IconButton}
        size="sm"
        position={"absolute"}
        variant={"outline"}
        bg={"white"}
        right={"5px"}
        _hover={{ bg: "gray.300" }}
        top={"50%"}
        transform={"translateY(-50%)"}
        aria-label="open"
        width={"fit-content"}
        height="20px"
        rounded={"full"}
        opacity={0}
        justifyContent={"center"}
        alignItems={"center"}
        className="icon-button"
        icon={<Icon as={BsThreeDots} />}
      />

      <MenuList minWidth={"100px"} width={"120px"}>
        <MenuItem
          isDisabled={isUploadingOrPreprocessing}
          onClick={(e) => {
            e.stopPropagation();
            fileTreeNode.type === "folder"
              ? setModalType("renameFolder")
              : setModalType("renameFile");
            onOpenModal();
          }}
          icon={
            <Icon
              as={fileTreeNode.type === "folder" ? LuFolderEdit : LuFileEdit}
              boxSize={4}
            />
          }
        >
          {"이름변경"}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            fileTreeNode.type === "folder"
              ? setModalType("deleteFolder")
              : setModalType("deleteFile");
            onOpenModal();
          }}
          icon={<Icon as={LuTrash2} boxSize={4} />}
        >
          {"삭제"}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const FileItem = ({
  fileTreeNode,
  selectedFolder,
  setSelectedFolder,
  tabItems,
  setTabItems,
  filesAndFolders,
  setFilesAndFolders,
  setFilesLoadError,
}: {
  fileTreeNode: FileTreeNode;
  selectedFolder: SelectedFolder;
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder>>;
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  filesAndFolders: FilesAndFolders | undefined;
  setFilesAndFolders: React.Dispatch<
    React.SetStateAction<FilesAndFolders | undefined>
  >;
  setFilesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const blinkAnimation = keyframes`
  0% { opacity: 0.3; }
  100% { opacity: 1; }
`;
  const id = fileTreeNode.id;
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [showUploadProgress, setShowUploadProgress] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);

  const [preprocessingProgress, setPreprocessingProgress] = React.useState(0);
  const [startValuePreprocssingProgress, setStartValuePreprocessingProgress] =
    React.useState(0);

  const [isPreprocessing, setIsPreprocessing] = React.useState(false);

  const [showPreprocessingProgress, setShowPreprocessingProgress] =
    React.useState(false);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [modalType, setModalType] = React.useState<ModalType>(undefined);

  React.useEffect(() => {
    if (preprocessingProgress === 0 && showPreprocessingProgress) {
      const startPreprocessedProgress = setTimeout(() => {
        setPreprocessingProgress(startValuePreprocssingProgress);
        clearTimeout(startPreprocessedProgress);
      }, 300);
    }
  }, [showPreprocessingProgress]);

  const timeForCompleteUploadProgress = 1000;
  const timeFoeCopletePreprocessingProgress = 3000;

  React.useEffect(() => {
    if (isPreprocessing === false && preprocessingProgress === 100) {
      const hidePreprocessedProgress = setTimeout(() => {
        setShowPreprocessingProgress(false);
        clearTimeout(hidePreprocessedProgress);
      }, timeFoeCopletePreprocessingProgress);
    }
  }, [isPreprocessing]);

  React.useEffect(() => {
    if (isUploading === false && uploadProgress === 100) {
      const completeUploadProgress = setTimeout(() => {
        setShowUploadProgress(false);
        setShowPreprocessingProgress(true);
        setIsPreprocessing(true);
        clearTimeout(completeUploadProgress);
      }, timeForCompleteUploadProgress + 1000);
    }
  }, [isUploading]);

  React.useEffect(() => {
    if (
      fileTreeNode.status === "failed_upload" ||
      fileTreeNode.status === "failed_preprocess"
    ) {
      setIsLoading(false);
      setShowPreprocessingProgress(false);
      setShowUploadProgress(false);
    }
  }, [fileTreeNode.status]);

  React.useEffect(() => {
    setIsLoading(true);
    if (fileTreeNode.type === "folder") {
      setIsLoading(false);
      return;
    }
    if (fileTreeNode.status && fileTreeNode.status === "submitted") {
      if (fileTreeNode.id === null) {
        setIsLoading(false);
        return;
      }
      requestFetchFile(authContext, fileTreeNode.id)
        .then((response) => {
          if (response === null) {
            setIsLoading(false);
            return;
          }
          const currentUploadedFilesNum = response.num_uploaded;
          const currentPreprocessedFilesNum = response.num_preprocessed;
          const numPartitions = response.num_partitions;
          if (currentUploadedFilesNum === 0) {
            setShowUploadProgress(true);
            setIsUploading(true);
            setIsLoading(false);
            return;
          }
          if (currentUploadedFilesNum < numPartitions) {
            setShowUploadProgress(true);
            setIsUploading(true);
            setUploadProgress(
              Math.floor((currentUploadedFilesNum / numPartitions) * 100)
            );
            setIsLoading(false);
            return;
          }
          if (currentUploadedFilesNum === numPartitions) {
            if (currentPreprocessedFilesNum > 0) {
              setPreprocessingProgress(
                Math.floor((currentPreprocessedFilesNum / numPartitions) * 100)
              );
            }
            if (currentPreprocessedFilesNum === 0) {
              setPreprocessingProgress(Math.floor((0.5 / numPartitions) * 100));
            }
            setShowPreprocessingProgress(true);
            setIsPreprocessing(true);
            setIsLoading(false);
            return;
          }
        })
        .catch((error) => {
          //TODO : error handling
          console.error(error);
          setIsLoading(false);
        });
      const intervalId = setInterval(() => {
        if (fileTreeNode.id === null) {
          return;
        }
        requestFetchFile(authContext, fileTreeNode.id)
          .then((response) => {
            if (response === null) {
              setIsLoading(false);
              return;
            }
            if (response.status === "submitted") {
              if (response.num_num_partitions === null) {
                return;
              }
              const numPartitions = response.num_partitions;
              if (preprocessingProgress === 0) {
                setStartValuePreprocessingProgress(
                  Math.floor((0.5 / numPartitions) * 100)
                );
              }
              const currentUploadedFilesNum = response.num_uploaded;

              setUploadProgress(
                Math.floor((currentUploadedFilesNum / numPartitions) * 100)
              );

              const currentPreprocessedFilesNum = response.num_preprocessed;
              if (currentPreprocessedFilesNum > 0) {
                setPreprocessingProgress(
                  Math.floor(
                    (currentPreprocessedFilesNum / numPartitions) * 100
                  )
                );
              }
              if (currentUploadedFilesNum === numPartitions) {
                setIsUploading(false);
              }
            }

            if (
              response.status === "fine" ||
              response.status === "failed_preprocess" ||
              response.status === "failed_upload"
            ) {
              setPreprocessingProgress(100);
              setShowUploadProgress(false);
              setIsUploading(false);
              setIsLoading(false);
              const completePreprocessedProgress = setTimeout(() => {
                setIsPreprocessing(false);
                clearTimeout(completePreprocessedProgress);
              }, 1000);

              setFilesAndFolders((prev) => ({
                folders: prev!.folders,
                files: prev!.files.map((file) => {
                  if (file.id === fileTreeNode.id) {
                    return {
                      ...file,
                      status: response.status,
                    };
                  } else {
                    return file;
                  }
                }),
              }));
              clearInterval(intervalId);
            }
          })
          .catch((error) => {
            //TODO : error handling
            console.error(error);
            clearInterval(intervalId);
          });
      }, 5000);
      return () => clearInterval(intervalId);
    }
    if (
      (fileTreeNode.status && fileTreeNode.status === "fine") ||
      (fileTreeNode.status && fileTreeNode.status === "failed_preprocess") ||
      (fileTreeNode.status && fileTreeNode.status === "failed_upload")
    ) {
      if (fileTreeNode.id === null) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      return;
    }
  }, [id]);

  const handleOnClickItem = () => {
    if (fileTreeNode.type === "folder") {
      setSelectedFolder({
        name: fileTreeNode.name,
        id: fileTreeNode.id,
        path: selectedFolder.path + "/" + fileTreeNode.name,
        path_string_id:
          selectedFolder.path_string_id + "/" + fileTreeNode.string_id,
        children: fileTreeNode.children,
      });
    } else {
      openPdfInTab({
        tabItems: tabItems,
        setTabItems: setTabItems,
        id: fileTreeNode.id,
        name: fileTreeNode.name,
      });
    }
  };
  return (
    <>
      <Tooltip
        bg="gray.500"
        label={
          showUploadProgress
            ? "업로드가 완료되기 전까지 파일을 열 수 없습니다."
            : fileTreeNode.status === "failed_upload"
            ? "파일 업로드에 실패했습니다. 다시 업로드해 주세요."
            : fileTreeNode.status === "failed_preprocess"
            ? "파일 전처리에 실패했습니다. 다시 업로드해 주세요."
            : ""
        }
        placement="top"
      >
        <Flex
          height={"78px"}
          shadow={"md"}
          _hover={{ bg: "blue.50", ".icon-button": { opacity: 1 } }}
          cursor={
            showUploadProgress || isLoading || fileTreeNode.status === "waiting"
              ? "not-allowed"
              : "pointer"
          }
          position={"relative"}
          bg="white"
          border={"1px"}
          borderColor={"gray.300"}
          borderRadius={"10px"}
          width={"300px"}
          m="10px"
          py="5px"
          px="10px"
          alignItems={"center"}
          onClick={(e) => {
            if (
              !showUploadProgress &&
              !isLoading &&
              fileTreeNode.status !== "failed_upload" &&
              fileTreeNode.status !== "waiting"
            ) {
              e.stopPropagation();
              handleOnClickItem();
            }
          }}
        >
          {isLoading ? (
            <>
              <Flex
                minWidth={"fit-content"}
                maxWidth={"fit-content"}
                height={"fit-content"}
                alignItems={"center"}
                me="5px"
              >
                <Icon as={PiFilePdfThin} boxSize={12} />
              </Flex>
              <Flex
                flex={1}
                py="5px"
                me="10px"
                height={"100%"}
                direction={"column"}
              >
                <Flex
                  height={"32px"}
                  width={"215px"}
                  minWidth={"215px"}
                  maxWidth={"215px"}
                  overflow={"hidden"}
                  alignItems={"center"}
                >
                  <Text
                    userSelect={"none"}
                    noOfLines={1}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="-webkit-box"
                    css={{
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                    align="left"
                  >
                    {fileTreeNode.name}
                  </Text>
                </Flex>
                <Skeleton height="15px" mt="6px" width="100%" />
              </Flex>
              <MenuInFileItem
                fileTreeNode={fileTreeNode}
                setModalType={setModalType}
                onOpenModal={onOpenModal}
                isUploadingOrPreprocessing={
                  showUploadProgress || showPreprocessingProgress
                }
              />
            </>
          ) : (
            <>
              <Flex
                minWidth={"fit-content"}
                maxWidth={"fit-content"}
                id={"Folder Icon"}
                height={"fit-content"}
                alignItems={"center"}
                me="5px"
              >
                {fileTreeNode.type === "folder" ? (
                  <Icon as={PiFolderThin} boxSize={12} />
                ) : (
                  <Icon as={PiFilePdfThin} boxSize={12} />
                )}
              </Flex>
              <Flex
                flex={1}
                py="5px"
                me="10px"
                height={"100%"}
                direction={"column"}
              >
                <Flex
                  height={
                    showUploadProgress ||
                    showPreprocessingProgress ||
                    isLoading ||
                    fileTreeNode.status === "failed_preprocess" ||
                    fileTreeNode.status === "failed_upload"
                      ? "32px"
                      : "100%"
                  }
                  width={"215px"}
                  minWidth={"215px"}
                  maxWidth={"215px"}
                  overflow={"hidden"}
                  alignItems={"center"}
                  transition="height 1s"
                >
                  <Text
                    userSelect={"none"}
                    noOfLines={
                      showUploadProgress ||
                      showPreprocessingProgress ||
                      isLoading ||
                      fileTreeNode.status === "failed_preprocess" ||
                      fileTreeNode.status === "failed_upload"
                        ? 1
                        : 2
                    }
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="-webkit-box"
                    css={{
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp:
                        showUploadProgress ||
                        showPreprocessingProgress ||
                        isLoading ||
                        fileTreeNode.status === "failed_preprocess" ||
                        fileTreeNode.status === "failed_upload"
                          ? 1
                          : 2,
                      animation: `${
                        fileTreeNode.type === "folder" &&
                        fileTreeNode.status === "waiting"
                          ? `${blinkAnimation} 0.8s infinite`
                          : "none"
                      }`,
                    }}
                    align="left"
                  >
                    {fileTreeNode.name}
                  </Text>
                </Flex>
                {(fileTreeNode.status === "failed_preprocess" ||
                  fileTreeNode.status === "failed_upload") && (
                  <Text fontSize={"sm"} fontWeight={"bold"} color={"red.400"}>
                    {fileTreeNode.status === "failed_upload"
                      ? "업로드 실패"
                      : "전처리 실패"}
                  </Text>
                )}

                {showUploadProgress && (
                  <Flex direction={"column"}>
                    <Text
                      fontSize={"xs"}
                      color={"gray.500"}
                      css={{
                        animation: `${blinkAnimation} 0.8s infinite`,
                      }}
                    >
                      {"업로드 중입니다."}
                    </Text>

                    <Flex
                      mt="2px"
                      position={"relative"}
                      borderRadius={"full"}
                      bg="gray.300"
                      width={"100%"}
                      height={"3px"}
                    >
                      <Flex
                        position={"absolute"}
                        borderRadius={"full"}
                        zIndex={1}
                        bg="blue.500"
                        width={`${uploadProgress}%`}
                        height={"3px"}
                        transition={`width ${timeForCompleteUploadProgress}ms`}
                      />
                    </Flex>
                  </Flex>
                )}

                {showPreprocessingProgress && (
                  <Flex direction={"column"}>
                    <Text
                      fontSize={"xs"}
                      color={"gray.500"}
                      css={{
                        animation: `${
                          !isPreprocessing
                            ? "none"
                            : `${blinkAnimation} 0.8s infinite`
                        }`,
                      }}
                    >
                      {!isPreprocessing
                        ? "전처리 완료되었습니다."
                        : "검색 기능을 위한 전처리 중입니다."}
                    </Text>

                    <Flex
                      mt="2px"
                      position={"relative"}
                      borderRadius={"full"}
                      bg="gray.300"
                      width={"100%"}
                      height={"3px"}
                    >
                      <Flex
                        position={"absolute"}
                        borderRadius={"full"}
                        zIndex={1}
                        bg="green.500"
                        width={`${preprocessingProgress}%`}
                        height={"3px"}
                        transition={
                          preprocessingProgress === 100
                            ? " width 1s"
                            : `width ${timeFoeCopletePreprocessingProgress}ms`
                        }
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <MenuInFileItem
                fileTreeNode={fileTreeNode}
                setModalType={setModalType}
                onOpenModal={onOpenModal}
                isUploadingOrPreprocessing={
                  showUploadProgress || showPreprocessingProgress
                }
              />
            </>
          )}
        </Flex>
      </Tooltip>
      {filesAndFolders && (
        <ModalInFileItem
          isOpenModal={isOpenModal}
          modalType={modalType}
          setModalType={setModalType}
          onCloseModal={onCloseModal}
          filesAndFolders={filesAndFolders}
          setFilesAndFolders={setFilesAndFolders}
          setFilesLoadError={setFilesLoadError}
          fileTreeNode={fileTreeNode}
        />
      )}
    </>
  );
};

export default FileItem;
