import React from "react";
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Spinner,
  Input,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { toastErrorMessage } from "../../utils/UIUtils";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  FilesAndFolders,
  FileTreeNode,
  requestCreateFolderInFileManager,
  requestFilesAndFolders,
  requestRenameFolderInFileManager,
  getParentFolderPath,
  selectFolderByPath,
  findNodeByPath,
  deleteFolder,
  ModalType,
  SelectedFolder,
  getIsAlreadyUsedNameInSelectedFolder,
  getIsAlreadyUsedNameInParentFolder,
  findFileIdsInFileTreeNode,
} from "../../logics/FileManager";

const ModalInFileManagerHeader = ({
  isOpenModal,
  modalType,
  setModalType,
  onCloseModal,
  setShouldBlinkFileTree,
  filesAndFolders,
  setFilesAndFolders,
  setFilesLoadError,
  fileTree,
  selectedFolder,
  setSelectedFolder,
}: {
  isOpenModal: boolean;
  modalType: ModalType;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
  onCloseModal: () => void;
  setShouldBlinkFileTree: React.Dispatch<React.SetStateAction<boolean>>;
  filesAndFolders: FilesAndFolders | undefined;
  setFilesAndFolders: React.Dispatch<
    React.SetStateAction<FilesAndFolders | undefined>
  >;
  setFilesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
  fileTree: FileTreeNode | undefined;
  selectedFolder: SelectedFolder;
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder>>;
}) => {
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const userName = authContext.user?.username;
  const toast = useToast();
  const initialRef = React.useRef<HTMLInputElement>(null);
  const [newFolderName, setNewFolderName] = React.useState<string>("");
  const [folderNameToChange, setFolderNameToChange] = React.useState<string>(
    selectedFolder.name,
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const isAlreadyUsedNameInParentFolder = getIsAlreadyUsedNameInParentFolder({
    id: selectedFolder.id,
    filesAndFolders: filesAndFolders,
    nameToChange: folderNameToChange,
  });
  const isAlreadyUsedNameInSelectedFolder =
    getIsAlreadyUsedNameInSelectedFolder({
      selectedFolder: selectedFolder,
      filesAndFolders: filesAndFolders,
      newFolderName: newFolderName,
    });

  const isSameNameToChange = selectedFolder.name === folderNameToChange;
  const closeModal = () => {
    onCloseModal();
    setModalType(undefined);
    setNewFolderName("");
    setFolderNameToChange(selectedFolder.name);
  };
  React.useEffect(() => {
    setNewFolderName("");
    setFolderNameToChange(selectedFolder.name);
  }, [selectedFolder]);

  const handleOnChangeNewName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(e.target.value);
  };

  const handleOnChangeRename = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderNameToChange(e.target.value);
  };

  const onClickCreateNewFolder = async () => {
    setShouldBlinkFileTree(true);
    setIsLoading(true);
    const selected_folder_id = selectedFolder.id;
    try {
      await requestCreateFolderInFileManager(
        authContext,
        selected_folder_id,
        newFolderName,
      );
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "새폴더 생성에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
      setShouldBlinkFileTree(false);
      return;
    }
    await requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "파일을 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setShouldBlinkFileTree(false);
        setFilesLoadError(true);
      });
    closeModal();
    setIsLoading(false);
  };

  const onClickRenameFolder = async () => {
    closeModal();
    const id = selectedFolder.id;
    if (!id) {
      return;
    }
    try {
      !isSameNameToChange &&
        setFilesAndFolders((prev) => {
          if (!prev) {
            return { files: [], folders: [] };
          }
          return {
            files: prev.files,
            folders: prev.folders.map((folder) => {
              const newFolder =
                folder.id === id
                  ? { ...folder, name: folderNameToChange }
                  : folder;
              return newFolder;
            }),
          };
        });
      !isSameNameToChange &&
        (await requestRenameFolderInFileManager(
          authContext,
          id,
          folderNameToChange,
        ));
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "폴더 이름 변경에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
    }
    await requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "파일을 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setFilesLoadError(true);
      });
  };

  const onClickDeleteFolder = async () => {
    closeModal();

    const parent_folder_path_string_id = getParentFolderPath(
      selectedFolder.path_string_id,
    );
    selectFolderByPath({
      fileTreeNode: fileTree,
      pathStringId: parent_folder_path_string_id,
      setSelectedFolder: setSelectedFolder,
      userName: userName,
    });
    const fileTreeNodeToDelete = findNodeByPath({
      fileTreeNode: fileTree,
      pathStringId: selectedFolder.path_string_id,
    });
    const deleteFileIds = fileTreeNodeToDelete
      ? findFileIdsInFileTreeNode(fileTreeNodeToDelete)
      : [];

    setFilesAndFolders((prev) => {
      if (!prev) {
        return { files: [], folders: [] };
      }
      return {
        files: prev?.files.filter((file) => !deleteFileIds.includes(file.id)),
        folders: prev?.folders.filter(
          (folder) => folder.id !== selectedFolder.id,
        ),
      };
    });

    if (!fileTreeNodeToDelete) {
      return;
    }

    await deleteFolder({
      node: fileTreeNodeToDelete,
      toast: toast,
      authContext: authContext,
    });

    await requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "파일을 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setFilesLoadError(true);
      });
    console.log("deleted folder in Server", selectedFolder.name);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpenModal}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody pb={1}>
          {isLoading ? (
            <Flex
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
            >
              <Text mt="20px" fontWeight={"bold"} whiteSpace={"pre"}>
                {modalType === "newFolder"
                  ? "생성 중 입니다."
                  : modalType === "renameFolder"
                    ? "변경 중 입니다."
                    : ""}
              </Text>
              <Spinner mt="10px" color="gray.500" size="md" />
            </Flex>
          ) : modalType === "deleteFolder" ? (
            <Flex width={"100%"} justifyContent={"center"}>
              <Text mt="20px" fontWeight={"bold"} whiteSpace={"pre"}>
                {
                  "해당 폴더를 삭제하시겠습니까?\n하위 폴더와 파일도 모두 삭제됩니다."
                }
              </Text>
            </Flex>
          ) : modalType === "newFolder" || modalType === "renameFolder" ? (
            <FormControl>
              <FormLabel my="20px">
                {modalType === "newFolder"
                  ? "새폴더 이름을 입력해주세요"
                  : "변경할 폴더의 이름을 입력해주세요"}
              </FormLabel>

              <Input
                ref={initialRef}
                value={
                  modalType === "newFolder" ? newFolderName : folderNameToChange
                }
                onChange={
                  modalType === "newFolder"
                    ? handleOnChangeNewName
                    : handleOnChangeRename
                }
              />
            </FormControl>
          ) : (
            <></>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size={"sm"} mr={3} onClick={closeModal}>
            {isLoading ? "닫기" : "취소"}
          </Button>
          <Tooltip
            bg="gray.500"
            placement="top"
            label={
              (modalType === "renameFolder" || modalType === "newFolder") &&
              (isAlreadyUsedNameInParentFolder ||
                isAlreadyUsedNameInSelectedFolder)
                ? "이미 사용된 이름입니다."
                : undefined
            }
          >
            <Button
              size={"sm"}
              bg={"blue.600"}
              isDisabled={
                isLoading
                  ? true
                  : modalType === "newFolder"
                    ? isAlreadyUsedNameInSelectedFolder || newFolderName === ""
                    : modalType === "renameFolder"
                      ? isAlreadyUsedNameInParentFolder ||
                        folderNameToChange === ""
                      : false
              }
              color={"white"}
              _hover={{ bg: "blue.700" }}
              onClick={
                modalType === "newFolder"
                  ? onClickCreateNewFolder
                  : modalType === "deleteFolder"
                    ? onClickDeleteFolder
                    : modalType === "renameFolder"
                      ? onClickRenameFolder
                      : undefined
              }
            >
              {modalType === "newFolder"
                ? "만들기"
                : modalType === "renameFolder"
                  ? "변경"
                  : "삭제"}
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalInFileManagerHeader;
