import { AuthContextType } from "../context/AuthContext";
import { requestServer, requestFormData } from "./Utils";
import { toastErrorMessage } from "../utils/UIUtils";
import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { splitPDF, readFileAsArrayBuffer } from "../libs/pdf/PdfManager";
import { set } from "date-fns";

export interface SelectedFile {
  name: string;
  type: "file" | "folder";
  status: "Loading" | "Error" | "Success" | undefined;
  status_server?: string;
  id: number;
  path: string;
  path_string_id: string | null;
  url?: string;
}

export interface SelectedFolder {
  name: string;
  id: number | null;
  path: string;
  path_string_id: string;
  children: FileTreeNode[] | undefined;
}

export interface Folder {
  id: number;
  parent_folder_id: number | undefined | null;
  status?: string;
  name: string;
}

export interface File {
  id: number;
  parent_folder_id: number;
  name: string;
  status: string;
  error_msg?: string;
}

export interface FilesAndFolders {
  folders: Folder[];
  files: File[];
}

export type ModalType =
  | "newFolder"
  | "renameFolder"
  | "renameFile"
  | "deleteFolder"
  | "deleteFile"
  | undefined;

export interface TabItem {
  title: string;
  id: number | null;
  status: "Loading" | "Error" | "Success" | undefined;
  url?: string;
  page?: number;
}

export interface TabItems {
  tabList: TabItem[];
  activeTabId: number | null; // null : 파일탐색기
}

export interface FileTreeNode {
  id: number | null;
  string_id: string | null;
  name: string;
  type: "folder" | "file";
  status?: string;
  children: FileTreeNode[];
}

export const requestFilesAndFolders = async (
  authContext: AuthContextType
): Promise<FilesAndFolders> => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/list`,
    null,
    "GET"
  );
  if (response === undefined) return { folders: [], files: [] };
  return response;
};

export const requestCreateFolderInFileManager = async (
  authContext: AuthContextType,
  parent_folder_id: number | null,
  name: string
): Promise<Folder> => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/create_folder`,
    { parent_folder_id: parent_folder_id, name: name },
    "POST"
  );
  const id = response.id;
  return { id: id, parent_folder_id: parent_folder_id, name: name };
};

export const requestRenameFolderInFileManager = async (
  authContext: AuthContextType,
  id: number,
  new_name: string
): Promise<string> => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/rename_folder`,
    { id: id, new_name: new_name },
    "POST"
  );
  return response;
};

export const requestRenameFileInFileManager = async (
  authContext: AuthContextType,
  id: number,
  new_name: string
): Promise<string> => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/rename_file`,
    { id: id, new_name: new_name },
    "POST"
  );
  return response;
};

export const requestFetchFile = async (
  authContext: AuthContextType,
  fileId: number
) => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/fetch?file_id=${fileId}`,
    null,
    "GET"
  );
  return response;
};

export const requestUpdateFilePartitionInfo = async (
  authContext: AuthContextType,
  file_id: number,
  numPages: number,
  partitionSize: number,
  numPartitions: number
) => {
  const response = await requestServer(
    authContext,
    "/api/file_manager/update_file_partition_info",
    {
      file_id: file_id,
      num_pages: numPages,
      partition_size: partitionSize,
      num_partitions: numPartitions,
    }
  );
  return response;
};

export const requestFileUploadKey = async (
  authContext: AuthContextType,
  fileId: number,
  partitionId: number
) => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/get_upload_key`,
    {
      file_id: fileId,
      partition_id: partitionId,
    },
    "POST"
  );
  return response;
};

export const processUploadFile = async (
  authContext,
  file,
  file_id,
  setFilesAndFolders
) => {
  const arrayBuffer = await readFileAsArrayBuffer(file);
  const partitionSize: number = 5; // Must be smaller than 10 because of Clova OCR API
  const [numPages, files] = await splitPDF(file, arrayBuffer, partitionSize);

  if (files.length == 0) {
    // TODO: handling error (this is not a pdf file)
  } else {
    return requestUpdateFilePartitionInfo(
      authContext,
      file_id,
      numPages,
      partitionSize,
      files.length
    )
      .then(async (response) => {
        const { status } = response;
        if (status !== "success") {
          console.log("error in requestUpdateFilePartitionInfo");
          return;
        }

        for (let partitionId = 0; partitionId < files.length; ++partitionId) {
          try {
            const { res } = await requestFileUploadKey(
              authContext,
              file_id,
              partitionId
            );

            if (res === "file not found") {
              console.log("file not found");
              return;
            }
            const formData = new FormData();
            for (const key in res.fields) {
              formData.append(key, res.fields[key]);
            }
            formData.append("file", files[partitionId]);
            const response = await fetch(res.url, {
              method: "POST",
              body: formData,
            });

            if (response.status == 204) {
              const res = await requestSetUploadProgress(
                authContext,
                file_id,
                partitionId
              );
            } else {
              return await requestUploadErrorMarkFile(authContext, file_id);
            }
          } catch (e) {
            console.log(e);
            requestUploadErrorMarkFile(authContext, file_id);
            setFilesAndFolders((prev) => {
              const newFilesAndFolders = {
                files: prev.files.map((file) => {
                  if (file.id === file_id) {
                    file.status = "failed_upload";
                  }
                  return file;
                }),
                folders: prev.folders,
              };
              return newFilesAndFolders;
            });
            return;
          }
        }
        //TODO: Add a logic to continue the upload which it fails before
        //TODO: Trigger preprocessing
        console.log("trigger preprocessing");
        await requestTriggerPreProcessFile(authContext, file_id);
      })
      .catch((e) => {
        console.log(e);
        requestUploadErrorMarkFile(authContext, file_id);
        setFilesAndFolders((prev) => {
          const newFilesAndFolders = {
            files: prev.files.map((file) => {
              if (file.id === file_id) {
                file.status = "failed_upload";
              }
              return file;
            }),
            folders: prev.folders,
          };
          return newFilesAndFolders;
        });
        return;
      });
  }
};

export const processUploadFiles = async (
  authContext,
  files,
  basePath,
  parentFolderId,
  setFilesAndFolders,
  setShouldBlinkFileTree,
  toast
) => {
  const filesGroupByFolder = files.reduce((acc, file) => {
    const parts = file.path.split("/");
    const folder = parts.length > 1 ? parts[1] : "";
    if (acc[folder] === undefined) {
      acc[folder] = [];
    }
    acc[folder].push(file);
    return acc;
  }, {});

  const foldersToUpload = Object.keys(filesGroupByFolder)
    .filter((folder) => folder !== "")
    .map((folder, index) => {
      const id = Number(Date.now()) * 1000 + index;
      return {
        id: id,
        parent_folder_id: parentFolderId,
        name: folder,
        status: "waiting",
      };
    });

  const filesToUpload = filesGroupByFolder[""]
    ? filesGroupByFolder[""].map((file, index) => {
        const id = Number(Date.now()) * 1000 + index;
        return {
          id: id,
          parent_folder_id: parentFolderId,
          name: file.name,
          status: "waiting",
        };
      })
    : [];

  setFilesAndFolders((prev) => {
    const newFilesAndFolders = {
      files: [...prev.files, ...filesToUpload],
      folders: [...prev.folders, ...foldersToUpload],
    };
    return newFilesAndFolders;
  });

  await Promise.all(
    files.map(async (file) => {
      try {
        const { id } = await requestCreateFileInDB(authContext, file, basePath);
        file.id = id;
      } catch (error) {
        console.log("An error occurred:", error);
        toast({
          title: "파일 업로드 실패",
          description: `파일 "${file.name}"을 업로드하는 중에 오류가 발생했습니다. 다시 시도해 주세요.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })
  );

  requestFilesAndFolders(authContext)
    .then((filesAndFolders) => {
      setFilesAndFolders((prev) => {
        const waitingFile = prev.files
          .filter((file) => file.status === "waiting")
          .filter((file) => !filesToUpload.some((f) => f.id === file.id));
        const newFiles = [...waitingFile, ...filesAndFolders.files];
        const waitingFolder = prev.folders
          .filter((folder) => folder.status === "waiting")
          .filter((folder) => !foldersToUpload.some((f) => f.id === folder.id));
        const newFolders = [...waitingFolder, ...filesAndFolders.folders];

        const newFilesAndFolders = {
          files: newFiles,
          folders: newFolders,
        };
        return newFilesAndFolders;
      });

      setShouldBlinkFileTree(false);
      const promises = files.map((file) => {
        if (file.id === undefined) return;
        return processUploadFile(
          authContext,
          file,
          file.id,
          setFilesAndFolders
        );
      });
      Promise.all(promises);
    })
    .catch((e) => {
      //TODO : error handling
      console.log("error in requestFilesAndFolders");
      console.log(e);
    });
};

export const requestFileDownloadUrl = async (
  authContext: AuthContextType,
  fileId: number
) => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/get_download_url`,
    {
      file_id: fileId,
    },
    "POST"
  );
  return response;
};

export const requestFilePartitionDownloadUrl = async (
  authContext: AuthContextType,
  fileId: number,
  partitionId: number
) => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/get_download_url`,
    {
      file_id: fileId,
      partition_id: partitionId,
    },
    "POST"
  );
  return response;
};

export const requestCreateFileInDB = async (
  authContext: AuthContextType,
  file,
  basePath = "" // e.g., "/folder1/folder2/...",
) => {
  console.log("asdf");
  const response = await requestServer(
    authContext,
    "/api/file_manager/create_file_in_DB",
    {
      file_path: basePath + "/" + file.path,
    }
  );
  return response;
};

export const requestTriggerPreProcessFile = async (
  authContext: AuthContextType,
  fileId: number
) => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/trigger_preprocess`,
    {
      file_id: fileId,
    }
  );
  return response;
};

export const requestUploadErrorMarkFile = async (
  authContext: AuthContextType,
  fileId: number
) => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/upload_error_mark`,
    {
      file_id: fileId,
    }
  );
  return response;
};

export const requestSetUploadProgress = async (
  authContext: AuthContextType,
  fileId: number,
  partitionId: number
) => {
  const response = await requestServer(
    authContext,
    `/api/file_manager/set_upload_progress`,
    {
      file_id: fileId,
      partition_id: partitionId,
    }
  );
  return response;
};

export const requestRemoveFile = async (
  authContet: AuthContextType,
  fileId: number
) => {
  const response = await requestServer(
    authContet,
    `/api/file_manager/remove_file`,
    {
      file_id: fileId,
    }
  );
};

export const requestRemoveFolder = async (
  authContet: AuthContextType,
  folderId: number
) => {
  const response = await requestServer(
    authContet,
    `/api/file_manager/remove_folder`,
    {
      folder_id: folderId,
    }
  );
};

export const makeFileTree = (filesAndFolders: FilesAndFolders | undefined) => {
  const root: FileTreeNode = {
    id: null,
    string_id: null,
    name: "",
    type: "folder",
    children: [],
  };
  if (!filesAndFolders) return root;
  // time complexity: n^2
  const addChildren = (node: FileTreeNode) => {
    if (node.type === "file") return;
    node.children = filesAndFolders!.folders
      .filter((folder) => folder.parent_folder_id === node.id)
      .map((folder) =>
        folder.status && folder.status === "waiting"
          ? {
              id: folder.id,
              string_id: "folder" + String(folder.id),
              name: folder.name,
              type: "folder",
              status: "waiting",
              children: [],
            }
          : {
              id: folder.id,
              string_id: "folder" + String(folder.id),
              name: folder.name,
              type: "folder",
              children: [],
            }
      );
    node.children = node.children.concat(
      filesAndFolders!.files
        .filter((file) => file.parent_folder_id === node.id)
        .map((file) => ({
          id: file.id,
          string_id: "file" + String(file.id),
          name: file.name,
          type: "file",
          status: file.status,
          children: [],
        }))
    );
    node.children.forEach(addChildren);
  };
  addChildren(root);
  return root;
};

export const findNodeByPath = ({
  fileTreeNode,
  pathStringId,
}: {
  fileTreeNode: FileTreeNode | undefined;
  pathStringId: string | null;
}): FileTreeNode | undefined => {
  if (fileTreeNode === undefined) return undefined;
  if (pathStringId === "") return fileTreeNode;
  if (pathStringId === null) return undefined;
  const pathList = pathStringId
    .split("/")
    .filter((id) => id)
    .map((id) => String(id));
  const findNode = (
    node: FileTreeNode,
    pathList: String[]
  ): FileTreeNode | undefined => {
    if (pathList.length === 0) return node;
    const nextNode = node.children.find(
      (child) => child.string_id === pathList[0]
    );
    if (nextNode === undefined) return undefined;
    return findNode(nextNode, pathList.slice(1));
  };
  return findNode(fileTreeNode, pathList);
};

export const findPathByPathStringId = ({
  fileTreeNode,
  pathStringId,
}: {
  fileTreeNode: FileTreeNode | undefined;
  pathStringId: string | null;
}): string => {
  if (fileTreeNode === undefined) return "";
  if (pathStringId === "") return "";
  if (pathStringId === null) return "";
  const pathList = pathStringId
    .split("/")
    .filter((id) => id)
    .map((id) => String(id));
  const findPath = (
    node: FileTreeNode,
    pathList: String[],
    path: string
  ): string => {
    if (pathList.length === 0) return path;
    const nextNode = node.children.find(
      (child) => child.string_id === pathList[0]
    );
    if (nextNode === undefined) return "";
    return findPath(nextNode, pathList.slice(1), path + "/" + nextNode.name);
  };
  return findPath(fileTreeNode, pathList, "");
};

export const selectFolderByPath = ({
  fileTreeNode,

  pathStringId,
  setSelectedFolder,
  userName,
}: {
  fileTreeNode: FileTreeNode | undefined;

  pathStringId: string;
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder>>;
  userName: string;
}) => {
  if (fileTreeNode === undefined) return;
  const node = findNodeByPath({
    fileTreeNode: fileTreeNode,
    pathStringId: pathStringId,
  });
  const path = findPathByPathStringId({
    fileTreeNode: fileTreeNode,
    pathStringId: pathStringId,
  });
  if (node === undefined) {
    setSelectedFolder({
      name: `${userName}`,
      id: null,
      path: "",
      path_string_id: "",
      children: fileTreeNode.children,
    });
    return;
  }

  const selectedFolder = {
    id: node.id,
    name: node.id === null ? `${userName}` : node.name,
    path: path,
    path_string_id: pathStringId,
    children: node.children,
  };
  setSelectedFolder(selectedFolder);
};

export const getParentFolderPath = (path: string | null) => {
  if (path === null) return "";
  const lastIndex = path.lastIndexOf("/");
  if (lastIndex === -1) return path;
  return path.substring(0, lastIndex);
};

export const deleteFolder = async ({
  node,
  toast,
  authContext,
}: {
  node: FileTreeNode;
  toast: ReturnType<typeof useToast>;
  authContext: AuthContextType;
}): Promise<void> => {
  if (node.type === "file") {
    if (node.id === null) {
      return;
    }
    try {
      console.log("deleting file: ", node.name);
      await requestRemoveFile(authContext, node.id);
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: `파일 "${node.name}" 삭제에 실패했습니다. 다시 시도해 주세요.`,
        toast: toast,
      });
      return;
    }
  } else if (node.type === "folder") {
    if (node.id === null) {
      return;
    }
    for (const child of node.children) {
      await deleteFolder({
        node: child,
        toast: toast,
        authContext: authContext,
      });
    }
    try {
      console.log("deleting folder: ", node.name);
      await requestRemoveFolder(authContext, node.id);
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: `폴더 "${node.name}" 삭제에 실패했습니다. 다시 시도해 주세요.`,
        toast: toast,
      });
    }
  }
};

export const shouldDeleteNode = (node: FileTreeNode): boolean => {
  if (node.type === "file" && node.status === "submitted") {
    return false;
  }
  for (const child of node.children) {
    if (!shouldDeleteNode(child)) {
      return false;
    }
  }
  return true;
};

export const MakeOnFilesAddedCallback = ({
  authContext,
  path,
  parentFolderId,
  setFilesAndFolders,
  toast,
  setShouldBlinkFileTree = () => {},
  setDoHighlightFlex = () => {},
}: {
  authContext: AuthContextType;
  path: string;
  parentFolderId: number | null;
  setFilesAndFolders: React.Dispatch<
    React.SetStateAction<FilesAndFolders | undefined>
  >;
  toast: ReturnType<typeof useToast>;
  setShouldBlinkFileTree?: React.Dispatch<React.SetStateAction<boolean>>;
  setDoHighlightFlex?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      setDoHighlightFlex(false);
      setShouldBlinkFileTree(true);
      processUploadFiles(
        authContext,
        acceptedFiles,
        path,
        parentFolderId,
        setFilesAndFolders,
        setShouldBlinkFileTree,
        toast
      );
    },
    [path]
  );
};

export const MakeOnFilesRejectedCallback = ({
  toast,
}: {
  toast: ReturnType<typeof useToast>;
}) => {
  return useCallback((rejectedFiles) => {
    rejectedFiles.forEach((rejectedFile) => {
      const { file, errors } = rejectedFile;

      errors.forEach((error) => {
        let message = "";
        switch (error.code) {
          case "file-invalid-type":
            message = `파일 ${file.name} 유형이 허용되지 않습니다.`;
            break;
          case "file-too-large":
            message = `파일 ${file.name}이(가) 너무 큽니다. 최대 크기는 1GB입니다.`;
            break;
          default:
            message = "알 수 없는 이유로 파일이 거부되었습니다.";
        }

        toast({
          title: "파일 거부됨",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    });
  }, []);
};

export const getIsAlreadyUsedNameInSelectedFolder = ({
  selectedFolder,
  filesAndFolders,
  newFolderName,
}: {
  selectedFolder: SelectedFolder;
  filesAndFolders: FilesAndFolders | undefined;
  newFolderName: string;
}): boolean => {
  const selected_folder_id = selectedFolder.id;
  const foldersInSelectedFolder = filesAndFolders
    ? filesAndFolders.folders.filter(
        (folder) => folder.parent_folder_id === selected_folder_id
      )
    : [];
  const isAlreadyUsedNameInSelectedFolder = foldersInSelectedFolder.some(
    (folder) => folder.name === newFolderName
  );
  return isAlreadyUsedNameInSelectedFolder;
};

export const getIsAlreadyUsedNameInParentFolder = ({
  id,
  filesAndFolders,
  nameToChange,
  type = "folder",
}: {
  id: number | null;
  filesAndFolders: FilesAndFolders | undefined;
  nameToChange: string;
  type?: "folder" | "file";
}): boolean => {
  if (type === "folder") {
    const selected_folder_id = id;
    const parent_folder = filesAndFolders
      ? filesAndFolders.folders.filter(
          (folder) => folder.id === selected_folder_id
        )
      : [];

    const parent_folder_id =
      parent_folder.length > 0 ? parent_folder[0].parent_folder_id : null;
    const foldersInParentFolder = filesAndFolders
      ? filesAndFolders.folders.filter(
          (folder) => folder.parent_folder_id === parent_folder_id
        )
      : [];
    const isAlreadyUsedNameInParentFolder = foldersInParentFolder.some(
      (folder) =>
        String(folder.name).normalize() === String(nameToChange).normalize() &&
        folder.id !== selected_folder_id
    );
    return isAlreadyUsedNameInParentFolder;
  } else {
    const selected_file_id = id;
    const seletecd_file = filesAndFolders
      ? filesAndFolders.files.filter((file) => file.id === selected_file_id)
      : [];
    const parent_folder_id =
      seletecd_file?.length > 0 ? seletecd_file[0].parent_folder_id : null;
    const filesInParentFolder = filesAndFolders
      ? filesAndFolders.files.filter(
          (file) => file.parent_folder_id === parent_folder_id
        )
      : [];
    const isAlreadyUsedNameInParentFolder = filesInParentFolder.some(
      (file) =>
        String(file.name).normalize() === String(nameToChange).normalize() &&
        file.id !== selected_file_id
    );
    return isAlreadyUsedNameInParentFolder;
  }
};

export const putUrlInTabItem = async (
  tabItem: TabItem,
  authContext: AuthContextType
): Promise<TabItem> => {
  const id = tabItem.id;
  if (id === null) return tabItem;
  const { url } = await requestFileDownloadUrl(authContext, id);
  tabItem.status = "Success";
  const newTabItem = { ...tabItem };
  newTabItem.url = url;
  return newTabItem;
};

export const findFileIdsInFileTreeNode = (
  fileTreeNode: FileTreeNode
): number[] => {
  let fileIds: number[] = [];
  const findFileIds = (node: FileTreeNode) => {
    if (node.type === "file" && node.id !== null) {
      fileIds.push(node.id);
    }
    node.children.forEach((child) => {
      findFileIds(child);
    });
  };
  findFileIds(fileTreeNode);
  return fileIds;
};

export const findFolderIdsInSelectedFolder = (
  selectedFolder: SelectedFolder
): number[] => {
  let folderIds: number[] = [];

  const id = selectedFolder.id === null ? -1 : selectedFolder.id;
  folderIds.push(id);

  const findFolderIds = (node: FileTreeNode) => {
    if (node.type === "folder" && node.id !== null) {
      folderIds.push(node.id);
    }
    node.children.forEach((child) => {
      findFolderIds(child);
    });
  };
  selectedFolder.children?.forEach((child) => {
    findFolderIds(child);
  });

  return folderIds;
};

export const findSubmittedFilesInFileTreeNode = (
  selectedFolder: SelectedFolder
): string[] => {
  let fileNames: string[] = [];

  const findSubmittedFilePaths = (node: FileTreeNode, prevPath: string) => {
    const path = prevPath + "/" + node.name;
    if (node.type === "file" && node.status === "submitted") {
      fileNames.push(prevPath + "/" + node.name);
    }
    node.children.forEach((child) => {
      findSubmittedFilePaths(child, path);
    });
  };
  selectedFolder.children?.forEach((child) => {
    findSubmittedFilePaths(child, "..");
  });

  return fileNames;
};

export const openPdfInTab = ({
  tabItems,
  setTabItems,
  id,
  name,
  page,
}: {
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  id: number | null;
  name: string;
  page?: number;
}) => {
  const isAlreadyOpened = tabItems.tabList.some((tabItem) => tabItem.id === id);
  if (!isAlreadyOpened) {
    const newTabList = [
      ...tabItems.tabList,
      {
        title: name,
        id: id,
        type: "pdf" as "pdf" | "fileExplorer",
        status: "Loading" as "Loading" | "Success" | "Error",
        page: page,
      },
    ];
    setTabItems({
      tabList: newTabList,
      activeTabId: id,
    });
  } else {
    const updatedTabList = tabItems.tabList.map((tabItem, _) => {
      if (tabItem.id === id) {
        const newPage = page !== undefined ? page : Number(tabItem.page);
        return { ...tabItem, page: newPage };
      }
      return tabItem;
    });

    setTabItems((prev) => {
      return {
        tabList: updatedTabList,
        activeTabId: id,
      };
    });
  }
};
