import * as React from "react";
import { useContext } from "react";
import { useSetAtom, useAtom } from "jotai";
import { IconButton, Icon, Flex, Input, Text, Divider } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { LuX } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  requestQuery,
  requestSearchHistory,
  requestDeleteSearchHistory,
  analyzeCaseNameInQuery,
  requestCaseInfoByCaseNumber,
  requestAddHistory,
  SearchHistory,
} from "../../logics/Search";
import {
  searchResultsWithStatusAtom,
  selectedCaseAtom,
  currentResultIdAtom,
  ContentPerPage,
} from "../../states/Search";
import { handleEnterPress } from "../../utils/UIUtils";

const SearchBox = ({
  directionExpansion = "down",
  inNavbar = false,
  initialQuery = "",
  showHistory = true,
}: {
  directionExpansion?: "up" | "down";
  inNavbar?: boolean;
  initialQuery?: string;
  showHistory?: boolean;
}) => {
  const [searchResultsWithStatus, setSearchResultsWithStatus] = useAtom(
    searchResultsWithStatusAtom,
  );

  const setCurrentResultId = useSetAtom(currentResultIdAtom);
  const paddingY = inNavbar ? "5px" : "10px";

  const flexDirection = directionExpansion === "up" ? "flex-end" : "flex-start";
  const authContext = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();
  const [query, setQuery] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const setSelectedCase = useSetAtom(selectedCaseAtom);

  const [searchHistory, setSearchHistory] = React.useState<SearchHistory[]>([]);

  React.useLayoutEffect(() => {
    requestSearchHistory(authContext).then((searchHistory) => {
      setSearchHistory(searchHistory);
    });
  }, []);

  React.useEffect(() => {
    if (initialQuery !== "") {
      setQuery(initialQuery);
    }
  }, [initialQuery]);
  const boxShadow = inNavbar ? "" : "base";

  const handleOnClick = async (e) => {
    if (isLoading) {
      return;
    }
    const id = Date.now();
    setCurrentResultId(id);
    e.preventDefault();
    e.target.blur();

    if (searchResultsWithStatus.status !== "Empty") {
      setSearchResultsWithStatus({
        status: "Loading",
        startIdxDisplay: 0,
        query: query,
      });
    }
    if (searchHistory.some((history) => history.question === query) === false) {
      await requestAddHistory(authContext, query);
    }
    if (inNavbar) {
      await requestSearchHistory(authContext).then((searchHistory) => {
        setSearchHistory(searchHistory);
      });
    }
    setIsLoading(true);
    setSelectedCase(undefined);
    setSearchResultsWithStatus({
      status: "Loading",
      startIdxDisplay: 0,
      query: query,
    });

    navigate("/search");

    const startTimeAnalizeCaseName = Date.now();
    const caseNameAnalysisResult = analyzeCaseNameInQuery(query);
    const endTimeAnalizeCaseName = Date.now();
    console.log(
      "elapsed time (판례명 분석)",
      endTimeAnalizeCaseName - startTimeAnalizeCaseName,
    );

    const startTimeRequestCaseInfo = Date.now();
    const casesSearchResult = await requestCaseInfoByCaseNumber(
      authContext,
      caseNameAnalysisResult.caseNumber,
    );
    const endTimeRequestCaseInfo = Date.now();
    console.log(
      "elapsed time (우선적 판례 정보 요청)",
      endTimeRequestCaseInfo - startTimeRequestCaseInfo,
    );

    const prioritizedCasesData = casesSearchResult.cases;
    if (casesSearchResult.error !== undefined) {
      setSearchResultsWithStatus({
        status: "Error",
        searchResults: [],
        query: query,
        id: id,
        startIdxDisplay: 0,
        took: 0,
      });
      setIsLoading(false);
      return;
    }
    if (
      caseNameAnalysisResult.type === "exactCaseName" &&
      prioritizedCasesData.length === 1
    ) {
      const caseId = prioritizedCasesData[0].id;
      setIsLoading(false);
      navigate(`/case/${caseId}`);
    } else {
      try {
        const startTimeRequestQuery = Date.now();
        const { searchResults, took } = await requestQuery(
          authContext,
          query,
          ContentPerPage,
        );

        const endTimeRequestQuery = Date.now();
        console.log(
          "elapsed time (검색 요청)",
          endTimeRequestQuery - startTimeRequestQuery,
        );

        const prioritizedSearchResults = prioritizedCasesData
          ? prioritizedCasesData.map((caseData) => {
              const case_id = caseData.id;
              const name = JSON.parse(caseData.contents_json).제목;
              const prioritizedCaseData = {
                case: caseData,
                case_id: case_id,
                name: name,
                relavant_parts: [],
                score: 0,
              };
              return prioritizedCaseData;
            })
          : undefined;
        setSearchResultsWithStatus({
          status: "Finished",
          caseNameAnalysisResult: caseNameAnalysisResult,
          prioritizedSearchResults: prioritizedSearchResults,
          searchResults: searchResults,
          query,
          took,
          id: id,
          startIdxDisplay: 0,
        });
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setSearchResultsWithStatus({
          status: "Error",
          searchResults: [],
          query: query,
          id: id,
          startIdxDisplay: 0,
          took: 0,
        });
        setIsLoading(false);
        return;
      }
    }
  };

  const [showHistoryList, setShowHistoryList] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleDeleteHistory = async (id: number) => {
    await requestDeleteSearchHistory(authContext, id);
    await requestSearchHistory(authContext).then((searchHistory) => {
      setSearchHistory(searchHistory);
    });
  };

  return (
    <Flex
      width={"100%"}
      justifyContent={flexDirection}
      height={inNavbar ? "36px" : "46px"}
      direction="column"
    >
      <Flex width={"100%"} alignItems={flexDirection}>
        <Flex
          border={"1px"}
          borderColor={"gray.300"}
          borderRadius={"20px"}
          width={"100%"}
          justifyContent={flexDirection}
          py={paddingY}
          pe="10px"
          ps="10px"
          zIndex={10}
          boxShadow={boxShadow}
          bg="white"
        >
          <IconButton
            borderRadius={"full"}
            aria-label="Search"
            size="xs"
            bg={query === "" ? "gray.300" : "blue.600"}
            _hover={{ bg: query === "" ? "gray.300" : "blue.500" }}
            icon={<Icon as={SearchIcon} />}
            colorScheme="blue"
            onClick={(e) => {
              if (query === "") {
                return;
              }

              handleOnClick(e);
            }}
          />
          <Flex width={"100%"} direction={"column"}>
            <Input
              ref={inputRef}
              minHeight={"24px"}
              maxHeight={inNavbar ? "24px" : "200px"}
              as={TextareaAutosize}
              resize={"none"}
              value={query}
              size={"md"}
              placeholder="질문을 입력하세요."
              border={"none"}
              overflowY={inNavbar ? "hidden" : "auto"}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              onFocus={() => {
                setShowHistoryList(true);
              }}
              onBlur={() => {
                setShowHistoryList(false);
              }}
              _focus={{
                boxShadow: "none",
                maxHeight: "200px",
                overflowY: "auto",
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && query === "") {
                  e.preventDefault();
                  return;
                }
                handleEnterPress(e, () => {
                  handleOnClick(e);
                });
              }}
              bg="white"
              zIndex={10}
            />

            {showHistory && showHistoryList && searchHistory.length > 0 && (
              <>
                <Divider my="5px" />
                <Text
                  my="2px"
                  ms="12px"
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  color={"gray.600"}
                >
                  {"최근 검색어"}
                </Text>
                <Flex direction="column" width={"100%"} zIndex={20}>
                  {searchHistory
                    .filter((history) => {
                      return history.deleted === 0;
                    })
                    .map((history) => {
                      return (
                        <Flex width={"100%"} alignItems={"center"}>
                          <Flex
                            key={history.id}
                            width={"100%"}
                            borderRadius={"5px"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            m={"2px"}
                            py="5px"
                            flex={1}
                            px="10px"
                            bg="white"
                            cursor={"pointer"}
                            _hover={{ bg: "gray.100" }}
                            onClick={(e) => {
                              setQuery(history.question);
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault(); // 이 부분이 추가됨
                            }}
                          >
                            <Text
                              color={"gray.600"}
                              noOfLines={2}
                              overflow="hidden"
                              textOverflow="ellipsis"
                              display="-webkit-box"
                              css={{
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                              }}
                            >
                              {history.question}
                            </Text>
                          </Flex>
                          <IconButton
                            size="xs"
                            onMouseDown={(e) => {
                              e.preventDefault(); // 이 부분이 추가됨
                            }}
                            aria-label="Delete History"
                            variant={"ghost"}
                            icon={<Icon as={LuX} color={"gray.500"} />}
                            onClick={() => {
                              handleDeleteHistory(history.id);
                            }}
                          />
                        </Flex>
                      );
                    })}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SearchBox;
