import React from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useContext } from "react";
import { FaChevronRight, FaFolder, FaFolderOpen } from "react-icons/fa";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  FileTreeNode,
  FilesAndFolders,
  SelectedFolder,
  MakeOnFilesAddedCallback,
  MakeOnFilesRejectedCallback,
} from "../../logics/FileManager";
import {
  FileTreeExplorerWidth,
  FileTreeExplorerRightBorderWidth,
} from "./FileExplorer";
import { FileTreeExplorerMainPadding } from "./FileTreeExplorer";

const FileTreeNodeToAccordionItem = ({
  fileTreeNode,
  depth,
  basePath,
  basePathStringId,
  selectedFolder,
  setSelectedFolder,
  setFilesAndFolders,
  setShouldBlinkFileTree,
}: {
  fileTreeNode: FileTreeNode;
  depth: number;
  basePath: string;
  basePathStringId: string;
  selectedFolder: SelectedFolder;
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder>>;
  setFilesAndFolders: React.Dispatch<React.SetStateAction<FilesAndFolders>>;
  setShouldBlinkFileTree: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [rotate, setRotate] = React.useState<boolean>(false);
  const currentDepth = depth + 1;
  const handleClick = () => {
    setRotate(!rotate);
    setIsOpen(!isOpen);
  };
  const iconStyle = {
    transform: rotate ? "rotate(90deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  };
  const toast = useToast();
  const authContext = useContext(AuthContext) as AuthContextType;
  const [doHighlightFolderNameCurrent, setDoHighlightFolderNameCurrent] =
    React.useState<Boolean>(false);

  const currentPath = basePath + "/" + fileTreeNode.name;
  const currentPathStingId = basePathStringId + "/" + fileTreeNode.string_id;

  const onFilesAdded = MakeOnFilesAddedCallback({
    authContext: authContext,
    path: currentPath,
    parentFolderId: fileTreeNode.id,
    toast: toast,
    setFilesAndFolders: setFilesAndFolders,
    setShouldBlinkFileTree: setShouldBlinkFileTree,
    setDoHighlightFlex: setDoHighlightFolderNameCurrent,
  });

  const onFilesRejected = MakeOnFilesRejectedCallback({
    toast: toast,
  });

  const { getRootProps } = useDropzone({
    onDrop: onFilesAdded,
    onDropRejected: onFilesRejected,
    noClick: true,
    accept: { "application/pdf": [] },
    maxSize: 1024 * 1024 * 1024,
  });
  const [isClicked, setIsClicked] = React.useState<Boolean>(false);
  const handleOnClickFlex = () => {
    setSelectedFolder({
      name: fileTreeNode.name,
      id: fileTreeNode.id,
      path: currentPath,
      path_string_id: currentPathStingId,
      children: fileTreeNode.children,
    });
  };

  const uploadAreaEndPadding = 2;
  const openIconWidth = 20;
  const openIconMargin = 2;
  const folderNameAreaWidth =
    FileTreeExplorerWidth -
    FileTreeExplorerRightBorderWidth -
    2 * FileTreeExplorerMainPadding -
    uploadAreaEndPadding -
    openIconWidth -
    2 * openIconMargin -
    (currentDepth - 1) * 20;

  React.useEffect(() => {
    if (selectedFolder.path_string_id === currentPathStingId) {
      setIsClicked(true);
    } else {
      setIsClicked(false);
    }
  }, [selectedFolder]);

  return (
    fileTreeNode.type === "folder" && (
      <Flex direction="column">
        <Flex
          {...getRootProps()}
          alignItems={"center"}
          borderRadius={"5px"}
          py="2px"
          pe={`${uploadAreaEndPadding}px`}
          bg={
            doHighlightFolderNameCurrent
              ? "blue.100"
              : isClicked
                ? "gray.300"
                : ""
          }
          onDragOver={(e) => {
            e.preventDefault();
            setDoHighlightFolderNameCurrent(true);
          }}
          onDragLeave={() => setDoHighlightFolderNameCurrent(false)}
        >
          <Flex
            minWidth={`${(currentDepth - 1) * 20}px`}
            maxWidth={`${(currentDepth - 1) * 20}px`}
          />
          <IconButton
            size="small"
            variant={"ghost"}
            aria-label="open"
            width={`${openIconWidth}px`}
            minWidth={`${openIconWidth}px`}
            maxWidth={`${openIconWidth}px`}
            onClick={handleClick}
            m={`${openIconMargin}px`}
            height="15px"
            _hover={{ bg: "gray.200" }}
          >
            <FaChevronRight size={"12px"} style={iconStyle} />
          </IconButton>
          <Flex
            id={"Folder Name"}
            maxWidth={`${folderNameAreaWidth}px`}
            width={`${folderNameAreaWidth}px`}
            minWidth={`${folderNameAreaWidth}px`}
            _hover={{ bg: "gray.200" }}
            borderRadius={"5px"}
            cursor={"pointer"}
            px="2px"
            onClick={handleOnClickFlex}
          >
            <Flex
              minWidth={"16px"}
              maxWidth={"16px"}
              id={"Folder Icon"}
              height={"24px"}
              alignItems={"center"}
            >
              {isOpen ? <FaFolderOpen /> : <FaFolder />}
            </Flex>
            <Text
              userSelect={"none"}
              noOfLines={2}
              overflow="hidden"
              textOverflow="ellipsis"
              display="-webkit-box"
              css={{
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
              align="left"
              ms="5px"
            >
              {fileTreeNode.name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={"column"}
          height={isOpen ? "" : "0px"}
          overflow="hidden"
        >
          {fileTreeNode.children.map((child) => {
            return (
              <FileTreeNodeToAccordionItem
                fileTreeNode={child}
                depth={currentDepth}
                basePath={currentPath}
                basePathStringId={currentPathStingId}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                setFilesAndFolders={setFilesAndFolders}
                setShouldBlinkFileTree={setShouldBlinkFileTree}
              />
            );
          })}
        </Flex>
      </Flex>
    )
  );
};

export default FileTreeNodeToAccordionItem;
