import * as React from "react";
import { useState } from "react";
import {
  Flex,
  Divider,
  Heading,
  Skeleton,
  IconButton,
  Icon,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {
  OnlyMobile,
  OnlyPC,
  highlightByRelevantParts,
} from "../../utils/UIUtils";
import { selectedCaseAtom } from "../../states/Search";
import { useSetAtom } from "jotai";
import { MdOutlineSummarize } from "react-icons/md";
import { AiOutlineExport } from "react-icons/ai";
import { caseSearchResulWidth } from "./SearchResultDisplay";
import { FaBookmark } from "react-icons/fa";
import {
  filterCases,
  getFolderName,
  requestDeleteObjectInCaseArchive,
  requestCaseArchiveList,
} from "../../logics/CaseArchive";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { navbarHeight } from "../Navbar";
import TobeImplementedTab from "../../tabs/TobeImplementedTab";
import DisplayPreview from "./DisplayPreview";
import CaseSummaryDisplay from "./CaseSummaryDisplay";
import CaseSaveModal from "./CaseSaveModal";

const ExternalSearchResultListItem = ({
  searchResult,
  index,
  selectedSummaryIndex,
  setSelectedSummaryIndex,
  caseTree,
  shouldBlinkCaseTree,
  setShouldBlinkCaseTree,
  savedCasesAndFolders,
  setSavedCasesAndFolders,
  savedCasesList,
  setSavedCasesLoadError,
  isPrioritized = false,
  isLast = false,
}) => {
  const setSelectedCase = useSetAtom(selectedCaseAtom);
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [highlightedDocDic, setHighlightedDocDic] = useState<any>("");
  const [highlightedContentsJson, setHighlightedContentsJson] =
    useState<any>("");

  React.useEffect(() => {
    const contentsJson = JSON.parse(searchResult.case.contents_json);
    if (searchResult.relevant_parts === undefined) {
      setHighlightedDocDic("");
      setHighlightedContentsJson(contentsJson);
      return;
    }
    const [highlightedDocDic, highlightedContentsJson] =
      highlightByRelevantParts(contentsJson, searchResult.relevant_parts);

    setHighlightedDocDic(highlightedDocDic);

    setHighlightedContentsJson(highlightedContentsJson);
  }, [searchResult]);

  const caseId = searchResult.case_id;
  const caseName = searchResult.name;

  const [folderListSavingCase, setFolderListSavingCase] = React.useState<
    { id: number | null; name: string; id_case: number }[]
  >([]);

  React.useEffect(() => {
    if (savedCasesList === undefined) return;
    const filteredSavedCases = filterCases(savedCasesList, caseId);
    const folderListSavingCase = filteredSavedCases.map((savedCase) => ({
      id: savedCase.parent_folder_id,
      name: getFolderName(savedCasesAndFolders, savedCase.parent_folder_id),
      id_case: savedCase.id,
    }));
    setFolderListSavingCase(folderListSavingCase);
    if (filteredSavedCases.length === 0) {
      setIsSaved(false);
    } else {
      setIsSaved(true);
    }
  }, [savedCasesList]);

  const getSelectedCase = () => {
    const 판례 = searchResult.case;
    const caseInformation = {
      법원: 판례.법원,
      사건명: 판례.사건명,
      사건번호: 판례.사건번호,
      사건종류: 판례.사건종류,
      선고: 판례.선고,
      선고일자: 판례.선고일자,
      이유: 판례.이유,
      주문: 판례.주문,
      청구취지: 판례.청구취지,
      판결유형: 판례.판결유형,
    };
    return {
      case_id: searchResult.case_id,
      name: searchResult.name,
      contents_json: highlightedContentsJson,
      case_information: caseInformation,
    };
  };

  const handleOnClickName = () => {
    const selectedCase = getSelectedCase();
    setSelectedCase(selectedCase);
  };

  const [isSaved, setIsSaved] = React.useState<boolean>(false);

  const [caseSummaryState, setCaseSummaryState] = React.useState<
    "Ready" | "Loading" | "Finished" | "Error"
  >("Ready");

  const handleOnClickSummaryAnswer = () => {
    if (showSummary && caseSummaryState === "Finished") {
      setSelectedSummaryIndex(index);
      return;
    }
    if (caseSummaryState === "Loading") {
      return;
    }
    setCaseSummaryState("Loading");
    setShowSummary(true);
    setSelectedSummaryIndex(index);
  };

  const handleOnClickNewWindowCase = () => {
    const path = `/case/${searchResult.case_id}`;
    const 판례 = searchResult.case;
    const caseInformation = {
      법원: 판례.법원,
      사건명: 판례.사건명,
      사건번호: 판례.사건번호,
      사건종류: 판례.사건종류,
      선고: 판례.선고,
      선고일자: 판례.선고일자,
      이유: 판례.이유,
      주문: 판례.주문,
      청구취지: 판례.청구취지,
      판결유형: 판례.판결유형,
    };
    const caseData = {
      name: searchResult.name,
      case_id: searchResult.case_id,
      contents_json: highlightedContentsJson,
      case_information: caseInformation,
    };
    localStorage.setItem("caseViewerData", JSON.stringify(caseData));
    window.open(path, "_blank");
  };

  const toast = useToast();

  const handleOnClickMenuItem = async (id_case: number) => {
    console.log("id_case", id_case);
    setShouldBlinkCaseTree(true);
    try {
      await requestDeleteObjectInCaseArchive(authContext, id_case, "case");
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: "삭제에 실패했습니다. 다시 시도해 주세요.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setShouldBlinkCaseTree(false);
      return;
    }
    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: "Error",
          description:
            "저장된 판례를 불러오는데 실패했습니다. 새로고침 해주세요.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setSavedCasesLoadError(true);
      });
  };

  const {
    isOpen: isOpenSaveCase,
    onOpen: onOpenSaveCase,
    onClose: onCloseSaveCase,
  } = useDisclosure();

  return (
    <>
      <OnlyPC>
        <Flex alignItems={"flex-start"} width={"100%"}>
          <Flex
            direction={"column"}
            minWidth={`${caseSearchResulWidth}px`}
            maxWidth={`${caseSearchResulWidth}px`}
          >
            <Flex direction="column" width="100%" key={index}>
              <Flex mt="15px" mb="10px">
                <Flex flex={1} alignItems={"center"}>
                  <Heading
                    _hover={{
                      color: "blue.500",
                    }}
                    cursor={"pointer"}
                    size="sm"
                    color="gray.700"
                    onClick={() => handleOnClickName()}
                  >
                    {isPrioritized ? (
                      <u style={{ textDecoration: "underline #3182CE" }}>
                        {searchResult.name}
                      </u>
                    ) : (
                      searchResult.name
                    )}
                  </Heading>
                </Flex>
                <Flex ms="10px">
                  <Tooltip
                    bg="gray.500"
                    label={
                      caseSummaryState === "Ready"
                        ? "요약하기"
                        : caseSummaryState === "Finished"
                          ? "요약 보기"
                          : caseSummaryState === "Error"
                            ? "요약 재시도"
                            : "요약 하기"
                    }
                  >
                    <IconButton
                      size={"sm"}
                      aria-label="요약"
                      _loading={{ color: "gray.500" }}
                      isLoading={caseSummaryState === "Loading"}
                      variant={"outline"}
                      borderColor={
                        index === selectedSummaryIndex ? "blue.500" : "gray.500"
                      }
                      bg={index === selectedSummaryIndex ? "blue.50" : ""}
                      icon={
                        <MdOutlineSummarize
                          color={
                            index === selectedSummaryIndex ? "#3182CE" : ""
                          }
                        />
                      }
                      _hover={{ bg: "gray.100" }}
                      onClick={handleOnClickSummaryAnswer}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
              {highlightedDocDic !== "" && (
                <Flex mb="5px">
                  {highlightedDocDic === undefined ? (
                    <Skeleton width={"100%"} height="20px" />
                  ) : (
                    <DisplayPreview
                      highlightedDocDic={highlightedDocDic}
                      caseData={getSelectedCase()}
                    />
                  )}
                </Flex>
              )}
            </Flex>
            <Flex
              mb="10px"
              width={"100%"}
              alignItems="flex-start"
              justifyContent={"flex-end"}
              ps="10px"
            >
              {isSaved ? (
                <Menu>
                  <Tooltip bg="gray.500" label={"판례보관함에 저장"}>
                    <MenuButton
                      size={"xs"}
                      me="5px"
                      isLoading={savedCasesList === undefined}
                      as={IconButton}
                      icon={
                        <Icon
                          as={FaBookmark}
                          color={isSaved ? "blue.500" : "gray.400"}
                        />
                      }
                    />
                  </Tooltip>

                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        onOpenSaveCase();
                      }}
                    >
                      {"판례 보관함 저장"}
                    </MenuItem>
                    {folderListSavingCase.map((folder) => (
                      <MenuItem
                        onClick={() => handleOnClickMenuItem(folder.id_case)}
                        key={folder.id_case}
                      >
                        {`"${folder.name}" 폴더에서 삭제`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              ) : (
                <Tooltip bg="gray.500" label={"판례보관함에 저장"}>
                  <IconButton
                    size={"xs"}
                    me="5px"
                    variant={"outline"}
                    isLoading={savedCasesList === undefined}
                    aria-label="save case"
                    icon={
                      <Icon
                        as={FaBookmark}
                        color={isSaved ? "blue.500" : "gray.400"}
                      />
                    }
                    _hover={{ bg: "gray.300" }}
                    onClick={() => {
                      onOpenSaveCase();
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip bg="gray.500" label="새창에서 열기">
                <IconButton
                  size={"xs"}
                  variant={"outline"}
                  aria-label="new window"
                  icon={<AiOutlineExport />}
                  _hover={{ bg: "gray.300" }}
                  onClick={handleOnClickNewWindowCase}
                />
              </Tooltip>
            </Flex>
            {!isLast && <Divider borderColor={"gray.300"} />}
          </Flex>
          {showSummary && (
            <CaseSummaryDisplay
              index={index}
              selectedSummaryIndex={selectedSummaryIndex}
              setSelectedSummaryIndex={setSelectedSummaryIndex}
              setCaseSummaryState={setCaseSummaryState}
              caseSummaryState={caseSummaryState}
              case_id={searchResult.case_id}
            />
          )}
        </Flex>
      </OnlyPC>
      <CaseSaveModal
        isOpenSaveCase={isOpenSaveCase}
        onCloseSaveCase={onCloseSaveCase}
        caseTree={caseTree}
        shouldBlinkCaseTree={shouldBlinkCaseTree}
        setShouldBlinkCaseTree={setShouldBlinkCaseTree}
        savedCasesAndFolders={savedCasesAndFolders}
        setSavedCasesAndFolders={setSavedCasesAndFolders}
        caseId={caseId}
        caseName={caseName}
      />
      <OnlyMobile>
        <Flex width={"100%"} height={`calc( 100vh - ${navbarHeight}px)`}>
          <TobeImplementedTab mobile={true} />
        </Flex>
      </OnlyMobile>
    </>
  );
};

export default ExternalSearchResultListItem;
