import React from "react";
import { Flex } from "@chakra-ui/react";
import { TabItems } from "../../logics/FileManager";
import { navbarHeight } from "../Navbar";

import TabSelector from "./TabSelector";
import FileExplorer from "./FileExplorer";
import PdfViewerInFileManager from "./PdfViewerInFileManager";

export const tabSelectorHeight = 48;

const FileManagerMain = () => {
  const [tabItems, setTabItems] = React.useState<TabItems>({
    tabList: [
      {
        title: "파일탐색기",
        id: null,
        status: "Success",
      },
    ],
    activeTabId: null,
  });

  const activeTabColor = "white";

  return (
    <Flex direction={"column"} width={"100%"} position={"relative"}>
      <TabSelector
        tabItems={tabItems}
        setTabItems={setTabItems}
        activeTabColor={activeTabColor}
        tabSelectorHeight={tabSelectorHeight}
      />
      <Flex
        id="TabContent"
        width={"100vw"}
        zIndex={tabItems.activeTabId === null ? 10 : 0}
        height={`calc( 100vh - ${tabSelectorHeight + navbarHeight}px)`}
        overflow={"auto"}
        bg={activeTabColor}
      >
        <FileExplorer tabItems={tabItems} setTabItems={setTabItems} />
      </Flex>
      {tabItems.tabList
        .filter((tabItem) => tabItem.id !== null)
        .map((tabItem) => {
          return (
            <Flex
              position={"absolute"}
              left={0}
              bg="white"
              zIndex={tabItem.id === tabItems.activeTabId ? 10 : 0}
              top={`${tabSelectorHeight}px`}
              height={`calc( 100vh - ${tabSelectorHeight + navbarHeight}px)`}
              width={"100vw"}
              overflow={"auto"}
            >
              <PdfViewerInFileManager
                currentTabItem={tabItem}
                setTabItems={setTabItems}
              />
            </Flex>
          );
        })}
    </Flex>
  );
};

export default FileManagerMain;
