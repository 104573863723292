import React from "react";
import { Flex } from "@chakra-ui/react";

import { TabItems } from "../../logics/FileManager";
import Tab from "./Tab";

export const higlightTabBorderColor = "blue.500";
export const tabBorderColor = "gray.400";

const TabSelector = ({
  tabItems,
  setTabItems,
  activeTabColor = "white",
  tabSelectorHeight = 48,
}: {
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  activeTabColor?: string;
  tabSelectorHeight?: number;
}) => {
  const tabSeletorBgColor = "gray.300";
  const inactiveTabColor = "gray.100";
  const tabMaxWidthPixel = 250;

  return (
    <Flex
      id="TabSelector"
      direction={"column"}
      minHeight={`${tabSelectorHeight}px`}
      maxHeight={`${tabSelectorHeight}px`}
      width={"100%"}
    >
      <Flex
        width="100%"
        height={"100%"}
        bg={tabSeletorBgColor}
        maxWidth={"100vw"}
        alignItems={"flex-end"}
        position={"relative"}
        borderBottom="2px"
        borderColor={
          tabItems.activeTabId === null
            ? higlightTabBorderColor
            : tabBorderColor
        }
      >
        <Flex position={"absolute"} bottom="-2px" maxWidth={"100vw"} px="5px">
          {tabItems.tabList.map((tabItem) => {
            const id = tabItem.id;
            const title = tabItem.title;
            return (
              <Tab
                key={id}
                title={title}
                id={id}
                tabWidth={id === null ? 120 : tabMaxWidthPixel}
                setTabItems={setTabItems}
                activeTabColor={activeTabColor}
                inactiveTabColor={inactiveTabColor}
                isActive={tabItems.activeTabId === id}
                onClick={() => setTabItems({ ...tabItems, activeTabId: id })}
                highlightTitle={tabItem.id === null}
                showCloseButton={id !== null}
              />
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TabSelector;
