import * as React from "react";
import { createContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export interface AuthContextType {
  user: any;
  authTokens: any;
  loginUser: (username: string, password: string) => Promise<void>;
  logoutUser: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const toast = useToast();

  let [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(
          JSON.parse(localStorage.getItem("authTokens") as string).access
        )
      : null
  );
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens") as string)
      : null
  );
  let [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loginUser = async (username: string, password: string) => {
    try {
      const response = await fetch(
        window.myGlobal.REACT_APP_API_URL + "/api/token/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
          }),
        }
      );

      const data = await response.json();

      if (data.refresh && data.access) {
        localStorage.setItem("authTokens", JSON.stringify(data));
        setAuthTokens(data);
        setUser(jwtDecode(data.access));
        navigate("/start");
        return;
      } else if (data.detail) {
        toast({
          title: "아이디 혹은 패스워드가 잘못되었습니다.",
          status: "error",
          isClosable: true,
        });
        return;
      } else {
        toast({
          title: "로그인 실패: " + JSON.stringify(data),
          status: "error",
          isClosable: true,
        });
        return;
      }
    } catch (error: any) {
      toast({
        title: "로그인 실패: " + error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  let logoutUser = () => {
    // e.preventDefault()
    localStorage.removeItem("authTokens");
    setAuthTokens(null);
    setUser(null);
    navigate("/login");
  };

  const updateToken = async () => {
    const response = await fetch(
      window.myGlobal.REACT_APP_API_URL + "/api/token/refresh/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: authTokens?.refresh }),
      }
    );

    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  const contextData = {
    user,
    authTokens,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }

    const REFRESH_INTERVAL = 1000 * 60 * 5; // 2 minutes
    let interval = setInterval(() => {
      if (authTokens) {
        console.log("refreshing token");
        updateToken();
      }
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
