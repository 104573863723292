import React from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { SelectedCaseOrFolder } from "../../logics/CaseArchive";
import { navbarHeight } from "../Navbar";
import { caseTreeExplorerWidthPixel } from "../../tabs/CaseArchiveTab";
import { useDisclosure } from "@chakra-ui/hooks";
import CaseViewerHeader from "./CaseViewerHeader";
import CaseViewerBody from "./CaseViewerBody";
import SummaryInCaseViewer from "./SummaryInCaseViewer";
import ModalInCaseArchive from "./ModalInCaseArchive";
import { SavedCasesAndFolders } from "../../logics/CaseArchive";
import { requestGetSingleCaseInfo } from "../../logics/CaseArchive";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { Case } from "../../logics/Search";
import { CaseTreeNode } from "../../logics/CaseArchive";
import { getSelectedCase } from "../../logics/Search";

const CaseViewer = ({
  selectedCaseOrFolder,
  setSelectedCaseOrFolder,
  setShouldBlinkCaseTree,
  savedCasesAndFolders,
  setSavedCasesAndFolders,
  savedCasesLoadError,
  setSavedCasesLoadError,
  caseTree,
}: {
  selectedCaseOrFolder: SelectedCaseOrFolder | undefined;
  setSelectedCaseOrFolder: React.Dispatch<
    React.SetStateAction<SelectedCaseOrFolder | undefined>
  >;
  setShouldBlinkCaseTree: React.Dispatch<React.SetStateAction<Boolean>>;
  savedCasesAndFolders: SavedCasesAndFolders | undefined;
  setSavedCasesAndFolders: React.Dispatch<
    React.SetStateAction<SavedCasesAndFolders | undefined>
  >;
  savedCasesLoadError: boolean;
  setSavedCasesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
  caseTree: CaseTreeNode | undefined;
}) => {
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const summaryWidth = 400;
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [modalType, setModalType] = React.useState<
    "newFolder" | "renameFolder" | "deleteObject" | undefined
  >(undefined);
  const [openSummary, setOpenSummary] = React.useState<Boolean>(false);
  const [selectedCaseRaw, setSelectedCaseRaw] = React.useState<
    { caseId: number; caseName: string } | undefined
  >(undefined);

  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [selectedCase, setSelectedCase] = React.useState<Case | undefined>(
    undefined
  );

  const [errorLoadingCase, setErrorLoadingCase] =
    React.useState<Boolean>(false);

  React.useEffect(() => {
    if (selectedCaseRaw) {
      setErrorLoadingCase(false);
      requestGetSingleCaseInfo(authContext, selectedCaseRaw.caseId)
        .then((caseInfo) => {
          const selectedCase = getSelectedCase({
            caseInfo: caseInfo[0],
          });
          setSelectedCase(selectedCase);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setErrorLoadingCase(true);
          setIsLoading(false);
        });
    }
  }, [selectedCaseRaw]);

  React.useEffect(() => {
    if (
      selectedCaseOrFolder &&
      selectedCaseOrFolder.type === "case" &&
      selectedCaseOrFolder.case_id
    ) {
      setIsLoading(true);
      setSelectedCaseRaw({
        caseId: selectedCaseOrFolder.case_id,
        caseName: selectedCaseOrFolder.name,
      });
    } else {
      setSelectedCase(undefined);
    }
    setOpenSummary(false);
  }, [selectedCaseOrFolder]);

  return (
    <>
      <Flex
        height={`calc( 100vh - ${navbarHeight}px)`}
        maxHeight={`calc( 100vh - ${navbarHeight}px)`}
        maxWidth={`calc( 100vw - ${caseTreeExplorerWidthPixel}px)`}
        minWidth={`calc( 100vw - ${caseTreeExplorerWidthPixel}px)`}
        alignItems={"center"}
      >
        {errorLoadingCase ? (
          <Flex
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text color={"red.500"} fontSize={"lg"} fontWeight={"bold"}>
                {"판례를 불러오는 중 오류가 발생했습니다. 다시 시도해주세요."}
              </Text>
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex
              flex={1}
              borderRight={"1px"}
              borderColor={"gray.300"}
              maxWidth={
                openSummary
                  ? `calc( 100vw - ${
                      caseTreeExplorerWidthPixel + summaryWidth
                    }px)`
                  : `calc( 100vw - ${caseTreeExplorerWidthPixel}px)`
              }
              minWidth={
                openSummary
                  ? `calc( 100vw - ${
                      caseTreeExplorerWidthPixel + summaryWidth
                    }px)`
                  : `calc( 100vw - ${caseTreeExplorerWidthPixel}px)`
              }
              transition={
                "min-width 0.3s ease-in-out, max-width 0.3s ease-in-out"
              }
              height={"100%"}
              direction={"column"}
            >
              <CaseViewerHeader
                selectedCaseOrFolder={selectedCaseOrFolder}
                setSelectedCaseOrFolder={setSelectedCaseOrFolder}
                onOpenModal={onOpenModal}
                setModalType={setModalType}
                openSummary={openSummary}
                setOpenSummary={setOpenSummary}
                savedCasesLoadError={savedCasesLoadError}
                selectedCase={selectedCase}
              />
              {isLoading ? (
                <Flex
                  width={"100%"}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Spinner color="gray.500" size={"lg"} />
                </Flex>
              ) : (
                <CaseViewerBody
                  selectedCaseOrFolder={selectedCaseOrFolder}
                  selectedCase={selectedCase}
                />
              )}
            </Flex>
            <Flex height={"100%"} borderColor={"gray.300"} overflowX={"hidden"}>
              {selectedCaseOrFolder?.case_id && openSummary && (
                <SummaryInCaseViewer
                  openSummary={openSummary}
                  setOpenSummary={setOpenSummary}
                  summaryWidth={summaryWidth}
                  case_id={selectedCaseOrFolder.case_id}
                />
              )}
            </Flex>
          </>
        )}
      </Flex>
      <ModalInCaseArchive
        isOpenModal={isOpenModal}
        modalType={modalType}
        setModalType={setModalType}
        selectedCaseOrFolder={selectedCaseOrFolder}
        setSelectedCaseOrFolder={setSelectedCaseOrFolder}
        onCloseModal={onCloseModal}
        setShouldBlinkCaseTree={setShouldBlinkCaseTree}
        savedCasesAndFolders={savedCasesAndFolders}
        setSavedCasesAndFolders={setSavedCasesAndFolders}
        setSavedCasesLoadError={setSavedCasesLoadError}
        caseTree={caseTree}
      />
    </>
  );
};

export default CaseViewer;
