import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext, { AuthContextType } from "../context/AuthContext";

const AuthChecker = () => {
  let { authTokens } = useContext(AuthContext) as AuthContextType;

  return !authTokens ? <Navigate to="/login" /> : <Navigate to="/start" />;
};
export default AuthChecker;
