import React from "react";
import { useLayoutEffect } from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useSetAtom } from "jotai";
import { selectedNavbarKeyAtom } from "../states/HomePage";
import { OnlyMobile, OnlyPC } from "../utils/UIUtils";
import TobeImplementedTab from "./TobeImplementedTab";
import { navbarHeight } from "../components/Navbar";
import CaseTreeExplorer from "../components/CaseArchive/CaseTreeExplorer";
import CaseViewer from "../components/CaseArchive/CaseViewer";
import { SelectedCaseOrFolder, makeCaseTree } from "../logics/CaseArchive";
import {
  SavedCasesAndFolders,
  requestCaseArchiveList,
} from "../logics/CaseArchive";
import AuthContext, { AuthContextType } from "../context/AuthContext";
import { CaseTreeNode } from "../logics/CaseArchive";
import { selectCaseOrForderByPath } from "../logics/CaseArchive";
export const caseTreeExplorerWidthPixel = 350;

const CaseArchiveTab = () => {
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const setSelectedNavbarKey = useSetAtom(selectedNavbarKeyAtom);
  useLayoutEffect(() => {
    setSelectedNavbarKey("판례보관함");
  }, []);

  const [selectedCaseOrFolder, setSelectedCaseOrFolder] = React.useState<
    SelectedCaseOrFolder | undefined
  >(undefined);

  const [savedCasesAndFolders, setSavedCasesAndFolders] = React.useState<
    SavedCasesAndFolders | undefined
  >(undefined);
  const [caseTree, setCaseTree] = React.useState<CaseTreeNode | undefined>(
    undefined
  );

  const [isLoadingSavedCasesAndFolders, setIsLoadingSavedCasesAndFolders] =
    React.useState<Boolean>(true);

  const [shouldBlinkCaseTree, setShouldBlinkCaseTree] =
    React.useState<Boolean>(false);

  const [savedCasesLoasdError, setSavedCasesLoadError] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setIsLoadingSavedCasesAndFolders(true);
    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        setSavedCasesLoadError(true);
      });
  }, []);

  React.useEffect(() => {
    const caseTree = makeCaseTree(savedCasesAndFolders);
    if (selectedCaseOrFolder) {
      selectCaseOrForderByPath({
        caseTreeNode: caseTree,
        path: selectedCaseOrFolder.path,
        setSelectedCaseOrFolder: setSelectedCaseOrFolder,
      });
    }
    setCaseTree(caseTree);
    if (savedCasesAndFolders) {
      setIsLoadingSavedCasesAndFolders(false);
      setShouldBlinkCaseTree(false);
    }
  }, [savedCasesAndFolders]);

  return (
    <>
      <OnlyPC>
        <Flex width={"100%"} height={`calc( 100vh - ${navbarHeight}px)`}>
          <Flex
            minWidth={`${caseTreeExplorerWidthPixel}px`}
            maxWidth={`${caseTreeExplorerWidthPixel}px`}
            borderRight={"1px"}
            borderColor={"gray.300"}
          >
            {savedCasesLoasdError ? (
              <Flex
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text
                  color="red.400"
                  whiteSpace={"pre-wrap"}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  {"저장된 판례를 불러오는데 실패했습니다.\n새로고침 해주세요."}
                </Text>
              </Flex>
            ) : (
              <CaseTreeExplorer
                selectedCaseOrFolder={selectedCaseOrFolder}
                setSelectedCaseOrFolder={setSelectedCaseOrFolder}
                caseTree={caseTree}
                shouldBlinkCaseTree={shouldBlinkCaseTree}
                isLoadingSavedCasesAndFolders={isLoadingSavedCasesAndFolders}
              />
            )}
          </Flex>
          <Flex flex={1}>
            <CaseViewer
              selectedCaseOrFolder={selectedCaseOrFolder}
              setSelectedCaseOrFolder={setSelectedCaseOrFolder}
              setShouldBlinkCaseTree={setShouldBlinkCaseTree}
              savedCasesAndFolders={savedCasesAndFolders}
              setSavedCasesAndFolders={setSavedCasesAndFolders}
              savedCasesLoadError={savedCasesLoasdError}
              setSavedCasesLoadError={setSavedCasesLoadError}
              caseTree={caseTree}
            />
          </Flex>
        </Flex>
      </OnlyPC>
      <OnlyMobile>
        <Flex width={"100%"} height={`calc( 100vh - ${navbarHeight}px)`}>
          <TobeImplementedTab mobile={true} />
        </Flex>
      </OnlyMobile>
    </>
  );
};

export default CaseArchiveTab;
