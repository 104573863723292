import { Flex, Text, useToast, Spinner } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { keyframes } from "@emotion/react";
import React, { useContext } from "react";
import { GoHomeFill } from "react-icons/go";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  FileTreeNode,
  FilesAndFolders,
  MakeOnFilesAddedCallback,
  MakeOnFilesRejectedCallback,
  SelectedFolder,
} from "../../logics/FileManager";

import FileTreeNodeToAccordionItem from "./FileTreeNodeToAccordionItem";

export const FileTreeExplorerMainPadding = 5;

const FileTreeExplorer = ({
  fileTree,
  setFilesAndFolders,
  fileLoadError,
  shouldBlinkFileTree,
  setShouldBlinkFileTree,
  isLoadingFilesAndFolders,
  selectedFolder,
  setSelectedFolder,
}: {
  fileTree: FileTreeNode | undefined;
  setFilesAndFolders: React.Dispatch<React.SetStateAction<FilesAndFolders>>;
  fileLoadError: boolean;
  shouldBlinkFileTree: boolean;
  setShouldBlinkFileTree: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingFilesAndFolders: boolean;
  selectedFolder: SelectedFolder;
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder>>;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;

  const userName = authContext.user?.username;
  const toast = useToast();
  const [doHighlightFolderNameCurrent, setDoHighlightFolderNameCurrent] =
    React.useState<Boolean>(false);

  const onFilesAdded = MakeOnFilesAddedCallback({
    authContext: authContext,
    path: "",
    parentFolderId: null,
    toast: toast,
    setFilesAndFolders: setFilesAndFolders,
    setShouldBlinkFileTree: setShouldBlinkFileTree,
    setDoHighlightFlex: setDoHighlightFolderNameCurrent,
  });

  const onFilesRejected = MakeOnFilesRejectedCallback({
    toast: toast,
  });

  const { getRootProps } = useDropzone({
    onDrop: onFilesAdded,
    onDropRejected: onFilesRejected,
    noClick: true,
    accept: { "application/pdf": [] },
    maxSize: 1024 * 1024 * 1024,
  });

  const handleOnClick = () => {
    if (fileTree === undefined) return;
    setSelectedFolder({
      name: userName,
      id: null,
      path: "",
      path_string_id: "",
      children: fileTree.children,
    });
  };

  const blinkAnimation = keyframes`
  0% { opacity: 0.2; }
  100% { opacity: 1; }
`;

  return (
    <Flex id={"File Tree Explorer"} width={"100%"} height={"100%"}>
      <Flex
        p={`${FileTreeExplorerMainPadding}px`}
        width={"100%"}
        borderBottom={"1px"}
        borderColor="gray.300"
        height={"fit-content"}
        direction={"column"}
        css={{
          animation: `${
            shouldBlinkFileTree ? `${blinkAnimation} 0.8s infinite` : "none"
          }`,
        }}
        bg={"gray.50"}
      >
        <Flex
          {...getRootProps()}
          alignItems={"center"}
          borderRadius={"5px"}
          py="2px"
          px="2px"
          bg={
            doHighlightFolderNameCurrent
              ? "blue.100"
              : selectedFolder?.path_string_id === ""
                ? "gray.300"
                : ""
          }
          onDragOver={(e) => {
            e.preventDefault();
            setDoHighlightFolderNameCurrent(true);
          }}
          onDragLeave={() => setDoHighlightFolderNameCurrent(false)}
        >
          <Flex
            height={"24px"}
            width={"100%"}
            _hover={{ bg: "gray.200" }}
            borderRadius={"5px"}
            cursor={"pointer"}
            px="2px"
            alignItems={"center"}
            onClick={handleOnClick}
          >
            <Flex height={"24px"} alignItems={"center"}>
              <GoHomeFill />
            </Flex>
            <Text
              userSelect={"none"}
              textAlign="left"
              color={"gray.600"}
              ms="5px"
            >
              <b>{`${userName}`}</b>
            </Text>
          </Flex>
        </Flex>

        {isLoadingFilesAndFolders ? (
          <Flex justifyContent={"center"} alignItems={"center"} height="28px">
            <Spinner color="gray.500" size="sm" />
          </Flex>
        ) : fileLoadError ? (
          <Flex width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Text
              my={"10px"}
              color="red.400"
              whiteSpace={"pre-wrap"}
              fontWeight={"bold"}
              textAlign={"center"}
              fontSize={"sm"}
            >
              {"저장된 판례를 불러오는데 실패했습니다.\n새로고침 해주세요."}
            </Text>
          </Flex>
        ) : fileTree?.children.length !== 0 ? (
          fileTree?.children.map((child) => {
            return (
              <FileTreeNodeToAccordionItem
                key={child.id}
                fileTreeNode={child}
                depth={0}
                basePath={""}
                basePathStringId={""}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                setFilesAndFolders={setFilesAndFolders}
                setShouldBlinkFileTree={setShouldBlinkFileTree}
              />
            );
          })
        ) : (
          <Flex
            height={"28px"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text textAlign={"center"} fontSize={"sm"} color={"gray.600"}>
              <b>{"업로드한 파일이 없습니다."}</b>
            </Text>
          </Flex>
        )}
        <Flex
          {...getRootProps()}
          onDragOver={(e) => {
            e.preventDefault();
            setDoHighlightFolderNameCurrent(true);
          }}
          onDragLeave={() => setDoHighlightFolderNameCurrent(false)}
          height={"70px"}
          px="15px"
          justifyContent={"center"}
          pt="5px"
        >
          <Text
            userSelect={"none"}
            textAlign={"center"}
            whiteSpace={"pre-wrap"}
            fontSize={"sm"}
            color={"gray.500"}
          >
            <b>
              {
                "원하는 폴더 위치에 파일 또는 폴더를\n 드래그 앤 드롭하여 업로드해 주세요.\n(업로드 가능한 파일 형식: pdf)"
              }
            </b>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FileTreeExplorer;
