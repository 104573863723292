import React from "react";
import { Flex, Text, IconButton, Button, Icon } from "@chakra-ui/react";
import { SelectedCaseOrFolder } from "../../logics/CaseArchive";
import {
  LuX,
  LuTrash2,
  LuExternalLink,
  LuFolderPlus,
  LuFolderEdit,
} from "react-icons/lu";
import { MdOutlineSummarize } from "react-icons/md";
import { Case } from "../../logics/Search";

export const caseViewerHeaderHeight = 50;

const CaseViewerHeader = ({
  selectedCaseOrFolder,
  setSelectedCaseOrFolder,
  onOpenModal,
  setModalType,
  openSummary,
  setOpenSummary,
  savedCasesLoadError,
  selectedCase,
}: {
  selectedCaseOrFolder: SelectedCaseOrFolder | undefined;
  setSelectedCaseOrFolder: React.Dispatch<
    React.SetStateAction<SelectedCaseOrFolder | undefined>
  >;
  onOpenModal: () => void;
  setModalType: React.Dispatch<
    React.SetStateAction<
      "newFolder" | "renameFolder" | "deleteObject" | undefined
    >
  >;
  openSummary: Boolean;
  setOpenSummary: React.Dispatch<React.SetStateAction<Boolean>>;
  savedCasesLoadError: boolean;
  selectedCase: Case | undefined;
}) => {
  const handleOnClickNewWindowCase = () => {
    if (!selectedCase) return;
    const path = `/case/${selectedCase.case_id}`;
    window.open(path, "_blank");
  };
  return (
    <Flex
      key={"CaseViewer Header"}
      width={"100%"}
      maxHeight={`${caseViewerHeaderHeight}px`}
      minHeight={`${caseViewerHeaderHeight}px`}
      bg="gray.50"
      justifyContent={"center"}
      alignItems={"center"}
      borderBottom={"1px"}
      borderColor={"gray.300"}
      px="10px"
    >
      {selectedCaseOrFolder ? (
        <>
          <IconButton
            size={"sm"}
            variant={"outline"}
            bg={"white"}
            aria-label="close folder"
            _hover={{ bg: "gray.300" }}
            onClick={() => setSelectedCaseOrFolder(undefined)}
          >
            <Icon as={LuX} />
          </IconButton>
          <Text
            flex={1}
            isTruncated
            color={"gray.700"}
            fontWeight={"bold"}
            px="10px"
          >
            {selectedCaseOrFolder.name}
          </Text>

          {selectedCaseOrFolder.type === "folder" ? (
            <>
              <Button
                size={"sm"}
                leftIcon={<Icon as={LuFolderPlus} />}
                variant={"outline"}
                bg={"white"}
                me="5px"
                _hover={{ bg: "gray.300" }}
                onClick={() => {
                  setModalType("newFolder");
                  onOpenModal();
                }}
              >
                {"새폴더"}
              </Button>
              <Button
                size={"sm"}
                leftIcon={<Icon as={LuFolderEdit} />}
                variant={"outline"}
                bg={"white"}
                me="5px"
                _hover={{ bg: "gray.300" }}
                onClick={() => {
                  setModalType("renameFolder");
                  onOpenModal();
                }}
              >
                {"이름변경"}
              </Button>
              <Button
                size={"sm"}
                leftIcon={<Icon as={LuTrash2} />}
                variant={"outline"}
                bg={"white"}
                _hover={{ bg: "gray.300" }}
                onClick={() => {
                  setModalType("deleteObject");
                  onOpenModal();
                }}
              >
                {"삭제"}
              </Button>
            </>
          ) : (
            <>
              <Button
                size={"sm"}
                leftIcon={<Icon as={MdOutlineSummarize} />}
                variant={"outline"}
                bg={"white"}
                me="5px"
                isDisabled={openSummary ? true : false}
                _hover={{ bg: "gray.300" }}
                onClick={() => setOpenSummary(!openSummary)}
              >
                {"요약"}
              </Button>
              <Button
                size={"sm"}
                leftIcon={<Icon as={LuExternalLink} />}
                variant={"outline"}
                bg={"white"}
                me="5px"
                onClick={handleOnClickNewWindowCase}
                _hover={{ bg: "gray.300" }}
              >
                {"새창에서 열기"}
              </Button>
              <Button
                size={"sm"}
                leftIcon={<Icon as={LuTrash2} />}
                variant={"outline"}
                bg={"white"}
                _hover={{ bg: "gray.300" }}
                onClick={() => {
                  setModalType("deleteObject");
                  onOpenModal();
                }}
              >
                {"삭제"}
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <Text flex={1} color={"gray.700"} fontWeight={"bold"} px="10px">
            {"판례보관함"}
          </Text>
          <Button
            size={"sm"}
            leftIcon={<Icon as={LuFolderPlus} />}
            variant={"outline"}
            bg={"white"}
            _hover={{ bg: "gray.300" }}
            onClick={() => {
              setModalType("newFolder");
              onOpenModal();
            }}
            isDisabled={savedCasesLoadError ? true : false}
          >
            {"새폴더"}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default CaseViewerHeader;
