import React from "react";
import { Flex } from "@chakra-ui/react";
import { useSetAtom } from "jotai";
import { OnlyMobile, OnlyPC } from "../utils/UIUtils";
import { selectedNavbarKeyAtom } from "../states/HomePage";
import { navbarHeight } from "../components/Navbar";

import TobeImplementedTab from "./TobeImplementedTab";
import FileManagerMain from "../components/FileManager/FileManagerMain";

const FileManagerTab = () => {
  const setSelectedNavbarKey = useSetAtom(selectedNavbarKeyAtom);
  React.useLayoutEffect(() => {
    setSelectedNavbarKey("파일관리");
  }, []);
  return (
    <>
      <OnlyPC>
        <Flex width={"100vw"} height={`calc( 100vh - ${navbarHeight}px)`}>
          <FileManagerMain />
        </Flex>
      </OnlyPC>
      <OnlyMobile>
        <Flex width={"100%"} height={`calc( 100vh - ${navbarHeight}px)`}>
          <TobeImplementedTab mobile={true} />
        </Flex>
      </OnlyMobile>
    </>
  );
};

export default FileManagerTab;
