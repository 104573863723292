import * as React from "react";
import {
  Flex,
  Icon,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { MdKeyboardReturn } from "react-icons/md";
import { MdOutlineSummarize } from "react-icons/md";
import { FaBookmark } from "react-icons/fa";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { selectedCaseAtom } from "../../states/Search";
import { navbarHeight } from "../Navbar";
import {
  filterCases,
  getFolderName,
  requestDeleteObjectInCaseArchive,
  requestCaseArchiveList,
} from "../../logics/CaseArchive";
import DisplayCase from "../DisplayCase";
import CaseSaveModal from "../Search/CaseSaveModal";
import SummaryInCaseViewer from "../CaseArchive/SummaryInCaseViewer";

const CaseViewerInTab = ({
  caseTree,
  savedCasesList,
  savedCasesAndFolders,
  setSavedCasesAndFolders,
  shouldBlinkCaseTree,
  setShouldBlinkCaseTree,
  setSavedCasesLoadError,
}) => {
  const [selectedCase, setSelectedCase] = useAtom(selectedCaseAtom);
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const [openSummary, setOpenSummary] = React.useState<Boolean>(false);
  const toast = useToast();
  const heightHeader = 50;
  const summaryWidth = 400;
  const [isSaved, setIsSaved] = React.useState<boolean>(false);
  const [folderListSavingCase, setFolderListSavingCase] = React.useState<
    { id: number | null; name: string; id_case: number }[]
  >([]);

  const caseId = selectedCase?.case_id;
  const caseName = selectedCase?.name;

  React.useEffect(() => {
    if (savedCasesList === undefined) return;
    if (caseId === undefined) return;
    const filteredSavedCases = filterCases(savedCasesList, caseId);
    const folderListSavingCase = filteredSavedCases.map((savedCase) => ({
      id: savedCase.parent_folder_id,
      name: getFolderName(savedCasesAndFolders, savedCase.parent_folder_id),
      id_case: savedCase.id,
    }));
    setFolderListSavingCase(folderListSavingCase);
    if (filteredSavedCases.length === 0) {
      setIsSaved(false);
    } else {
      setIsSaved(true);
    }
  }, [savedCasesList]);

  const {
    isOpen: isOpenSaveCase,
    onOpen: onOpenSaveCase,
    onClose: onCloseSaveCase,
  } = useDisclosure();

  const handleOnClickMenuItem = async (id_case: number) => {
    console.log("id_case", id_case);
    setShouldBlinkCaseTree(true);
    try {
      await requestDeleteObjectInCaseArchive(authContext, id_case, "case");
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: "삭제에 실패했습니다. 다시 시도해 주세요.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setShouldBlinkCaseTree(false);
      return;
    }
    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: "Error",
          description:
            "저장된 판례를 불러오는데 실패했습니다. 새로고침 해주세요.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setSavedCasesLoadError(true);
      });
  };

  return (
    <Flex
      minWidth={"100vw"}
      maxHeight={`calc( 100vh - ${navbarHeight}px)`}
      maxWidth={"100vw"}
    >
      <Flex
        flex={1}
        height={"100%"}
        direction={"column"}
        borderRight={"1px"}
        borderColor={"gray.300"}
      >
        <Flex
          height={`${heightHeader}px`}
          borderBottom={"1px"}
          bg="gray.50"
          borderColor={"gray.300"}
          alignItems={"center"}
        >
          <Button
            size={"sm"}
            m="5px"
            bg="gray.50"
            leftIcon={<MdKeyboardReturn />}
            _hover={{ bg: "gray.200" }}
            onClick={() => {
              setSelectedCase(undefined);
            }}
          >
            <Text fontSize={"sm"} color="gray.600">
              {"검색결과로 돌아가기"}
            </Text>
          </Button>
          <Flex flex={1} />
          {isSaved ? (
            <Menu>
              <MenuButton
                as={Button}
                size={"sm"}
                leftIcon={
                  <Icon
                    as={FaBookmark}
                    color={isSaved ? "blue.500" : "gray.400"}
                  />
                }
                variant={"outline"}
                bg={"white"}
                me="5px"
                _hover={{ bg: "gray.300" }}
              >
                {"판례보관함 저장"}
              </MenuButton>

              <MenuList>
                <MenuItem
                  onClick={() => {
                    onOpenSaveCase();
                  }}
                >
                  {"판례 보관함 저장"}
                </MenuItem>
                {folderListSavingCase.map((folder) => (
                  <MenuItem
                    onClick={() => handleOnClickMenuItem(folder.id_case)}
                    key={folder.id_case}
                  >
                    {`"${folder.name}" 폴더에서 삭제`}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : (
            <Button
              as={Button}
              size={"sm"}
              isLoading={savedCasesList === undefined}
              leftIcon={
                <Icon
                  as={FaBookmark}
                  color={isSaved ? "blue.500" : "gray.400"}
                />
              }
              variant={"outline"}
              bg={"white"}
              me="5px"
              onClick={() => {
                onOpenSaveCase();
              }}
              _hover={{ bg: "gray.300" }}
            >
              {"판례보관함 저장"}
            </Button>
            // <IconButton
            //   size={"xs"}
            //   me="5px"
            //   variant={"outline"}
            //   isLoading={savedCasesList === undefined}
            //   aria-label="save case"
            //   icon={
            //     <Icon
            //       as={FaBookmark}
            //       color={isSaved ? "blue.500" : "gray.400"}
            //     />
            //   }
            //   _hover={{ bg: "gray.300" }}
            //   onClick={() => {
            //     onOpenSaveCase();
            //   }}
            // />
          )}
          <Button
            size={"sm"}
            leftIcon={<Icon as={MdOutlineSummarize} />}
            variant={"outline"}
            bg={"white"}
            me="5px"
            isDisabled={openSummary ? true : false}
            _hover={{ bg: "gray.300" }}
            onClick={() => setOpenSummary(!openSummary)}
          >
            {"요약"}
          </Button>
        </Flex>

        <Flex
          px="40px"
          justifyContent={"center"}
          height={`calc( 100vh - ${navbarHeight + heightHeader}px )`}
          overflowY={"scroll"}
        >
          <DisplayCase selectedCase={selectedCase!} />
        </Flex>
      </Flex>
      <Flex
        height={"100%"}
        width={openSummary ? `${summaryWidth}px` : "0px"}
        overflowX={"hidden"}
        borderRight={openSummary ? "1px" : "0px"}
        borderColor={"gray.300"}
        transition={"width 0.3s ease-in-out"}
      >
        {openSummary && (
          <SummaryInCaseViewer
            openSummary={openSummary}
            setOpenSummary={setOpenSummary}
            summaryWidth={summaryWidth}
            case_id={selectedCase!.case_id}
          />
        )}
      </Flex>
      {caseId && caseName && (
        <CaseSaveModal
          isOpenSaveCase={isOpenSaveCase}
          onCloseSaveCase={onCloseSaveCase}
          caseTree={caseTree}
          shouldBlinkCaseTree={shouldBlinkCaseTree}
          setShouldBlinkCaseTree={setShouldBlinkCaseTree}
          savedCasesAndFolders={savedCasesAndFolders}
          setSavedCasesAndFolders={setSavedCasesAndFolders}
          caseId={caseId}
          caseName={caseName}
        />
      )}
    </Flex>
  );
};

export default CaseViewerInTab;
