import React from "react";
import { Flex, useToast } from "@chakra-ui/react";
import { useContext } from "react";
import { useDropzone } from "react-dropzone";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  SelectedFolder,
  FilesAndFolders,
  TabItems,
  MakeOnFilesAddedCallback,
  MakeOnFilesRejectedCallback,
  FileTreeNode,
} from "../../logics/FileManager";
import FileItem from "./FileItem";

const FileExplorerMain = ({
  selectedFolder,
  setSelectedFolder,
  tabItems,
  setTabItems,
  filesAndFolders,
  setFilesAndFolders,
  setFilesLoadError,
  fileLoadError,
}: {
  selectedFolder: SelectedFolder;
  setSelectedFolder: React.Dispatch<React.SetStateAction<SelectedFolder>>;
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  filesAndFolders: FilesAndFolders | undefined;
  setFilesAndFolders: React.Dispatch<React.SetStateAction<FilesAndFolders>>;
  setFilesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
  fileLoadError: boolean;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;
  const [higlightFlexByDragging, setHiglightFlexByDragging] =
    React.useState<Boolean>(false);
  const toast = useToast();
  const path = selectedFolder.path;

  const onFilesAdded = MakeOnFilesAddedCallback({
    authContext: authContext,
    path: path,
    parentFolderId: selectedFolder.id,
    toast: toast,
    setFilesAndFolders: setFilesAndFolders,
    setDoHighlightFlex: setHiglightFlexByDragging,
  });

  const onFilesRejected = MakeOnFilesRejectedCallback({
    toast: toast,
  });

  const { getRootProps } = useDropzone({
    onDrop: onFilesAdded,
    onDropRejected: onFilesRejected,
    noClick: true,
    accept: { "application/pdf": [] },
    maxSize: 1024 * 1024 * 1024,
  });

  return (
    <Flex height={"100%"} width={"100%"}>
      <Flex
        flex={1}
        {...getRootProps()}
        id="file-explorer-main"
        border={higlightFlexByDragging ? "2px " : "none"}
        borderColor={higlightFlexByDragging ? "blue.200" : "none"}
        bg={higlightFlexByDragging ? "blue.50" : ""}
        onDragOver={(e) => {
          e.preventDefault();
          setHiglightFlexByDragging(true);
        }}
        onDragLeave={() => setHiglightFlexByDragging(false)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        overflowY={"auto"}
      >
        <Flex
          id="files-container"
          height={"fit-content"}
          width={"100%"}
          p="20px"
          flexWrap="wrap"
        >
          {!fileLoadError &&
            selectedFolder.children &&
            selectedFolder.children.length > 0 &&
            selectedFolder.children.map((child) => {
              return (
                <FileItem
                  key={child.string_id}
                  fileTreeNode={child}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  filesAndFolders={filesAndFolders}
                  setFilesAndFolders={setFilesAndFolders}
                  setFilesLoadError={setFilesLoadError}
                />
              );
            })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FileExplorerMain;
