import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as React from "react";
import { AuthProvider } from "./context/AuthContext";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./styles/theme";
import AuthChecker from "./utils/AuthChecker";

import PrivateRoute from "./utils/PrivateRoute";
function App() {
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<AuthChecker />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <HomePage />
                  </PrivateRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </ChakraProvider>
  );
}

export default App;
