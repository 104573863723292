import { Flex, Text } from "@chakra-ui/react";
import { SelectedCaseOrFolder } from "../../logics/CaseArchive";
import folderSVG from "../../img/folder.svg";
import DisplayCase from "../DisplayCase";
import { Case } from "../../logics/Search";
import { caseViewerHeaderHeight } from "./CaseViewerHeader";
import { navbarHeight } from "../Navbar";

const CaseViewerBody = ({
  selectedCaseOrFolder,
  selectedCase,
}: {
  selectedCaseOrFolder: SelectedCaseOrFolder | undefined;
  selectedCase: Case | undefined;
}) => {
  return (
    <Flex
      key={"CaseViewer Body"}
      width={"100%"}
      height={"100%"}
      maxHeight={`calc( 100vh - ${navbarHeight}px - ${caseViewerHeaderHeight}px)`}
      justifyContent={"center"}
      overflowY={"auto"}
      alignItems={"center"}
      direction={"column"}
      px="10px"
    >
      {selectedCaseOrFolder ? (
        selectedCaseOrFolder.type === "folder" ? (
          <Flex mb="10px">
            <img src={folderSVG} alt="Folder SVG" style={{ height: 120 }} />
          </Flex>
        ) : (
          selectedCase && (
            <DisplayCase selectedCase={selectedCase} isHighlighted={false} />
          )
        )
      ) : (
        <Flex mb="10px">
          <img src={folderSVG} alt="Folder SVG" style={{ height: 120 }} />
        </Flex>
      )}
    </Flex>
  );
};
export default CaseViewerBody;
