import React from "react";
import {
  Flex,
  Spinner,
  Text,
  IconButton,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { LuX } from "react-icons/lu";

import { PdfPageViewer } from "../../libs/pdf/PdfManager";
import { InternalSearchResult } from "../../logics/Search";
import { TabItems, openPdfInTab } from "../../logics/FileManager";

const DisplayPreviewItem = ({
  previewSearchResult,
  setPreviewSearchResult,
  tabItems,
  setTabItems,
}: {
  previewSearchResult: InternalSearchResult;
  setPreviewSearchResult: React.Dispatch<
    React.SetStateAction<InternalSearchResult | undefined>
  >;
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
}) => {
  const [isPreviewLoading, setisPreviewLoading] =
    React.useState<boolean>(false);

  const onDocumentLoadSuccess = () => {
    setisPreviewLoading(false);
  };

  const onDocumentLoadStart = () => {
    setisPreviewLoading(true);
  };

  const handleOnClickName = () => {
    openPdfInTab({
      tabItems: tabItems,
      setTabItems: setTabItems,
      id: previewSearchResult.file_manager_file_id,
      name: previewSearchResult.name,
      page: previewSearchResult.page,
    });
  };
  return (
    <>
      {isPreviewLoading && (
        <Flex
          position={"absolute"}
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          zIndex={"10"}
          bg="white"
        >
          <Spinner color="gray.500" />
        </Flex>
      )}
      <Flex
        width={"100%"}
        alignItems={"center"}
        direction={"column"}
        bg="gray.100"
      >
        <Flex
          width={"100%"}
          minHeight={"44px"}
          maxHeight={"44px"}
          borderBottom={"1px"}
          borderColor={"gray.300"}
          bg="white"
          alignItems={"center"}
          pe="10px"
        >
          <IconButton
            ms="5px"
            me={"10px"}
            size={"sm"}
            aria-label="Search"
            icon={<Icon as={LuX} color={"gray.600"} />}
            variant={"outline"}
            onClick={() => {
              setPreviewSearchResult(undefined);
            }}
          />
          <Tooltip
            label="새 탭에서 파일 열기"
            bg="gray.500"
            placement="top"
            aria-label="파일을 열기"
          >
            <Text
              userSelect={"none"}
              noOfLines={1}
              fontSize={"lg"}
              overflow="hidden"
              textOverflow="ellipsis"
              display="-webkit-box"
              css={{
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
              _hover={{
                color: "blue.500",
              }}
              cursor={"pointer"}
              align="left"
              fontWeight={"bold"}
              onClick={() => handleOnClickName()}
            >
              {previewSearchResult.path +
                ` (p.${previewSearchResult.page + 1})`}
            </Text>
          </Tooltip>
        </Flex>
        <Flex
          width={"100%"}
          height={"100%"}
          id="preview-container"
          justifyContent={"center"}
          py="10px"
          overflow={"auto"}
        >
          <Flex
            width={"calc(100% - 30px)"}
            height={"fit-content"}
            p="5px"
            borderRadius={"5px"}
            border={"2px"}
            borderColor={"gray.100"}
            overflow="hidden"
          >
            <PdfPageViewer
              url={previewSearchResult.url}
              pageId={
                previewSearchResult.num_partitions == 1
                  ? previewSearchResult.page
                  : previewSearchResult.page -
                    Number(previewSearchResult.partition_id) *
                      Number(previewSearchResult.partition_size)
              }
              highlightPosition={{
                top: previewSearchResult.top,
                left: previewSearchResult.left,
                right: previewSearchResult.right,
                bottom: previewSearchResult.bottom,
              }}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              onDocumentLoadStart={onDocumentLoadStart}
              isExpanded={true}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default DisplayPreviewItem;
