import React from "react";
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { toastErrorMessage } from "../../utils/UIUtils";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  FilesAndFolders,
  FileTreeNode,
  requestFilesAndFolders,
  requestRenameFolderInFileManager,
  requestRenameFileInFileManager,
  deleteFolder,
  ModalType,
  requestRemoveFile,
  getIsAlreadyUsedNameInParentFolder,
  findFileIdsInFileTreeNode,
} from "../../logics/FileManager";

const ModalInFileItem = ({
  isOpenModal,
  modalType,
  setModalType,
  onCloseModal,
  filesAndFolders,
  setFilesAndFolders,
  setFilesLoadError,
  fileTreeNode,
}: {
  isOpenModal: boolean;
  modalType: ModalType;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
  onCloseModal: () => void;
  filesAndFolders: FilesAndFolders;
  setFilesAndFolders: React.Dispatch<React.SetStateAction<FilesAndFolders>>;
  setFilesLoadError: React.Dispatch<React.SetStateAction<boolean>>;
  fileTreeNode: FileTreeNode;
}) => {
  const authContext = React.useContext(AuthContext) as AuthContextType;
  const toast = useToast();
  const initialRef = React.useRef<HTMLInputElement>(null);
  const [nameToChange, setNameToChange] = React.useState<string>(
    fileTreeNode.name,
  );
  const isAlreadyUsedNameInParentFolder =
    fileTreeNode.type === "folder"
      ? getIsAlreadyUsedNameInParentFolder({
          id: fileTreeNode.id,
          filesAndFolders: filesAndFolders,
          nameToChange: nameToChange,
          type: "folder",
        })
      : getIsAlreadyUsedNameInParentFolder({
          id: fileTreeNode.id,
          filesAndFolders: filesAndFolders,
          nameToChange: nameToChange,
          type: "file",
        });
  const isSameNameToChange = fileTreeNode.name === nameToChange;
  const isPdfExtension = /\.pdf$/i.test(nameToChange);

  const closeModal = () => {
    onCloseModal();
    setModalType(undefined);
    setNameToChange(fileTreeNode.name);
  };
  React.useEffect(() => {
    setNameToChange(fileTreeNode.name);
  }, [fileTreeNode]);

  const handleOnChangeRename = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameToChange(e.target.value);
  };

  const onClickRenameFolder = async () => {
    closeModal();
    const id = fileTreeNode.id;
    if (!id) {
      return;
    }
    try {
      !isSameNameToChange &&
        setFilesAndFolders((prev) => {
          if (!prev) {
            return { files: [], folders: [] };
          }
          return {
            files: prev.files,
            folders: prev.folders.map((folder) => {
              const newFolder =
                folder.id === id ? { ...folder, name: nameToChange } : folder;
              return newFolder;
            }),
          };
        });
      !isSameNameToChange &&
        (await requestRenameFolderInFileManager(authContext, id, nameToChange));
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "폴더 이름 변경에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
    }
    await requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "파일을 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setFilesLoadError(true);
      });
  };

  const onClickRenameFile = async () => {
    closeModal();

    const id = fileTreeNode.id;
    if (!id) {
      return;
    }
    try {
      !isSameNameToChange &&
        setFilesAndFolders((prev) => {
          if (!prev) {
            return { files: [], folders: [] };
          }
          return {
            folders: prev.folders,
            files: prev.files.map((file) => {
              const newFile =
                file.id === id ? { ...file, name: nameToChange } : file;
              return newFile;
            }),
          };
        });
      !isSameNameToChange &&
        (await requestRenameFileInFileManager(authContext, id, nameToChange));
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "파일 이름 변경에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
    }
    await requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "파일을 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setFilesLoadError(true);
      });
  };

  const onClickDeleteFolder = async () => {
    closeModal();

    const deleteFileIds = findFileIdsInFileTreeNode(fileTreeNode);
    setFilesAndFolders((prev) => {
      if (!prev) {
        return { files: [], folders: [] };
      }
      return {
        files: prev?.files.filter((file) => !deleteFileIds.includes(file.id)),
        folders: prev?.folders.filter(
          (folder) => folder.id !== fileTreeNode.id,
        ),
      };
    });

    await deleteFolder({
      node: fileTreeNode,
      toast: toast,
      authContext: authContext,
    });

    await requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "파일을 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setFilesLoadError(true);
      });
    console.log("deleted folder in Server", fileTreeNode.name);
  };

  const onClickDeleteFile = async () => {
    closeModal();
    const id = fileTreeNode.id;
    if (!id) {
      return;
    }
    setFilesAndFolders((prev) => {
      if (!prev) {
        return { files: [], folders: [] };
      }
      return {
        files: prev.files.filter((file) => file.id !== id),
        folders: prev.folders,
      };
    });

    try {
      await requestRemoveFile(authContext, id);
    } catch (e) {
      console.log(e);
      toastErrorMessage({
        message: "삭제에 실패했습니다. 다시 시도해 주세요.",
        toast: toast,
      });
    }

    await requestFilesAndFolders(authContext)
      .then((filesAndFolders) => {
        setFilesAndFolders(filesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toastErrorMessage({
          message: "파일을 불러오는데 실패했습니다. 새로고침 해주세요.",
          toast: toast,
        });
        setFilesLoadError(true);
      });
    console.log("deleted file in Server", fileTreeNode.name);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpenModal}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody pb={1}>
          {modalType === "deleteFolder" || modalType === "deleteFile" ? (
            <Flex width={"100%"} justifyContent={"center"}>
              <Text mt="20px" fontWeight={"bold"} whiteSpace={"pre"}>
                {modalType === "deleteFolder"
                  ? "해당 폴더를 삭제하시겠습니까?\n하위 폴더와 파일도 모두 삭제됩니다."
                  : "해당 파일을 삭제하시겠습니까?"}
              </Text>
            </Flex>
          ) : modalType === "renameFolder" || modalType === "renameFile" ? (
            <FormControl>
              <FormLabel my="20px">
                {modalType === "renameFolder"
                  ? "변경할 폴더의 이름을 입력해주세요"
                  : "변경할 파일의 이름을 입력해주세요"}
              </FormLabel>

              <Input
                ref={initialRef}
                value={nameToChange}
                onChange={handleOnChangeRename}
              />
            </FormControl>
          ) : (
            <></>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size={"sm"} mr={3} onClick={closeModal}>
            {"취소"}
          </Button>
          <Tooltip
            bg="gray.500"
            placement="top"
            label={
              isAlreadyUsedNameInParentFolder && modalType === "renameFile"
                ? "이미 사용된 이름입니다."
                : !isPdfExtension && modalType === "renameFile"
                  ? ".pdf 확장자를 사용해주세요."
                  : undefined
            }
          >
            <Button
              size={"sm"}
              bg={"blue.600"}
              isDisabled={
                modalType === "renameFolder"
                  ? isAlreadyUsedNameInParentFolder || nameToChange === ""
                  : modalType === "renameFile"
                    ? isAlreadyUsedNameInParentFolder ||
                      nameToChange === "" ||
                      !isPdfExtension
                    : false
              }
              color={"white"}
              _hover={{ bg: "blue.700" }}
              onClick={
                modalType === "deleteFolder"
                  ? onClickDeleteFolder
                  : modalType === "deleteFile"
                    ? onClickDeleteFile
                    : modalType === "renameFolder"
                      ? onClickRenameFolder
                      : modalType === "renameFile"
                        ? onClickRenameFile
                        : undefined
              }
            >
              {modalType === "renameFolder" || modalType === "renameFile"
                ? "변경"
                : "삭제"}
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ModalInFileItem;
