import { AuthContextType } from "../context/AuthContext";
import { requestServer } from "./Utils";
import { Folder } from "./FileManager";

export interface SelectedCaseOrFolder {
  id: number | null;
  name: string;
  type: "folder" | "case";
  path: string;
  case_id?: number | undefined;
}

export interface SavedCase {
  id: number;
  parent_folder_id: number | null;
  name: string;
  case_id: number;
}

export interface SavedCasesAndFolders {
  folders: Folder[];
  saved_cases: SavedCase[];
}

export interface CaseTreeNode {
  id: number | null;
  string_id: string | null;
  name: string;
  type: "folder" | "case";
  status?: string;
  case_id?: number | undefined;
  children: CaseTreeNode[];
  error?: string;
}

export const requestCaseArchiveList = async (
  authContext: AuthContextType
): Promise<SavedCasesAndFolders> => {
  const response = await requestServer(
    authContext,
    `/api/case_archive/list`,
    null,
    "GET"
  );
  if (response === undefined) return { saved_cases: [], folders: [] };
  return response;
};

export const requestCreateFolderInCaseArchive = async (
  authContext: AuthContextType,
  parent_folder_id: number | null,
  name: string
): Promise<Folder> => {
  const response = await requestServer(
    authContext,
    `/api/case_archive/create_folder`,
    { parent_folder_id: parent_folder_id, name: name },
    "POST"
  );
  const id = response.id;
  return { id: id, parent_folder_id: parent_folder_id, name: name };
};

export const requestSaveCaseInCaseArchive = async (
  authContext: AuthContextType,
  parent_folder_id: number | null,
  name: string,
  case_id: number
): Promise<SavedCase> => {
  const response = await requestServer(
    authContext,
    `/api/case_archive/save_case`,
    { parent_folder_id: parent_folder_id, name: name, case_id: case_id },
    "POST"
  );
  const id = response.id;
  return {
    id: id,
    parent_folder_id: parent_folder_id,
    name: name,
    case_id: case_id,
  };
};

export const requestDeleteObjectInCaseArchive = async (
  authContext: AuthContextType,
  id: number,
  type: "folder" | "case"
): Promise<string> => {
  const response =
    type === "folder"
      ? await requestServer(
          authContext,
          `/api/case_archive/delete_folder`,
          { id: id },
          "POST"
        )
      : await requestServer(
          authContext,
          `/api/case_archive/delete_saved_case`,
          { id: id },
          "POST"
        );
  return response;
};

export const requestRenameFolderInCaseArchive = async (
  authContext: AuthContextType,
  id: number,
  new_name: string
): Promise<string> => {
  const response = await requestServer(
    authContext,
    `/api/case_archive/rename_folder`,
    { id: id, new_name: new_name },
    "POST"
  );
  return response;
};

export const requestGetSingleCaseInfo = async (
  authContext: AuthContextType,
  case_id: number
) => {
  const response = await requestServer(
    authContext,
    `/api/external_search/case`,
    { case_id_list: [case_id] },
    "POST"
  );
  return response;
};

export const makeCaseTree = (
  savedCasesAndFolders: SavedCasesAndFolders | undefined
) => {
  const root: CaseTreeNode = {
    id: null,
    string_id: null,
    name: "root",
    type: "folder",
    case_id: undefined,
    children: [],
  };
  if (!savedCasesAndFolders) return root;
  // time complexity: n^2
  const addChildren = (node: CaseTreeNode) => {
    if (node.type === "case") return;
    node.children = savedCasesAndFolders!.folders
      .filter((foldder) => foldder.parent_folder_id === node.id)
      .map((folder) => ({
        id: folder.id,
        string_id: "f" + String(folder.id),
        name: folder.name,
        type: "folder",
        case_id: undefined,
        children: [],
      }));
    node.children = node.children.concat(
      savedCasesAndFolders!.saved_cases
        .filter((savedCase) => savedCase.parent_folder_id === node.id)
        .map((savedCase) => ({
          id: savedCase.id,
          string_id: "c" + String(savedCase.id),
          name: savedCase.name,
          type: "case",
          case_id: savedCase.case_id,
          children: [],
        }))
    );
    node.children.forEach(addChildren);
  };
  addChildren(root);
  return root;
};

export const extractCaseIdAndParentFolderId = (
  savedCasesAndFolders: SavedCasesAndFolders
): SavedCase[] => {
  return savedCasesAndFolders.saved_cases.map((savedCase) => ({
    id: savedCase.id,
    case_id: savedCase.case_id,
    parent_folder_id: savedCase.parent_folder_id,
    name: savedCase.name,
  }));
};

export const filterCases = (
  savedCasesList: SavedCase[],
  caseId: number
): SavedCase[] => {
  return savedCasesList.filter((savedCase) => savedCase.case_id === caseId);
};

export const getFolderName = (
  savedCasesAndFolders: SavedCasesAndFolders,
  id: number | null
): string => {
  if (id === null) return "판례보관함";
  const folder = savedCasesAndFolders.folders.find(
    (folder) => folder.id === id
  );
  if (folder === undefined) return "";
  return folder.name;
};

export const findNodeByPath = ({
  caseTreeNode,
  path,
}: {
  caseTreeNode: CaseTreeNode | undefined;
  path: string;
}): CaseTreeNode | undefined => {
  if (caseTreeNode === undefined) return undefined;
  if (path === "") return caseTreeNode;
  const pathList = path
    .split("/")
    .filter((id) => id)
    .map((id) => String(id));
  const findNode = (
    node: CaseTreeNode,
    pathList: String[]
  ): CaseTreeNode | undefined => {
    if (pathList.length === 0) return node;
    const nextNode = node.children.find(
      (child) => child.string_id === pathList[0]
    );
    if (nextNode === undefined) return undefined;
    return findNode(nextNode, pathList.slice(1));
  };
  return findNode(caseTreeNode, pathList);
};

export const selectCaseOrForderByPath = ({
  caseTreeNode,
  path,
  setSelectedCaseOrFolder,
}: {
  caseTreeNode: CaseTreeNode | undefined;
  path: string;
  setSelectedCaseOrFolder: React.Dispatch<
    React.SetStateAction<SelectedCaseOrFolder | undefined>
  >;
}) => {
  const node = findNodeByPath({ caseTreeNode: caseTreeNode, path: path });
  if (node === undefined) {
    setSelectedCaseOrFolder(undefined);
    return;
  }
  if (node.id === null) {
    setSelectedCaseOrFolder(undefined);
    return;
  }
  const selectedCaseOrFolder = {
    id: node.id,
    name: node.name,
    type: node.type,
    path: path,
    case_id: node.case_id,
  };
  setSelectedCaseOrFolder(selectedCaseOrFolder);
};

export const getParentFolderPath = (path: string) => {
  const lastIndex = path.lastIndexOf("/");
  if (lastIndex === -1) return path;
  return path.substring(0, lastIndex);
};
