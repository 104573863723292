import * as React from "react";
import { useContext } from "react";
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import {
  CaseTreeNode,
  SelectedCaseOrFolder,
  requestSaveCaseInCaseArchive,
  requestCaseArchiveList,
  requestCreateFolderInCaseArchive,
  SavedCasesAndFolders,
} from "../../logics/CaseArchive";
import CaseTreeExplorer from "../CaseArchive/CaseTreeExplorer";

const CaseSaveModal = ({
  isOpenSaveCase,
  onCloseSaveCase,
  caseTree,
  shouldBlinkCaseTree,
  setShouldBlinkCaseTree,
  savedCasesAndFolders,
  setSavedCasesAndFolders,
  caseId,
  caseName,
}: {
  isOpenSaveCase: boolean;
  onCloseSaveCase: () => void;
  caseTree: CaseTreeNode;
  shouldBlinkCaseTree: Boolean;
  setShouldBlinkCaseTree: React.Dispatch<React.SetStateAction<Boolean>>;
  savedCasesAndFolders: SavedCasesAndFolders;
  setSavedCasesAndFolders: React.Dispatch<
    React.SetStateAction<SavedCasesAndFolders>
  >;
  caseId: number;
  caseName: string;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;
  const {
    isOpen: isOpenNewFolder,
    onOpen: onOpenNewFolder,
    onClose: onCloseNewFolder,
  } = useDisclosure();
  const caseTreeHeight = "300px";
  const [selectedCaseOrFolder, setSelectedCaseOrFolder] = React.useState<
    SelectedCaseOrFolder | undefined
  >(undefined);
  const initialRef = React.useRef(null);
  const [newFolderName, setNewFolderName] = React.useState<string>("");

  const toast = useToast();

  const [savedCasesLoadError, setSavedCasesLoadError] =
    React.useState<boolean>(false);

  const selected_folder_id =
    selectedCaseOrFolder?.type === "folder" && selectedCaseOrFolder
      ? selectedCaseOrFolder.id
      : null;

  const savedCasesInSelectedFolder = savedCasesAndFolders.saved_cases.filter(
    (savedCase) => savedCase.parent_folder_id === selected_folder_id
  );
  const foldersInSelectedFolder = savedCasesAndFolders.folders.filter(
    (folder) => folder.parent_folder_id === selected_folder_id
  );

  const isAlreadyUsedFolderName = foldersInSelectedFolder.some(
    (folder) => folder.name === newFolderName
  );

  const isAlreadySaved = savedCasesInSelectedFolder.some(
    (savedCase) => savedCase.case_id === caseId
  );

  const onClickCreateNewFolder = async () => {
    setShouldBlinkCaseTree(true);

    const name = newFolderName;
    try {
      await requestCreateFolderInCaseArchive(
        authContext,
        selected_folder_id,
        name
      );
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: "새폴더 생성에 실패했습니다. 다시 시도해 주세요.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setNewFolderName("");
      setShouldBlinkCaseTree(false);
      onCloseNewFolder();
      return;
    }

    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: "Error",
          description:
            "저장된 판례를 불러오는데 실패했습니다. 새로고침 해주세요.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setSavedCasesLoadError(true);
        setShouldBlinkCaseTree(false);
      });
    onCloseNewFolder();
    setNewFolderName("");
  };

  const onClickSaveCase = async () => {
    setShouldBlinkCaseTree(true);

    const name = caseName;
    const case_id = caseId;
    try {
      await requestSaveCaseInCaseArchive(
        authContext,
        selected_folder_id,
        name,
        case_id
      );
      toast({
        title: "Success",
        description: "판례가 성공적으로 저장되었습니다.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: "판례 저장에 실패했습니다. 다시 시도해 주세요.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setShouldBlinkCaseTree(false);
      return;
    }

    requestCaseArchiveList(authContext)
      .then((savedCasesAndFolders) => {
        setSavedCasesAndFolders(savedCasesAndFolders);
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: "Error",
          description:
            "저장된 판례를 불러오는데 실패했습니다. 새로고침 해주세요.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setSavedCasesLoadError(true);
        setShouldBlinkCaseTree(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpenSaveCase} onClose={onCloseSaveCase}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={1}>
            <Flex width={"100%"} alignItems={"center"} direction={"column"}>
              <Text
                my="10px"
                fontSize={"lg"}
                fontWeight={"bold"}
                color={"gray.600"}
                whiteSpace={"pre"}
              >
                {"판례보관함에 저장"}
              </Text>
              <Text
                mb="10px"
                fontSize={"md"}
                color={"gray.600"}
                whiteSpace={"pre"}
              >
                {"판례를 저장할 폴더를 선택해주세요."}
              </Text>
            </Flex>
            <Flex
              border={"1px"}
              borderColor={"gray.300"}
              width={"100%"}
              borderRadius={"5px"}
              justifyContent={"center"}
            >
              {savedCasesLoadError ? (
                <Flex
                  width={"100%"}
                  height={caseTreeHeight}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Text
                    color="red.400"
                    whiteSpace={"pre-wrap"}
                    fontWeight={"bold"}
                    textAlign={"center"}
                  >
                    {
                      "저장된 판례를 불러오는데 실패했습니다.\n새로고침 해주세요."
                    }
                  </Text>
                </Flex>
              ) : (
                <CaseTreeExplorer
                  selectedCaseOrFolder={selectedCaseOrFolder}
                  setSelectedCaseOrFolder={setSelectedCaseOrFolder}
                  caseTree={caseTree}
                  maxHeight={caseTreeHeight}
                  isFixedHeight={true}
                  foldersOnlyClickable={true}
                  shouldBlinkCaseTree={shouldBlinkCaseTree}
                />
              )}
            </Flex>
            <Button
              size={"sm"}
              isDisabled={savedCasesLoadError ? true : false}
              mt="10px"
              variant={"outline"}
              onClick={onOpenNewFolder}
              leftIcon={<AddIcon />}
            >
              {"새폴더"}
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button size={"sm"} mr={3} onClick={onCloseSaveCase}>
              {"취소"}
            </Button>
            <Tooltip
              label="해당 폴더에는 이미 저장된 판례입니다."
              isDisabled={!isAlreadySaved}
              bg="gray.500"
              aria-label="A tooltip"
              placement="top"
            >
              <Button
                size={"sm"}
                bg={"blue.600"}
                isDisabled={
                  savedCasesLoadError || isAlreadySaved ? true : false
                }
                color={"white"}
                _hover={{ bg: "blue.700" }}
                onClick={onClickSaveCase}
              >
                {"저장"}
              </Button>
            </Tooltip>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpenNewFolder}
        onClose={() => {
          onCloseNewFolder();
          setNewFolderName("");
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={1}>
            <FormControl>
              <FormLabel my="20px">{"새폴더 이름을 입력해주세요"}</FormLabel>

              <Input
                ref={initialRef}
                value={newFolderName}
                onChange={(e) => {
                  setNewFolderName(e.target.value);
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              size={"sm"}
              mr={3}
              onClick={() => {
                onCloseNewFolder();
                setNewFolderName("");
              }}
            >
              {"취소"}
            </Button>
            <Tooltip
              label="이미 사용 중인 폴더 이름입니다."
              isDisabled={!isAlreadyUsedFolderName}
              bg="gray.500"
              placement="top"
            >
              <Button
                size={"sm"}
                bg={"blue.600"}
                color={"white"}
                isDisabled={
                  isAlreadyUsedFolderName || newFolderName === "" ? true : false
                }
                _hover={{ bg: "blue.700" }}
                onClick={onClickCreateNewFolder}
                isLoading={shouldBlinkCaseTree ? true : false}
              >
                {"만들기"}
              </Button>
            </Tooltip>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CaseSaveModal;
