import React from "react";

import { TabItems, openPdfInTab } from "../../logics/FileManager";

import { InternalSearchResult } from "../../logics/Search";
import { Flex, Heading, Box, Spinner, Tooltip } from "@chakra-ui/react";
import { PdfPageViewer } from "../../libs/pdf/PdfManager";

export const InternalSearchResultListItem = ({
  searchResult,
  tabItems,
  setTabItems,
  setPreviewSearchResult,
}: {
  searchResult: InternalSearchResult;
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  setPreviewSearchResult: React.Dispatch<
    React.SetStateAction<InternalSearchResult | undefined>
  >;
}) => {
  const previewHeight = 200;
  const highlightPosition = {
    top: searchResult.top,
    left: searchResult.left,
    right: searchResult.right,
    bottom: searchResult.bottom,
  };
  const [isLoading, setisLoading] = React.useState<boolean>(true);

  const selectedPage =
    searchResult.num_partitions == 1
      ? searchResult.page
      : searchResult.page -
        Number(searchResult.partition_id) * Number(searchResult.partition_size);

  const handleOnClickName = () => {
    openPdfInTab({
      tabItems: tabItems,
      setTabItems: setTabItems,
      id: searchResult.file_manager_file_id,
      name: searchResult.name,
      page: searchResult.page,
    });
  };

  const onDocumentLoadSuccess = () => {
    setisLoading(false);
  };

  const onDocumentLoadStart = () => {
    setisLoading(true);
  };

  return (
    <Flex direction="column" width="100%">
      <Flex mt="15px" mb="10px" px="10px">
        <Tooltip
          label="새 탭에서 파일 열기"
          bg="gray.500"
          placement="top"
          aria-label="파일을 열기"
        >
          <Heading
            _hover={{
              color: "blue.500",
            }}
            cursor={"pointer"}
            size="sm"
            color="gray.700"
            onClick={() => handleOnClickName()}
          >
            {searchResult.path + ` (p.${searchResult.page + 1})`}
          </Heading>
        </Tooltip>
      </Flex>
      <Tooltip label="미리보기 창에서 보기" bg="gray.500" placement="top">
        <Box
          overflow="hidden"
          height={`${previewHeight}px`}
          borderWidth="1px"
          position={"relative"}
          borderRadius="lg"
          _hover={{
            boxShadow: "md",
            borderColor: "blue.400",
            borderWidth: "2px",
          }}
          boxShadow="xs"
          cursor="pointer"
          onClick={
            isLoading ? () => {} : () => setPreviewSearchResult(searchResult)
          }
          transition={"height 0.3s ease"}
        >
          {searchResult.url ? (
            <>
              {isLoading && (
                <Flex
                  position={"absolute"}
                  width={"100%"}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  bg="white"
                >
                  <Spinner color="gray.500" />
                </Flex>
              )}
              <PdfPageViewer
                url={searchResult.url}
                pageId={selectedPage}
                highlightPosition={highlightPosition}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                onDocumentLoadStart={onDocumentLoadStart}
                isExpanded={false}
              />
            </>
          ) : (
            <></>
          )}
        </Box>
      </Tooltip>
    </Flex>
  );
};

export const MemoizedInternalSearchResultListItem = React.memo(
  InternalSearchResultListItem,
);
