import React from "react";
import { useToast } from "@chakra-ui/react";
import { useContext } from "react";
import {
  Flex,
  Input,
  IconButton,
  Icon,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import TextareaAutosize from "react-textarea-autosize";
import { SearchIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { LuX } from "react-icons/lu";

import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { handleEnterPress } from "../../utils/UIUtils";
import { requestInternalSearchQuestionAndSearch } from "../../logics/Search";
import { InternalSearchResult } from "../../logics/Search";
import {
  FileTreeNode,
  SelectedFolder,
  findFolderIdsInSelectedFolder,
} from "../../logics/FileManager";

const SearchBoxInFileManager = ({
  selectedFolder,
  setInternalSearchResults,
  isSearchLoading,
  setIsSearchLoading,
  showSearchResults,
  setShowSearchResults,
  fileTree,
  setPreviewSearchResult,
  isTherePreprocessing = true,
}: {
  selectedFolder: SelectedFolder;
  setInternalSearchResults: React.Dispatch<
    React.SetStateAction<InternalSearchResult[] | undefined>
  >;
  isSearchLoading: boolean;
  setIsSearchLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showSearchResults: boolean;
  setShowSearchResults: React.Dispatch<React.SetStateAction<boolean>>;
  fileTree: FileTreeNode | undefined;
  setPreviewSearchResult: React.Dispatch<
    React.SetStateAction<InternalSearchResult | undefined>
  >;
  isTherePreprocessing?: boolean;
}) => {
  const authContext = useContext(AuthContext) as AuthContextType;
  const [question, setQuestion] = React.useState<string>("");
  const [subFoldersIdList, setSubFoldersIdList] = React.useState<number[]>([]);
  const toast = useToast();

  React.useEffect(() => {
    const subFoldersIdList = findFolderIdsInSelectedFolder(selectedFolder);
    setSubFoldersIdList(subFoldersIdList);
    setQuestion("");
    setPreviewSearchResult(undefined);
  }, [selectedFolder]);

  React.useEffect(() => {
    if (showSearchResults === false) {
      setQuestion("");
    }
  }, [showSearchResults]);

  const handleOnClick = async () => {
    if ((isSearchLoading && question === "") || !fileTree) {
      return;
    }
    setPreviewSearchResult(undefined);
    setShowSearchResults(true);
    setIsSearchLoading(true);
    setInternalSearchResults(undefined);
    try {
      const internalSearchResults =
        await requestInternalSearchQuestionAndSearch(
          authContext,
          fileTree!,
          subFoldersIdList,
          question,
        );
      setInternalSearchResults(internalSearchResults);
    } catch (e) {
      console.log(e);
      toast({
        title: "검색에 실패했습니다.",
        description: "잠시 후 다시 시도해주세요.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsSearchLoading(false);
  };
  return (
    <Flex
      width={"100%"}
      height={"46px"}
      justifyContent={"flex-start"}
      direction="column"
    >
      <Flex width={"100%"} alignItems={"flex-start"}>
        <Flex
          bg="white"
          border={"1px"}
          borderColor={"gray.300"}
          borderRadius={"5px"}
          width={"100%"}
          alignItems={"flex-start"}
          py="4px"
          pe="5px"
          ps="5px"
          zIndex={20}
        >
          <IconButton
            ms="2px"
            borderRadius={"full"}
            aria-label="Search"
            size="xs"
            bg={question === "" ? "gray.300" : "blue.500"}
            _hover={{ bg: question === "" ? "gray.300" : "blue.600" }}
            icon={<Icon as={SearchIcon} />}
            colorScheme="blue"
            onClick={(e) => {
              if (question === "") {
                return;
              }
              handleOnClick();
            }}
          />
          {isTherePreprocessing && (
            <Tooltip
              width={"fit-content"}
              whiteSpace={"pre-wrap"}
              label={`"${
                selectedFolder.name.length > 18
                  ? selectedFolder.name.substring(0, 18) + "..."
                  : selectedFolder.name
              }"에 전처리 중인 파일이 있습니다.\n파일의 전처리 완료된 부분만 검색됩니다.`}
              bg="gray.500"
              placement="top"
            >
              <Flex
                ms="8px"
                width={"26px"}
                position="relative"
                height={"24px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Flex
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform={"translate(-50%, -50%)"}
                >
                  <Spinner h="22px" w="22px" color="gray.300" />
                </Flex>
                <Icon
                  as={InfoOutlineIcon}
                  h="16px"
                  w="16px"
                  color="orange.400"
                />
              </Flex>
            </Tooltip>
          )}
          <Input
            ps={isTherePreprocessing ? "5px" : "16px"}
            minHeight={"24px"}
            maxHeight={"24px"}
            as={TextareaAutosize}
            resize={"none"}
            placeholder={`"${selectedFolder.name}" 내에서 검색`}
            value={question}
            size={"md"}
            overflowY={"hidden"}
            _focus={{
              boxShadow: "none",
              maxHeight: "200px",
              overflowY: "auto",
            }}
            border={"none"}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && question === "") {
                e.preventDefault();
                return;
              }
              handleEnterPress(e, () => {
                handleOnClick();
              });
            }}
            bg="white"
            zIndex={20}
          />
          {showSearchResults && (
            <IconButton
              ms="2px"
              aria-label="Search"
              size="xs"
              icon={<Icon as={LuX} color={"gray.500"} />}
              variant={"ghost"}
              onClick={() => {
                setShowSearchResults(false);
                setQuestion("");
                setInternalSearchResults(undefined);
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SearchBoxInFileManager;
