import React from "react";
import { Text, Flex, Button } from "@chakra-ui/react";
import { TabItems } from "../../logics/FileManager";
import { MemoizedInternalSearchResultListItem } from "./InternalSearchResultListItem";
import { InternalSearchResult } from "../../logics/Search";

const InternalSearchResultList = ({
  searchResults,
  tabItems,
  setTabItems,
  setPreviewSearchResult,
}: {
  searchResults: InternalSearchResult[];
  tabItems: TabItems;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  setPreviewSearchResult: React.Dispatch<
    React.SetStateAction<InternalSearchResult | undefined>
  >;
}) => {
  const [startIdx, setStartIdx] = React.useState<number>(0);
  const ContentPerPage = 10;
  const endIdx = startIdx + ContentPerPage;
  const nomoreResult = endIdx > searchResults.length;

  return (
    <Flex
      direction={"column"}
      width="100%"
      px="10px"
      height={"100%"}
      overflowY={"scroll"}
    >
      {
        <Flex direction="column" alignItems={"center"}>
          {searchResults
            .filter((_, idx) => idx < endIdx)
            .map((result, index) => {
              return (
                <MemoizedInternalSearchResultListItem
                  key={index}
                  searchResult={result}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  setPreviewSearchResult={setPreviewSearchResult}
                />
              );
            })}
          {nomoreResult ? (
            searchResults.length === 0 ? (
              <Text my={"20px"} color={"gray.600"}>
                <b>{"검색 결과가 없습니다."}</b>{" "}
              </Text>
            ) : (
              <Text my={"20px"} color={"gray.600"}>
                <b>{"더 이상 검색 결과가 없습니다."}</b>{" "}
              </Text>
            )
          ) : (
            <Button size={"sm"} my={"10px"} onClick={() => setStartIdx(endIdx)}>
              {"검색결과 더 보기"}
            </Button>
          )}
        </Flex>
      }
    </Flex>
  );
};

export default InternalSearchResultList;
