import { Flex, Spinner } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { searchResultsWithStatusAtom } from "../../states/Search";
import ExternalSearchResultList from "./ExternalSearchResultList";
import { selectedCaseAtom } from "../../states/Search";
import { navbarHeight } from "../Navbar";

export const caseSearchResulWidth = 714;

const SearchResultDisplay = ({ searchRegion }) => {
  const selectedCase = useAtomValue(selectedCaseAtom);
  const searchResultsWithStatus = useAtomValue(searchResultsWithStatusAtom);

  return (
    <Flex height={"100%"} direction="column" flex={1}>
      <Flex
        width={"100vw"}
        height={`calc( 100vh - ${navbarHeight}px )`}
        overflowY={selectedCase ? "hidden" : "auto"}
        ps="214px"
      >
        {searchResultsWithStatus.status === "Empty" ? (
          <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          ></Flex>
        ) : searchResultsWithStatus.status === "Loading" ? (
          <Flex width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Spinner color="gray.500" size={"lg"} />
          </Flex>
        ) : searchRegion === "External" ? (
          <ExternalSearchResultList />
        ) : (
          <div />
        )}
      </Flex>
    </Flex>
  );
};

export default SearchResultDisplay;
