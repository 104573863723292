import * as React from "react";
import { useContext, useState } from "react";
import AuthContext, { AuthContextType } from "../context/AuthContext";
import { Input, VStack, Button } from "@chakra-ui/react";
import { handleEnterPress } from "../utils/UIUtils";
import logo from "../img/main_logo.svg";
import { isMobileQuery } from "../utils/UIUtils";
import { useMediaQuery } from "react-responsive";

const LoginPage = () => {
  const { loginUser } = useContext(AuthContext) as AuthContextType;
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const isMobile = useMediaQuery(isMobileQuery);
  const logoHeight = isMobile ? "150px" : "230px";

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <VStack align="center">
        <img src={logo} alt="Servant" style={{ height: logoHeight }} />
        <Input
          placeholder="아이디"
          mt="30px"
          width="200px"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <Input
          type="password"
          placeholder="비밀번호"
          width="200px"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyPress={(e) =>
            handleEnterPress(e, async () => {
              setShowSpin(true);
              await loginUser(username, password);
              setShowSpin(false);
            })
          }
        />
        <Button
          isLoading={showSpin}
          colorScheme="blue"
          onClick={async () => {
            setShowSpin(true);
            await loginUser(username, password);
            setShowSpin(false);
          }}
        >
          로그인
        </Button>
      </VStack>
    </div>
  );
};

export default LoginPage;
