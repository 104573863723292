import React from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";

import { MdClose } from "react-icons/md";
import { TabItems } from "../../logics/FileManager";
import { higlightTabBorderColor } from "./TabSelector";
import { tabBorderColor } from "./TabSelector";

const Tab = ({
  title,
  isActive,
  onClick,
  id,
  setTabItems,
  tabWidth = 200,
  activeTabColor = "white",
  inactiveTabColor = "gray.100",
  tabHeight = 44,
  highlightTitle = false,
  showCloseButton = true,
}: {
  title: string;
  isActive: boolean;
  onClick: () => void;
  id: number | null;
  setTabItems: React.Dispatch<React.SetStateAction<TabItems>>;
  tabWidth?: number;
  activeTabColor?: string;
  inactiveTabColor?: string;
  tabHeight?: number;
  highlightTitle?: boolean;
  showCloseButton?: boolean;
}) => {
  return (
    <Flex
      position={"relative"}
      px="5px"
      pt="5px"
      pb="7px"
      width={`${tabWidth}px`}
      minWidth={highlightTitle ? `${tabWidth}px` : isActive ? "50px" : ""}
      height={`${tabHeight}px`}
      bg={isActive ? activeTabColor : ""}
      borderTopRadius={"5px"}
      onClick={onClick}
      zIndex={isActive ? 10 : 0}
      cursor="pointer"
      borderTop={"2px"}
      borderLeft={"2px"}
      borderRight={"2px"}
      overflow={"hidden"}
      borderColor={
        isActive && highlightTitle
          ? higlightTabBorderColor
          : isActive
          ? tabBorderColor
          : "transparent"
      }
    >
      <Flex
        key="ClickAreaInTab"
        py="5px"
        width="100%"
        bg={isActive ? activeTabColor : inactiveTabColor}
        borderRadius={"5px"}
        _hover={{ bg: isActive ? "" : "gray.200" }}
        alignItems={"center"}
        overflow={"hidden"}
        justifyContent={"center"}
      >
        <Flex
          ps="10px"
          py="5px"
          width="100%"
          overflow={"hidden"}
          pe={highlightTitle ? "10px" : "2px"}
        >
          <Flex overflowX={"hidden"} width="100%">
            <Text
              width="100%"
              me={showCloseButton ? "5px" : ""}
              isTruncated
              textAlign={highlightTitle ? "center" : "left"}
              fontWeight={highlightTitle ? "bold" : ""}
              color={highlightTitle ? "blue.500" : "gray.600"}
            >
              {title}
            </Text>
          </Flex>
          {showCloseButton && (
            <IconButton
              aria-label="Close Tab"
              icon={<MdClose />}
              borderRadius={"full"}
              size="xs"
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                setTabItems((prev) => {
                  const newTabList = prev.tabList.filter((tabItem, _) => {
                    return tabItem.id !== id;
                  });
                  const newActiveTabId =
                    prev.activeTabId === id ? null : prev.activeTabId;
                  return {
                    tabList: newTabList,
                    activeTabId: newActiveTabId,
                  };
                });
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Tab;
